import { stateMachine } from "Components/Misc/CaseDetails_System";
import { RootState, store } from "Store/mainStore";
import { z } from "zod";
import homeSchema from "./schema/home";

export class Validator {
    response = { schema: null, report: {}, slice: null };
    store: RootState = null;

    constructor(slice) {
        this.response.slice = slice;
        this.store = store.getState();
        if (!this.store) {
            throw new Error("😈Validator: Initiation failed for store");
        }
    }

    generateSchema() {
        this.response.schema = homeSchema;
        return this;
    }

    patchRequired() {
        if (!this.response.schema) {
            throw new Error("😈Validator: Select a schema");
        }

        const caseType = this.store.findCase.data.caseType;
        const subType = this.store.findCase.data.subType;
        const sM = stateMachine.find((e) => e.caseType === caseType && e.subType === subType);

        if (sM) {
            const transformedFields = {};

            for (const key in this.response.schema.shape) {

                if (sM.fieldsRequired[key] === true) {
                    if (key == "network_id" && this.store.findCase.data.sCRMID.length == 0) {
                        transformedFields[key] = this.response.schema.shape[key].or(z.string().optional());
                    } else {
                        transformedFields[key] = this.response.schema.shape[key].and((z.string().nonempty()).or(z.number().gte(0)));
                    }

                } else {
                    transformedFields[key] = this.response.schema.shape[key].or(z.string().optional());
                }
            }

            this.response.schema = z.object(transformedFields);
        }

        return this;
    }

    exec() {
        this.response.report = this.response.schema.safeParse(this.store[this.response.slice].data);
        return this.response;
    }

    validate() {
        return this.generateSchema().patchRequired().exec();
    }
}
