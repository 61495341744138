import React from 'react'
import Frame from 'Components/Content/configurationArea/Frame';
import OptionsFrame from 'Components/Content/configurationArea/OptionsFrame'
import ViewFrame from 'Components/Content/configurationArea/ViewFrame'
import Options from 'Components/Content/configurationArea/PTDAC/Applications/Options'
import View from 'Components/Content/configurationArea/PTDAC/Applications/View'

const ApplicationsIndex = () => {
  return (
    <>
      <Frame>
        <OptionsFrame title='Port Terminal Recommendations'>
          <Options />
        </OptionsFrame>
        <ViewFrame>
          <View />
        </ViewFrame>
      </Frame>
    </>
  )
}

export default ApplicationsIndex