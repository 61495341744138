import { Body } from "Components/Body";
import CaseDetails from "Components/Content/CaseDetails";
import { EmptyState } from "Components/Content/EmptyState";
import { FindCase } from "Components/Content/FindCase";
import Header from "Components/Header";
import { useAuthState } from "Components/Logics/ensoCommonFunctions";
import { STATUS } from "Components/Misc/CaseDetails_System";
import React, { memo } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector } from "react-redux";

import { RootState } from "Store/mainStore";

const Home = () => {
  const caseDetailsSelector = useSelector(
    (state: RootState) => state.caseDetails
  );
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const themeSelector = useSelector((state: RootState) => state.theme);
  // const businessSelector = useSelector((state: RootState) => state.business);
  useAuthState();
  return (
    <>
      <LoadingOverlay
        active={authSelector.system.isLoading}
        className="scroll"
        spinner
        classNamePrefix="MyLoader_"
        text="Please wait "
      >


        <Header />
        <Body
          crumbs={[
            caseDetailsSelector.data.caseName
              ? "Create Case: " + caseDetailsSelector.data.caseName
              : "Create Case",
          ]}
          bodyContent={
            <>
              {
                <div style={{ display: "grid", height: '100%' }}>
                  <div >
                    <FindCase />
                  </div>
                  <div>
                    {caseDetailsSelector.status === STATUS.IDLE ? (
                      <>
                        <div>

                          {themeSelector.data.stack.isOnline ? (
                            <CaseDetails />
                          ) : (
                            <EmptyState
                              src="/assets/images/WentWrong.svg"
                              msg="Something went wrong !" />
                          )}
                        </div>

                        {/* <PortPlans /> */}
                      </>
                    ) : (
                      <EmptyState
                        msg="Select the required criteria with valid sCRM Opportunity ID to proceed further"
                      />
                    )}
                  </div>
                </div>
              }

            </>
          }
        />


      </LoadingOverlay>
    </>
  );
};

export default memo(Home);

/*
 
*/
