import React, { useState, useRef, useEffect } from 'react';
import _findIndex from 'lodash/findIndex';
// FF icon
import ChevronDownSmallIcon from '@nokia-csf-uxr/ccfk-assets/latest/ChevronDownSmallIcon';
import CloseSmallIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseSmallIcon';

import Label from '@nokia-csf-uxr/ccfk/Label';
import Avatar from '@nokia-csf-uxr/ccfk/Avatar';
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import _ from 'lodash';
import SelectItem, {
  SelectItemLabelContent,
  SelectListItem,
  SelectItemText,
  SelectItemButton,
  SelectItemClearButton,
} from '@nokia-csf-uxr/ccfk/SelectItem';

import Chip, {
  ChipLabel,
  ChipIconButton
} from '@nokia-csf-uxr/ccfk/Chip';
import { ToggleButtonGroup } from '@nokia-csf-uxr/ccfk';
import { ToggleGroupButton } from '@nokia-csf-uxr/ccfk/ToggleButtonGroup';
import { ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import { HelpText } from '../hooks/useHelpText';

const isSelectionKeyPressed = key => key && (key === ENTER_KEY || key === SPACE_KEY);
const DELETE = 'Delete';
const BACKSPACE = 'Backspace';
const ENTER_KEY = 'Enter';
const SPACE_KEY = ' ';

const CHIP_STYLE = {
  style: {
    margin: '0.1875rem 0.125rem 0.125rem 0.125rem'
  }
};
const PLACEHOLDER = 'Select';
interface DataList {
  data: Array<any>,
  optionsData: object,
  active_step: string,
  updateDispatchStore?: Function
  isDisabled: boolean,
  errorMessageText?: Function,
  ref: object,
  dataStore?:Object
}
/** Example of non-searchable SelectItem with mutiple selections allowed with a dropdown list with avatars. */
const MultiSelect = (props: DataList) => {
  const getOptionsProcessed = () => {
    let optionsdata = [];
    const result = {};
    if(props.optionsData && props.optionsData[props.data[0]]) {
      optionsdata = props.optionsData[props.data[0]];
      optionsdata.forEach(item => {
        result[item.id] = item.value;
      });
    }
    return result;
  }
  
  // const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const selectItemRef = useRef(null);
  const selectItemButtonRef = useRef(null);
  const [values, setValues] = useState(setSelectedValues());
  const [isOpen, setIsOpen] = useState(false);
  const label = props.data[1].required ?  props.data[1].label + ' *' : props.data[1].label;
  
  const optionsObj = getOptionsProcessed();
  const keysArray: string[] = Object.keys(optionsObj);
  const valuesArray: string[] = Object.values(optionsObj);
  const ariaString = () => values.map((item) => {return item}).toString();
  const haveValues = () => values.length > 0;
  const disableState = props.data[1].disabled ? props.data[1].disabled : props.isDisabled;
  const isValueAlreadySelected = value => _findIndex(values, item => item === value) > -1;
  const [errorDisabled, setErrorDisabled] = useState(props.data[1].required);

  const {
      showHelpText
  } = HelpText();

  useEffect(() => {
    if (props.data[1].required) {
        if (props.dataStore && props.dataStore[props.data[0]] !== undefined) {
            const optionValue = props.dataStore[props.data[0]];
            if (optionValue.length !== 0) {
                setErrorDisabled(false)
            }
            else {
                setErrorDisabled(true)
            }
        }
    }
    setValues(setSelectedValues());
  }, [props.dataStore])

  function setSelectedValues(){
    let data_list = []
    if(props.dataStore && props.dataStore[props.data[0]] !== ""){      
      props.dataStore[props.data[0]] && Object.values(props.dataStore[props.data[0]])
      .map((item) => {
        if(item["key"] && item["key"].length > 0){
          data_list.push(item["key"])
        }        
      })
    }
    return data_list;
  }

  // TODO: Manage the Form field states only through Store values.
  // useEffect(() => {
  //   // console.log("props.data[1]",props.data[1])
  //   // if(props.data[1].default !== "") {
  //   //   const defaultVal = Object.values(props.data[1].default);
  //   //   setValues(defaultVal);
  //   // }
  //   // if(!_.isEmpty(props.dataStore) && props.data[1].multiple !== "" && props.data[1].multiple && props.dataStore[props.data[0]] !== undefined){
  //   //   setValues(setSelectedValues());
  //   // }
  // },[])

  // useEffect(() => {
  //   setValues(setSelectedValues());
  // },[props.dataStore , props.dataStore[props.data[0]]])

  // TODO: Remove the useEffect & states and replace it through redux.
  // useEffect(() => {
  //   if(props.data[1].multiple && props.data[1].multiple === true){
  //     // Multiple select box
  //     // Check for default value - expecting an Array
  //     // if(Array.isArray(props.data[1].default)){
  //     //   // default value is an array and not empty
  //     //   let keyValueObj = []
  //     //   props.data[1].default.forEach(item=>{
  //     //     keyValueObj.push({
  //     //       key:item,
  //     //       value:item
  //     //     })
  //     //   })
  //     //   setValues(props.data[1].default);
  //     //   props.updateDispatchStore(props.data[0],keyValueObj);
  //     // }
  //     // -- existing logic
  //     // -- Need to cross check the logic
  //     // else if(props.data[1].default !== "" && props.dataStore) {
  //     //   let newValues =[]
  //     //   let keyValueObj = []
  //     //   keyValueObj = props.dataStore[props.data[0]];
  //     //   if(keyValueObj){  
  //     //     Object.values(keyValueObj).forEach(function (item) {
  //     //       if(newValues[item.value] != "")
  //     //         newValues.push(item.value)
  //     //     })
  //     //     setValues(newValues);
  //     //     props.updateDispatchStore(props.data[0],keyValueObj);
  //     //   }
  //     // }
  //   }
    
  //   //   // -- existing logic
  //   //   // -- Need to cross check the logic
  //   //   if(props.data[1].default !== "") {
  //   //     if (props.dataStore){
  //   //       let newValues =[]
  //   //       let keyValueObj = []
  //   //       keyValueObj = props.dataStore[props.data[0]];
  //   //       if(keyValueObj){  
  //   //         Object.values(keyValueObj).forEach(function (item) {
  //   //           if(newValues[item.value] != "")
  //   //             newValues.push(item.value)
  //   //         })
  //   //         setValues(newValues);
  //   //         props.updateDispatchStore(props.data[0],keyValueObj);
  //   //       }
  //   //     }   
  //   //   }
    
    
  // },[props.dataStore])

  // handle list item selection and close dropdown list after item is selected
  const handleEvent = (index) => (event) => {
    const { type } = event;
    // const isValueAlreadySelected = value => _findIndex(values, item => item === value) > -1;
    let theValue = valuesArray[index];
    let newValues = [];
    let considerKey = false;
    if (type === 'keydown') {
      if (isSelectionKeyPressed(event.key)) {
        if (!isValueAlreadySelected(theValue)) {
          const newValues = [...values, theValue ];
          setValues(newValues);
        }
        setIsOpen(false);
      }
    } else if (type === 'click') {
      if (!isValueAlreadySelected(theValue)) {
        const newValues = [...values, theValue ];
        setValues(newValues);
      }
      setIsOpen(false);
    } 
    getKeyvalueData(props.data[0],index,'add',considerKey);
  };

  // remove closed chip from selected values
  const handleChipClose = (value) => () => {
    const valueIndex = _findIndex(values, item => item === value);
    if (valueIndex > -1) {
      const clonedValues = [...values];
      clonedValues.splice(valueIndex, 1);
      setValues(clonedValues);
      selectItemButtonRef.current && selectItemButtonRef.current.focus();
      getKeyvalueData(props.data[0],value,'delete', false);
    }
  };
  const getKeyvalueData = (dataSet,selectkey,ops,considerKey) => {
    const optionsList = props.optionsData[props.data[0]]
    let selectedValues = []
    if (ops === 'add') {
      const selectedOption = optionsList.filter( e => e.id === keysArray[selectkey]);
      const keyData = considerKey ? selectedOption[0].id : selectedOption[0].value;
      // Take the selected values from store
      selectedValues = Array.isArray(props.dataStore[dataSet]) ? [...props.dataStore[dataSet]] : [];
      // Check the current selection is already selected
      if (!isValueAlreadySelected(keyData)) {
        // Append the new selection if it is not in the selected list
        selectedValues = [...selectedValues, {key: keyData, value: selectedOption[0].value}]
      }
    } else if (ops === 'delete') {
      selectedValues = props.dataStore[dataSet].filter((item) => item.value !== selectkey)
    }
    props.updateDispatchStore(dataSet,selectedValues);
        
}

  const buildKeyvalueData = (optionList) => {
    const arrayOfObjects = Object.entries(optionList).map(([key, value]) => ({
      key: key,
      value,
    }))
    return arrayOfObjects;
  }

  const renderClearButton = (props) => {
    return (
      <SelectItemClearButton
        aria-label="clear input"
        onClick={() => {setValues([]); selectItemButtonRef.current && selectItemButtonRef.current.focus();}}
        {...props}
        >
        <CloseSmallIcon />
      </SelectItemClearButton>
    );
  };

  const renderSelectItemBase = (props)  => {
    // only show ClearButton when there is at least 1 item selected and the menu is opened
    const showClearButton = haveValues() && isOpen;
    return (
      <SelectItemButton
        ref={selectItemButtonRef}
        placeholder={PLACEHOLDER}
        dropdownIcon={<ChevronDownSmallIcon />}
        inputProps={{ value: haveValues() ? ariaString() : PLACEHOLDER }}
        renderClearButton={showClearButton ?  renderClearButton : undefined}
        role="combobox"
        {...props}
      >
        {haveValues() && values.map((item, x) => {
          return (
            <Chip
              key={`chip-${item}-${x}`}
              disabled={disableState}
              tabIndex={0}
              role="comment"
              size="small"
              aria-label={item}
              {...CHIP_STYLE}
              onClick={(event) => {event.stopPropagation();}}
              onKeyDown={(event) => {
                if (event.key === BACKSPACE || event.key === DELETE) {
                  handleChipClose(item)();
                }}}
            >
              <ChipLabel label={item} />
              <ChipIconButton aria-label={`remove ${item}`} onClick={handleChipClose(item)}><CloseSmallIcon /></ChipIconButton>
            </Chip>
        )})}
      </SelectItemButton>
    )
  };

  return (
    <>
    <div style={{ display: 'flex'}}>
      <Label
        id="selectitem-component-label"
        variant="vertical-layout"
      >
        <SelectItemLabelContent variant='default'>
          {label}
        </SelectItemLabelContent>
      </Label>
      {showHelpText(props.data)}
    </div>
       <SelectItem
          ref={selectItemRef}
          aria-labelledby="selectitem-component-label"
          aria-label={haveValues() ? ariaString() : PLACEHOLDER}
          disabled={props.isDisabled}
          multiSelect
          isOpen={isOpen}
          onOpen={() => { setIsOpen(true); }}
          onClose={() => { setIsOpen(false); }}
          truncateListText
          listProps={{ ulProps: { role: 'listbox' } }}
          renderSelectItemBase={renderSelectItemBase}
          error={errorDisabled}
        >
          {valuesArray.map((item, x) => {
            return (
              <SelectListItem
                role="option"
                key={`${keysArray[x]}`}
                selected={_findIndex(values, value => value === item) > -1}
                onClick={handleEvent(x)}
                onKeyDown={handleEvent(x)}
                aria-label={item}
                style={{ height: '3rem', minHeight: '3rem',  maxHeight: '3rem'}}
              >
                {/* <Avatar size="medium">{item.substring(0,2).toUpperCase()}</Avatar> */}
                <SelectItemText style={{ paddingLeft: '1rem',}} >{item}</SelectItemText>
              </SelectListItem>
            );
          })}
        </SelectItem>
        {(errorDisabled ? <>{props.errorMessageText(props.data[1].label)}</> : '')}
    </>
  )
};

export default MultiSelect;