import React from "react";
import { Button } from "@nokia-csf-uxr/ccfk";
import { RootState } from "Store/mainStore";
import { useDispatch, useSelector } from "react-redux";
import { useLoadUI } from "./useLoadUI";
import { ButtonIcon } from "@nokia-csf-uxr/ccfk/IconButton";
import FindIcon from "@nokia-csf-uxr/ccfk-assets/legacy/FindIcon";
import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import { useConfigurator } from "./useConfigurator";
import { useDataCallBack } from "./useDataCallback";
import { useLocalStateManagement } from "./useLocalStateManagement";
import { setProp } from "Store/Actions";
import _ from "lodash";

export const useSeachBlock = (allRefs) => {
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const themeSelector = useSelector((state: RootState) => state.theme);
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const fieldsDisableEnable = themeSelector.data.button.isDisable.options_global;
  const dispatch = useDispatch();
  const solutionCategory = configstepperSelector.data.solutionCategory;
  const solutionType = configstepperSelector.data.solutionType;

  const { getClassBlock } = useConfigurator();
  const {checkDataCallback} = useDataCallBack(allRefs);
  const { setState } = useLocalStateManagement();
  const { LoadElements } = useLoadUI(allRefs);

  const getSearchDetails = (activePackages) => {
    let buttonData = {}
    dispatch(setProp({ slice: "dynamic", key: `setAccessoriesButton`, value: false }));
    const targetFields = dynamicSelector.data.setTargetFields;
    const stateTarget = { ...targetFields };
    let activeStepTarget = (stateTarget[configstepperSelector.data.active_step]) ? stateTarget[configstepperSelector.data.active_step] : [];
    

    Object.entries(activePackages).forEach(function (item) {
      if (item[1]['data_callback'] && item[1]['data_callback']['button']) {
        let value = dynamicSelector.data.element[configstepperSelector.data.active_step][item[0]];
        if (!activeStepTarget.includes(item[0])) {
          activeStepTarget.push(item[0]);
        }
        // hard coded - we dont want to call api for catalog items here
        if(!_.includes(["catalog_items","ndac_accessories"],item[0])) {
          checkDataCallback(item, value, item[0], false);
        }
        buttonData[item[0]] = item[1]
      }
    });

    const targetVal = {
      ...targetFields, //getState('targetFields'),
      [configstepperSelector.data.active_step]: activeStepTarget
    };
    // Set current target fields - For add to cart status check
    dispatch(setProp({ slice: "dynamic", key: `setTargetFields`, value: targetVal }));
  }

  /** search_submitButton_status */
  const search_submitButton_status = (deviceBlock) => {
    let buttonStatus = fieldsDisableEnable ? true : false;
    Object.entries(JSON.parse(JSON.stringify(deviceBlock))).forEach(function (item) {
        if (dynamicSelector.data.element[configstepperSelector.data.active_step]) {
            if (!dynamicSelector.data.element[configstepperSelector.data.active_step][item[0]] || dynamicSelector.data.element[configstepperSelector.data.active_step][item[0]].length === 0) {
                buttonStatus = true
            }
        }
    });
    setState('setSearchButtonStatus',buttonStatus);
    return buttonStatus
  }

  /** Rerender search block */
  const renderSearchBlock = (activePackages,renderConfig=undefined) => {
    const deviceBlock = dynamicSelector.data.dataFromPackage['deviceBlock'];
    const searchElements = JSON.parse(JSON.stringify(deviceBlock));
    return (
      <div style={getClassBlock()}>
        <>{LoadElements(searchElements, activePackages,renderConfig)}</>
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            disabled={search_submitButton_status(deviceBlock)}
            variant="neutral"
            onClick={() => {
              if (
                _.includes(["NDAC","MPW"],solutionCategory) &&
                  (configstepperSelector.data.active_step === "devices" || 
                    configstepperSelector.data.active_step === "connectivity") 
                    && solutionType !== 'Standalone'
              ) {
                getSearchDetails(activePackages);
              }
            }}
          >
            <ButtonIcon>
              <FindIcon />
            </ButtonIcon>
            <ButtonText>Search</ButtonText>
          </Button>
        </div>
      </div>
    );
  };

  return {
    renderSearchBlock
  }
}