import { createSlice } from "@reduxjs/toolkit";
import setObj from "lodash/set";
import { setProp } from 'Store/Actions';
import { setPropT } from "Store/Actions/ts/index";
// import { setPropT } from "./Actions/ts";
const initialSelectisOpen = {
  findCase_caseType: false,
  findCase_subType: false,

  caseDetails_country: false,
  caseDetails_currency: false,
  caseDetails_shipping_country: false,
  caseDetails_account: false,
  caseDetails_distributor: false,
  caseDetails_indirect_resellers: false,
  caseDetails_resellers: false,
  caseDetails_endCustomer: false,
  caseDetails_sales_channel: false,
  caseDetails_market: false,
  caseDetails_network: false,

  connectivity_radio_frequency_band: false,
  connectivity_private_wireless_users: false,
  connectivity_bandwidth: false,

  warranty_extend_warranty: false,
  warranty_subscription_duration: false,
  caseDetails_network_id: false,

  services_HSE_Value: false,
  services_no_of_installation_locations: false,
};

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    data: {
      select: {
        isOpen: initialSelectisOpen,
        isDisabled: {
          caseDetails_endCustomer: false,
        },
        isVisible: {
          caseDetails_endCustomer: true,
          caseDetails_indirect_resellers: true,
          caseDetails_resellers: true,
          caseDetails_distributor: true,
        }
      },
      panel: {
        isOpen: {
          reviewSubmit_caseDetails: true,
          global: false,
          subcon_implmentation: false,//Implementation Service - Subcon Cost,
          travelcost: false,//Implementation Service - Subcon Travel Cost,
          sitesurvey: false,//Site Survey - Subcon Cost
          no_of_installation_locations: false, //|Default: 1
          PM_dur_months: false,//Project Duration - replace filler {{pmdurmonths }},if this exisits.
          HSE_Value: false,///Health & Safety Management | Default "Low", - based on selection,align with filler
          Nokia_DAC_Aware: false,
          Training_Service_on_site_: false,
          Nokia_DAC_Installer: false,
          Nokia_DAC_Operator: false,
          Training_Service_remote_: false,
          Nokia_DAC_Edge_Networking: false,
          NDAC_Sub_con_Training_Physical_Install: false
        },

      },

      dropDown: {
        isOpen: {
          header_userAccountSummary: false,
          body_quickHelpMenu: false,
        }
      },
      stack: {
        isOpen: !false,
        isOnline: true, //determin weather handshake with nay api failed.
      },
      modal: {
        isOpen: {
          connectivity_catalog: false,
          warranty_subscription: false,
        }
      },
      snackbar: {
        isOpen: {
          network_failed: true,
        }
      },
      popUp: {
        isOpen: {
          header_tokenExpire: false,
        }
      },
      button: {
        isToggle: {
          applications_editSelection: true, // Make ATC Button == Active when True for all Steps, when editing is done.
          connectivity_editSelection: true, // Make ATC Button == Active when True for all Steps, when editing is done.
          business_undo: true,
          connectivity_undo: false,
          //dynamically rendered.
        },
        isDisable: {
          options_global:false,
        },
      }
    }
  },
  reducers: {
    resetTheme(state,) {
      state.data.select.isOpen = initialSelectisOpen;
    },
    addTheme(state,action){
      state.data.select.isOpen = action.payload.newdata_list
    },
    setTheme(state, action) {
      const { element, prop, comp, value } = action.payload;
      state.data[element] = {
        ...state.data[element],
        [prop]: { ...state.data[element][prop], [comp]: value },
      };
    },
    setVisible(state, action) {
      const { id, value } = action.payload;
      id.forEach(element => {
        state.data.select.isVisible[`caseDetails_${element}`] = value;
      });

    },
    setLive(state, action) {
      state.data.stack.isOnline = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(setProp, (state, action: setPropT) => {
      if (action.payload.slice === themeSlice.name) {
        setObj(state.data, action.payload.key, action.payload.value);
      }
    })
  }
});

// the return type : set to logic and return type is not mentioned.

export default themeSlice.reducer;
export const { setTheme, setVisible, setLive, resetTheme, addTheme } = themeSlice.actions;
