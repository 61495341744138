import React from "react";
import { InlineFeedbackMessage, Typography } from "@nokia-csf-uxr/ccfk";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";

const PageInlineFeedback = () => {
  const pageFeedbacks = useSelector(
    (state: RootState) => state.dynamic.data.pageFeedbacks
  );
  const allowedVariants = ["warning","error","info","success"];
  return (
    <>
      {pageFeedbacks.map((pageFeedack,i) => (
        <InlineFeedbackMessage
          key={`${pageFeedack.type}_${i}`}
          show={true}
          variant={allowedVariants.includes(pageFeedack.variant) ? pageFeedack.variant: "info"}
          style={{ marginBottom: "10px" }}
        >
          <Typography>{pageFeedack.message}</Typography>
        </InlineFeedbackMessage>
      ))}
    </>
  );
};

export default PageInlineFeedback;
