import React from 'react';

// Shared icons
import WifiOpenIcon from '@nokia-csf-uxr/ccfk-assets/legacy/WifiOpenIcon';

// caseTypesIcons
import EditIcon from '@nokia-csf-uxr/ccfk-assets/latest/EditIcon';
import SubnetsTabVipIcon from '@nokia-csf-uxr/ccfk-assets/legacy/SubnetsTabVipIcon';
import MinimizeIcon from '@nokia-csf-uxr/ccfk-assets/latest/MinimizeIcon';
import TrendUpIcon from '@nokia-csf-uxr/ccfk-assets/legacy/TrendUpIcon';
import TickCircleIcon from '@nokia-csf-uxr/ccfk-assets/latest/TickCircleIcon';

// caseSubTypesIcons
import HelpCircleIcon from '@nokia-csf-uxr/ccfk-assets/latest/HelpCircleIcon';
// import WifiOpenIcon from '@nokia-csf-uxr/ccfk-assets/latest/WifiOpenIcon';
import HostsIcon from '@nokia-csf-uxr/ccfk-assets/legacy/HostsIcon';
import NetworkElementVirtualIcon from '@nokia-csf-uxr/ccfk-assets/legacy/NetworkElementVirtualIcon';
import ModuleIcon from '@nokia-csf-uxr/ccfk-assets/latest/ModuleIcon';

// caseTypesIcons
export const caseTypesIcons = new Map([
  [
    "new",
    () => <EditIcon />,
  ],
  [
    "addition_to_existing_edge",
    () => <WifiOpenIcon />,
  ],
  [
    "non_commercial_network",
    () => <SubnetsTabVipIcon />,
  ],
  [
    "internal_order",
    () => <MinimizeIcon />,
  ],
  [
    "demand_forecast",
    () => <TrendUpIcon />,
  ],
  [
    "frame_agreement",
    () => <TickCircleIcon />,
  ],
]);
// caseSubTypes icon map
export const caseSubTypesIcons = new Map([
  [
    "help",
    () => <HelpCircleIcon />,
  ],
  [
    "pkg_plug_play",
    () => <WifiOpenIcon />,
  ],
  [
    "devices_only",
    () => <HostsIcon />,
  ],
  [
    "perimeter_nw",
    () => <NetworkElementVirtualIcon />,
  ],
  [
    "modular",
    () => <ModuleIcon />,
  ],
]);