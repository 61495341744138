//@ts-nocheck
import { DataGrid, HorizontalDivider, Skeleton, Tabs, TextInput, Typography, VerticalDivider } from '@nokia-csf-uxr/ccfk'
import { Tab } from '@nokia-csf-uxr/ccfk/Tabs'
import { TextInputLabelContent } from '@nokia-csf-uxr/ccfk/TextInput'
import ProductList, { IProduct } from 'Components/Elements/ProductList'
import QuantityModifier from 'Components/Elements/QuantityModifier'
import { prepareCart } from 'Store/contentState/cartBoqSlice'
import { RootState } from 'Store/mainStore'

import { ChipLabel, InfoChip } from '@nokia-csf-uxr/ccfk/Chip'
// import { useQuery } from '@tanstack/react-query'
// import OOT from 'Constants/services/oot'
// import { setConnectivityTabRules } from 'Store/contentState/stateMachine'
import { EmptyState } from 'Components/Content/EmptyState'
import { roundCount } from 'Utils'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCartItem, removeCartItem } from 'Store/Actions'
// import { EmptyState } from '../EmptyState'
interface IProductCatalog {
    catalog: object,
    menus: Array<any>,
    onTabSelect?: Function,  /*@event: string:selected-menu*/
    getCatalog?: Function,  /*  @event: string:selected-menu*/
    getSelectedProduct?: Function, /* @event {type:"add/remove",item:{@product}} */
}
const ProductCatalog = (props: IProductCatalog) => {
    const connectivitySelector = useSelector((state: RootState) => state.connectivity);
    const cartBoqSelector = useSelector((state: RootState) => state.cartBoq);
    // const authSelector = useSelector((state: RootState) => state.authSlice);
    const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
    // const themeSelector = useSelector((state: RootState) => state.theme);
    const stateMachineSelector = useSelector((state: RootState) => state.stateMachine);
    const dispatch = useDispatch();
    const [searchQuery, setQuery] = useState('');
    const [selectedTab, setSelectedTab] = useState(props.menus[0].id);
    //TODO: props.catalog[selectedTab][0] is Haparams.datacoded from backened side to get the products for expected selected tab,expect to send a 1d array.
    const getFilterProducts = (): IProduct[] => {
        if (searchQuery.length > 0) {
            // assign cost = list_price , as we are using cost to display in selected items
            const res = props.catalog[selectedTab].filter((e) => (e.product_id === searchQuery) || (e.product_name.toLowerCase().includes(searchQuery.toLowerCase())))
            return res;
        }
        return props.catalog[selectedTab];
    }

    const products = useMemo(() => getFilterProducts(), [searchQuery, selectedTab]);
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignContent: 'center' }}>
                <div id="catalog-items" style={{ maxWidth: '50%' }}>
                    <Tabs>
                        {
                            (props.menus.length === 0) ? (<Skeleton style={{ width: '350px' }} />) : (
                                <>
                                    {
                                        props.menus.map((type, i) => {
                                            return (<Tab key={type.id}

                                                onSelect={() => {
                                                    setSelectedTab(type.id);
                                                    props.onTabSelect(type.id);
                                                }}
                                                selected={selectedTab === type.id}>{type.value}</Tab>)
                                        })
                                    }
                                </>
                            )

                        }


                    </Tabs>
                    {
                        (props.menus.length === 0) ? (<Skeleton style={{ width: '350px', textAlign: 'center', justifyContent: 'center', alignSelf: 'center' }} height="30px" />) : (<TextInput placeholder='Search the products' onChange={(e) => setQuery(e.target.value)} value={searchQuery} />)
                    }

                    <div id='tabContent'>
                        <div style={{ display: (stateMachineSelector.PTDAC.activeTab === "connectivity") ? "block" : "none" }}>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                <div>
                                    <InfoChip status={'info'} size={'small'} className='m'>
                                        <ChipLabel label="Radio Frequency Band: " secondaryLabel={connectivitySelector.data.options.PTDAC.radio_frequency_band} />
                                    </InfoChip>
                                    {/* <Chip variant='outlined'><ChipLabel label="Radio Frequency Band" secondaryLabel={connectivitySelector.data.options.PTDAC.radio_frequency_band} /></Chip> */}
                                </div>
                            </div>
                        </div>
                        {
                            (props.menus.length === 0) ?
                                (<Skeleton style={{ width: '500px', }} height="250px" icon={'Loading...'} />) :
                                (

                                    (<>
                                        {
                                            (products.length === 0) ? (<Typography>No Results found</Typography>) : (
                                                <ProductList
                                                    products={products}
                                                    getCatalog={(e) => props.getCatalog(e, selectedTab)} //return the selected productCatalog + selectedTab
                                                    getSelectedProduct={(e) => {
                                                        props.getSelectedProduct(e)
                                                    }}
                                                />)
                                        }
                                    </>)

                                )
                        }

                    </div>
                </div>
                <VerticalDivider style={{ marginLeft: "1rem", marginRight: "1rem" }} />
                <div id="selected-items" style={{ flexGrow: '1' }}>
                    {
                        (cartBoqSelector.data.system.prepare.catalog.length === 0) ? (<EmptyState msg='Select items to add to cart' />) : (
                            <>
                                <TextInputLabelContent>Selected Item</TextInputLabelContent>
                                <HorizontalDivider className='mb mt' />
                                <DataGrid
                                    key={3}
                                    //  modules={AllCommunityModules}

                                    wrapperProps={{
                                        style: {
                                            height: '40vh',
                                            width: '100%'
                                        }
                                    }}
                                    rowData={cartBoqSelector.data.system.prepare.catalog}
                                    defaultColDef={{
                                        editable: false,
                                        sortable: true,
                                        flex: 1,
                                        minWidth: 100,
                                        filter: true,
                                        resizable: true,
                                    }}
                                    columnDefs={[{ headerName: 'Products', field: 'product_name', },
                                        {
                                        
                                        headerName: 'Quantity',
                                        field: 'quantity',
                                        cellRendererFramework: (params) => <div id={JSON.stringify(params.value)}>
                                            <QuantityModifier product={params.data}
                                                onUpdateQty={
                                                    (val) =>
                                                        Math.sign((params.data.quantity - val)) == - 1 ?
                                                            dispatch(addCartItem({ products: [new Object({ ...params.data, quantity: Math.abs((params.data.quantity - val)) })], targetItems: "catalog", parentSync: false, unWrap: false }))
                                                            : dispatch(removeCartItem({ products: [new Object({ ...params.data, quantity: Math.abs((params.data.quantity - val)) })], targetItems: "catalog", parentSync: false, unWrap: false }))
                                                }
                                                onDecQty={() => dispatch(removeCartItem({ products: [new Object({ ...params.data, quantity: 1 })], targetItems: "catalog", parentSync: true, unWrap: !false }))}
                                                onIncQty={() => {
                                                    dispatch(addCartItem({ products: [new Object({ ...params.data, quantity: 1 })], targetItems: "catalog", parentSync: true, unWrap: false }));
                                                }}
                                                // onDecQty={() => dispatch(prepareCart({ product: params.data, type: "catalog", method: "decQty" }))}
                                                // onIncQty={() => dispatch(prepareCart({ product: params.data, type: "catalog", method: "incQty" }))}
                                                value={(Math.round(params.value))} />
                                        </div>
                                    },
                                    { headerName: `Price (${caseDetailsSelector.data.currency}))`, field: 'cost', cellRendererFramework: (params) => roundCount(params.value) }]}
                                    onGridReady={(params) => {
                                    }}
                                    isInlineRowActions={false}
                                    isRowDrag={false} />

                            </>)
                    }
                </div>

            </div>

        </>
    )
}

ProductCatalog.propType = {
    catalog: Object,
    menus: Array,
    onTabSelect: Function,
    onProductSelect: Function,
}
export default ProductCatalog