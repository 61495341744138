export const georedundancyOptions = [
    { id: "yes", value: "YES" },
    { id: "no", value: "NO" },
];
export const powerOptions = [
    { id: "ac", value: "AC" },
    { id: "dc", value: "DC" },
];
export const connectorTypeOptions = [
    { id: "sfp", value: "SFP" },
    { id: "dac", value: "DAC" },
];
export const connectorSwitchOptions = [
    { id: "yes", value: "Nokia" },
    { id: "no", value: "3rd Party" },
];

export const switchVariantOptions = {
    allIds: [{ id: "LS", value: "Large" },{ id: "SS", value: "Small" }],
    byIds: {
        "LS": { throughtput_1: "8 X 25 GE", throughtput_2: "8 X 25 GE", size: "Large" },
        "SS": { throughtput_1: "4 X 25 GE", throughtput_2: "12 X 25 GE", size: "Small" } ,
    }
};
export const mixeServerOptions = {
    allIds: [{ id: "LMS", value: "Large" },{ id: "SMS", value: "Small" }],
    byIds: {
        "LMS": { size: "Large" },
        "SMS": { size: "Small" } ,
    }
};
