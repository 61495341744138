
import { Card } from '@nokia-csf-uxr/ccfk';
import { TextInputLabelContent } from '@nokia-csf-uxr/ccfk/TextInput';
// import { subscriptionPlansOptions } from 'Components/Content/configurationArea/Warranty/system';
import PropTypes from 'prop-types';
import React from 'react';
import SinglePlanCard from 'Components/Content/SinglePlanCard';
// import SinglePlanCard from '../SinglePlanCard';

interface IPlansProps {
  selected: string,
  onSelect: Function,
  cards: Array<any>,
}
const Plans = (props: IPlansProps) => {
  //TODO: Make it Dynamic

  return (
    <div id="plans-modal">
      <TextInputLabelContent style={{ textAlign: 'center' }}>Select the subscription plan</TextInputLabelContent>
      <div id="plans" >
        <div id="plan-collection" style={{ display: 'flex', margin: 'auto 0', justifyContent: 'space-around' }} >
          {
            props.cards.map((plan, id) => {
              return (
                <>
                  <Card id='card1' data-grid={{ x: id, y: 0, w: 1, h: 1 }}
                   onSelect={() => props.onSelect(plan)} selected={props.selected === plan.id} key={plan.id} >
                    <SinglePlanCard planID={plan.id} />
                  </Card>
                </>)
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Plans

Plans.propType = {
  selected: PropTypes.string,
  onSelect: PropTypes.func,
  cards:PropTypes.array
}