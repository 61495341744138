import React, { Fragment, useEffect, useState, memo } from "react";

import Planner from "../NDACPlanner/Planner";
import CustomTab from "../Components/Tab/CustomTab";

interface INDACPlanner {
  renderDynamicContent?: Function;
  activePackages?: Object;
  renderActionButton?: Function;
}

const NDACPlanner = ({
  renderDynamicContent,
  activePackages,
  renderActionButton,
}: INDACPlanner) => {
  const planner = () => {
    return (
      <Fragment key={0}>
        <div style={{ marginTop: "10px"}}>
          <Planner />
        </div>
      </Fragment>
    );
  };

  const selectProducts = () => {
    return (
      <Fragment key={1}>
        <div style={{ marginLeft: "2%", marginTop: "10px" }}>
          {renderDynamicContent(activePackages)}
          {renderActionButton()}
        </div>
      </Fragment>
    );
  };

  const TAB_DATA = [
    {
      label: "Available Radio Network Plan(S)",
      tab: "Available Radio Network Plan(S)",
      index: 0,
      content: planner(),
    },
    {
      label: "Select Products",
      tab: "Select Products",
      index: 1,
      content: selectProducts(),
    },
  ];

  return (
    <>
      <CustomTab tabData={TAB_DATA} />
    </>
  );
};

export default memo(NDACPlanner);
