import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "Components/Misc/CaseDetails_System";
import { setProp } from "Store/Actions";
import { setPropT } from 'Store/Actions/ts';
import _ from "lodash";
const generalView = {
    services: [],
    userSelections: {
        subcon_implmentation: null,//Implementation Service - Subcon Cost,
        travelcost: null,//Implementation Service - Subcon Travel Cost,
        sitesurvey: null,//Site Survey - Subcon Cost
        no_of_installation_locations: 1, //|Default: 1
        PM_dur_months: null,//Project Duration - replace filler {{pmdurmonths }},if this exisits.
        ///Health & Safety Management | Default "Low", - based on selection,align with filler
        HSE_Value: "Low",
        Nokia_DAC_Aware: 0,
        Training_Service_on_site_ : 0,
        Nokia_DAC_Installer : 0,
        Nokia_DAC_Operator: 0,
        Training_Service_remote_ : 0,
        Nokia_DAC_Edge_Networking: 0,
        NDAC_Sub_con_Training_Physical_Install: 0 //this is not actually requied but adding for cui_servicedID_assoc_fields - HSE_Value_Dropdown
    },
    category: {
        all: [],
        segment_oriented: ['P560289', 'P560286'], // TODO: HOTFIX: APDAC.
        HSE_Value: "Low", //this is not actually requied but adding for cui_servicedID_assoc_fields - HSE_Value_Dropdown
        Nokia_DAC_Aware: 0,
        Training_Service_on_site_: 0,
        Nokia_DAC_Installer: 0,
        Nokia_DAC_Operator: 0,
        Training_Service_remote_: 0,
        Nokia_DAC_Edge_Networking: 0,
        NDAC_Sub_con_Training_Physical_Install: 0
    }
}
const initialData = {
    view: {},
    options: {
        PTDAC: {
            ...generalView
        }
    }
};
const servicesSlice = createSlice({
    initialState: {
        data: initialData,
        status: STATUS.IDLE,
    },
    name: 'services',
    reducers: {
        setServices(state, action) {
            if (action.payload.method === "add") {
                //Bulk Add
                if (Array.isArray(action.payload.service)) {
                    let totalServies = [...state.data.options.PTDAC.services, ...action.payload.service];
                    let uniqueServices = _.uniqBy(totalServies, v => [v.product_id, v.type, v.service].join());
                    const servicesWithMaxPrice = _.map(uniqueServices, (service) => {
                        const maxPriceService = _.maxBy(totalServies, (s) => (s.service === service.service ? s.list_price : false));
                        return maxPriceService;
                    });
                    state.data.options.PTDAC.services = servicesWithMaxPrice;

                    return;
                }
                //One-By-One
                if (!_.some(state.data.options.PTDAC.services, (serv) => serv.product_id === action.payload.service.product_id)) {
                    state.data.options.PTDAC.services.push(action.payload.service);
                }
            }
            if (action.payload.method === "remove") {
                _.remove(state.data.options.PTDAC.services, function (n) {
                    return n.id === action.payload.service.id;
                });

            }
        },
    },
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: setPropT) => {
            if (action.payload.slice === servicesSlice.name) {
                _.set(state.data, action.payload.key, action.payload.value);
            }
        })
    }
});

export default servicesSlice.reducer;
export const { setServices } = servicesSlice.actions;