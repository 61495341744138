//@ts-nochec
import React, { useEffect, useState, memo } from "react";
import { RootState } from "Store/mainStore";
import { useDispatch, useSelector } from "react-redux";
import { addTheme } from "Store/theme";
import { setProp } from "Store/Actions";
import {
  setElement,
} from "Store/contentState/configurationArea/dynamicSlice";
import _ from "lodash";
import ReviewIndex from "Components/Content/configurationArea/Review";
import { useConfigurator } from "./hooks/useConfigurator";
import { addToCartLogic } from "./configurator_AddToCart";
import PageInlineFeedback from "./Components/PageInlineFeedback";
import ActionButtons from "./Components/ActionButtons";
import { useStatesOfElement } from "./hooks/useStatesOfElement";
import { useAddElement } from "./hooks/useAddElements";
import MPW from "./mpw/MPW";
import { useRenderDynamicElements } from "./hooks/useRenderDynamicElements";
import { useDataCallBack } from "./hooks/useDataCallback";
import { useLocalStateManagement } from "./hooks/useLocalStateManagement";
import { useProductDialogModal } from "./hooks/useProductDialogModal";
import { getStateOfElement } from "Components/Logics/stateMachine/modules/CONFIG/configurator_statelogics";
import NDACPlanner from "./NDACRefereceSolution/NDACPlanner";
import { useActivePackages } from "./hooks/useActivePackages";

const Options = () => {
  /** Selectors */
  const themeSelector = useSelector((state: RootState) => state.theme);
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const stateMachineSelector = useSelector(
    (state: RootState) => state.stateMachine
  );
  const caseDetailsSelector = useSelector(
    (state: RootState) => state.caseDetails
  );
  const findCaseSelector = useSelector((state: RootState) => state.findCase);
  /** Hooks */
  const dispatch = useDispatch();
  const {
    setPageFeedbacks,
    getHiddenFieldDataCallBack,
    getDataCallbackMMS,
    getPackageFieldList,
    setOptionsGlobalState,
    getListOfFields,
    getAllPackageFields,
    getAllFieldsInPackage
  } = useConfigurator();

  /** Component states */
  const allRefs = React.useRef([]);

  const { renderDynamicContent } = useRenderDynamicElements(allRefs);
  const { getElementsStates } = useStatesOfElement(allRefs);
  const { addElement } = useAddElement();
  const { addCallBack } = useDataCallBack(allRefs);

  const [applicationSelectedCount, setAppCount] = useState({ key: null, value: null });
  const [dummyAddCart, setDummyAddCart] = useState(null);

  const [cartButtons, setCartButtons] = useState([]);
  const [addCart, setAddCart] = useState(true);
  const { setState, getState } = useLocalStateManagement();

  /** Component variables */
  const api_data_call = {
    devices: "ndac_accessories",
    connectivity: "catalog_items",
    mxie: "mxie_server_consumption",
  };
  const packageData = getPackageFieldList(configstepperSelector.data.active_step);
  const { getDeviceBlockData, getActivePackagesData} = useActivePackages();

  const activePackages = getActivePackagesData();
  let deviceBlock = getDeviceBlockData();
  /** getBlockContent */
  // const getBlockContent = (packageDataDetails) => {
  //   const { dB, jD } = preparePackageElementContent(packageDataDetails);
  //   deviceBlock = { ...dB };
  //   return JSON.parse(JSON.stringify(jD));
  // };

  // const activePackages = getBlockContent(packageData);
  const steps = configstepperSelector.data.stepper_data;
  const getCurrentStep = steps.find((item) => item.status === "current");

  const solutionCategory = configstepperSelector.data.solutionCategory;
  const solutionType = configstepperSelector.data.solutionType;

  // const selectors = getSelectors();
  const selectors = {
    dynamic: dynamicSelector,
    config_stepper: configstepperSelector,
    caseDetails: caseDetailsSelector,
    findCase: findCaseSelector,
    dispatch: dispatch,
    getElementsStates: getElementsStates,
    getListOfFields: getListOfFields,
    getAllPackageFields: getAllPackageFields(),
    getAllFieldsInPackage : getAllFieldsInPackage
  };

  const {openProductDialogModal} = useProductDialogModal(activePackages,packageData,selectors);

  // Component Side effects
  useEffect(() => {
    __onComponentMount();
  }, []);

  // this useeffect will trigger when applicationcount changes/ dummycart flag value changes
  // dummyaddcart flag changes when add to cart button is clicked everytime.
  useEffect(() => {
    if (dummyAddCart !== null || applicationSelectedCount.key !== null) {
      // setisDisabled(addToCartLogic(activePackages, packageData, selectors));
      addToCartLogic(activePackages, packageData, selectors);
    }
  }, [applicationSelectedCount, dummyAddCart]);

  useEffect(() => {
    __onDynamicElementStoreChange();
    updateSearchBlock();
  }, [dynamicSelector.data.element[configstepperSelector.data.active_step]]);

  // Reset catalog apiload indicator to false
  useEffect(() => {
    setState('setProdCatalogApiLoad',false);
    // call the addcallback first time only when deviceblock is having data
    // if first step something else than device then it will be empty
    if(!_.isEmpty(deviceBlock)) {
      addCallBack(true, '', '', deviceBlock);
    } else if(solutionType === 'Standalone') {
      // if solution is standalone then add callbacks for cmu ( as of now all callbacks are added)
      // Need improvement
      addCallBack(true, '', '', deviceBlock);
    }
  }, [configstepperSelector.data.active_step]);

  useEffect(() => {
    setOptionsGlobalState();
    if(configstepperSelector.data.active_substep !== "radio_solution_connectivity") {
      setPageFeedbacks("errorStatus_radio_totalSites", false)
    }
  }, [configstepperSelector.data.active_substep]);


  const updateSearchBlock = () => {
    // -----store the packagedata into store to use globally-----//
    if (deviceBlock !== dynamicSelector.data.dataFromPackage["deviceBlock"]) {
      dispatch(
        setProp({
          slice: "dynamic",
          key: "dataFromPackage",
          value: { packageData: packageData, deviceBlock: deviceBlock },
        })
      );
    }
  };

  /** onComponentMount */
  const __onComponentMount = () => {
    getDataTest();
    getFieldTypeSelect();
    const elementData = addElement(allRefs);
    setState('setOptionsData', elementData.storeElement);
    dispatch(setElement(elementData.element));
    dispatch(setProp({ slice: "dynamic", key: "actionNext", value: true }));
  };

  /** onDynamicElementStoreChange */
  const __onDynamicElementStoreChange = () => {
    addCartButtonStatus();
    setHiddenField();
    callDataCallHiddenField();
    const consumptionValue = configstepperSelector.data.active_step === "mxie" ? true : false;
    setState('setConsumptionGraph', consumptionValue);
    setOptionsGlobalState();
    setOptionData();
    setState('setOptionsData', getState('optionsData'));
  };

  /** setOptionData */
  const setOptionData = () => {
    const dataInCurrentStep = dynamicSelector.data.element[configstepperSelector.data.active_step];
    const optionsDataList = getState('optionsDataList');
    const optionsData = getState('optionsData');
    if (dataInCurrentStep) {
      let validatedCheck = [];

      Object.entries(activePackages).map((data, _index) => {
        const allStatesForElement = getElementsStates(activePackages[data[0]]);
        if (
          allStatesForElement.visibleValue !== "none" &&
          data[1]["required"]
        ) {
          if (dataInCurrentStep[data[0]] === "") {
            validatedCheck.push(data[0]);
          }
        }
        if (_.includes(["NDAC","MPW"],solutionCategory)) {
          if (
            (configstepperSelector.data.active_step === "devices" &&
              data[0] == "ndac_device_items") ||
            (configstepperSelector.data.active_step === "connectivity" &&
              data[0] == "connectivity_ndac_items")
          ) {
            if (optionsDataList) {
              let optionsDataListData = optionsDataList;
              optionsDataListData = {
                id: data[0],
                data:
                  dynamicSelector.data.element[
                    configstepperSelector.data.active_step
                  ][data[0]] != ""
                    ? dynamicSelector.data.element[
                    configstepperSelector.data.active_step
                    ][data[0]]
                    : [],
              };
              setState('setOptionsDataList', optionsDataListData);
            }
          }
          if (
            configstepperSelector.data.active_step == "mxie" &&
            data[0] == "mxie_server"
          ) {
            if (
              optionsData[configstepperSelector.data.active_step] &&
              optionsData[configstepperSelector.data.active_step][data[0]]
            ) {
              let optionDatalist = JSON.parse(JSON.stringify(optionsData)); //{...optionsData};
              optionDatalist[configstepperSelector.data.active_step][data[0]] =
                dynamicSelector.data.element[
                  configstepperSelector.data.active_step
                ][data[0]] != ""
                  ? dynamicSelector.data.element[
                  configstepperSelector.data.active_step
                  ][data[0]]
                  : [];
              setState('setOptionsData', optionDatalist);
              setState('setConsumptionGraph', true);
            }
          }
        }
      });

      if (validatedCheck.length === 0) {
        setPageFeedbacks("errorStatus", false);
      } else {
        setPageFeedbacks("errorStatus", true, `Please add valid values for all the highlighted fields in ${getCurrentStep.name} section`);
      }
    }
  };

  /**  setApplicationCount */
  const setApplicationCount = () => {
    const currentStep = configstepperSelector.data.active_step;
    if (_.includes(["applications",'use_cases_apps'],currentStep)) {
      const countappVariable = "count_of_applications_selected";
      const elem = `element.${currentStep}.` + countappVariable;
      const packageData = configstepperSelector.data.solution_form_data['packages'][currentStep];
      let formData = getPackageFieldList(configstepperSelector.data.active_step);
  
      if(packageData["field_details"]['applications']) {
        const packagedata = packageData["field_details"]['applications']["field_details"];
        const packageFields = _.keys(packagedata);
        formData = _.filter(formData,(obj) => _.includes(packageFields,obj.id));
      }
      const count_apps = getSelectedApplicationCount(formData);
      const appcount = { key: count_apps, value: count_apps };
      setAppCount(appcount);
      dispatch(setProp({ slice: "dynamic", key: elem, value: appcount }));
    } else {
      setDummyAddCart(!dummyAddCart);
    }
  };

  /** setHiddenCaseDetails */
  const setHiddenCaseDetails = () => {
    const currstep = configstepperSelector.data.active_step;
    const currstep_keys = _.keys(dynamicSelector.data.element[currstep]);
    if (_.includes(currstep_keys, 'case_type') && _.includes(currstep_keys, 'case_sub_type')) {
      const casetype = findCaseSelector.data.caseType;
      dispatch(
        setProp({
          slice: "dynamic",
          key: `element.${currstep}.case_type`,
          value: casetype,
        })
      );
      const case_subtype = findCaseSelector.data.subType;
      dispatch(
        setProp({
          slice: "dynamic",
          key: `element.${currstep}.case_sub_type`,
          value: case_subtype,
        })
      );
    }
  }

  /** setHiddenField */
  const setHiddenField = () => {
    if ((_.includes(["NDAC","MPW"],solutionCategory))) {
      setHiddenCaseDetails();
      if (
        dynamicSelector.data.element[configstepperSelector.data.active_step] &&
        !dynamicSelector.data.element[configstepperSelector.data.active_step][
        "country"
        ]
      ) {
        const country = {
          key: caseDetailsSelector.data.country,
          value: caseDetailsSelector.data.country,
        };
        dispatch(
          setProp({
            slice: "dynamic",
            key: `element.${configstepperSelector.data.active_step}.country`,
            value: country,
          })
        );
      }
    }
    // Set step state hidden field segment
    if(configstepperSelector.data.active_step && dynamicSelector.data.element[configstepperSelector.data.active_step]){
      // Check active_step === first dynamic step (exclude business step)
      if(configstepperSelector.data.active_step === steps[1]?.stepname){
        // Check dynamicSelector > element > <active_step> has a field segement and it has not a valid value set
        
        if(!dynamicSelector.data.element[configstepperSelector.data.active_step]["segment"]){
          const segment = {
              key: configstepperSelector.data.solution_selected,
              value: configstepperSelector.data.solution_selected,
            };
            dispatch(
              setProp({
                slice: "dynamic",
                key: `element.${configstepperSelector.data.active_step}.segment`,
                value: segment,
              })
            );
        }
      }      
    }
  };

  /** callDataCallHiddenField */
  const callDataCallHiddenField = () => {
    let packagesAllList =
      configstepperSelector.data.solution_form_data["packages"];
    Object.keys(packagesAllList).forEach(function (key) {
      const packageFieldDetails = getPackageFieldList(key);
      if (packageFieldDetails) {
        Object.entries(packageFieldDetails).map((data, _index) => {
          if (data[1]["type"] === "hidden" && data[1]["data_callback"]) {
            if (
              dynamicSelector.data.element[
              configstepperSelector.data.active_step
              ] &&
              dynamicSelector.data.element[
              configstepperSelector.data.active_step
              ][data[0]]
            ) {
              if (
                dynamicSelector.data.element[
                  configstepperSelector.data.active_step
                ][data[0]].key === 0
              ) {
                getHiddenFieldDataCallBack(data);
              }
            }
          }
        });
      }
    })
  }

  /** getDataTest */
  const getDataTest = () => {
    Object.entries(dynamicSelector.data.elementMMS).forEach(function (key) {
      const field_details = getPackageFieldList(key[0]);
      if (field_details) {
        Object.entries(field_details).map((data, _index) => {
          if (data[1]["type"] === "range" || data[1]["type"] === "number") {
            let dataMinMaxStep = ["min", "max", "steps"];
            dataMinMaxStep.forEach((mms) => {
              if (data[1][mms]["data_callback"]) {
                if (
                  dynamicSelector.data.elementMMS[key[0]] &&
                  dynamicSelector.data.elementMMS[key[0]][data[0]]
                ) {
                  if (
                    dynamicSelector.data.elementMMS[key[0]][data[0]][mms] === ""
                  ) {
                    getDataCallbackMMS(key[0], data, mms);
                  }
                }
              }
            });
          }
        });
      }
    });
  };

  const getFieldTypeSelect = () => {
    let test = {};
    Object.keys(activePackages).forEach(function (item) {
      let filedType = activePackages[item];
      if (filedType.type === "select") {
        test[item] = false;
      }
    })
    let newdata_list = { ...themeSelector.data.select.isOpen, ...test }
    dispatch(addTheme({ newdata_list }))
  }

  /** addCartButtonStatus */
  const addCartButtonStatus = () => {
    let cartButtons = [];
    //TODO: check this code - may be this code can be changed instead of looping
    Object.keys(activePackages).forEach(function (item) {
      if (activePackages[item]) {
        const allStatesForElement = getElementsStates(activePackages[item]);
        if (
          allStatesForElement.visibleValue !== "none" &&
          activePackages[item].required
        ) {
          cartButtons.push(item);
        }
      }
    });
    /** setCartButtons */
    setCartButtons(cartButtons)
    let testdata = []
    if (dynamicSelector.data.element[configstepperSelector.data.active_step]) {
      if (cartButtons.length !== 0) {
        cartButtons.forEach(function (data) {
          if (
            dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ][data] === ""
          ) {
            testdata.push(data);
          }
        });
      } else {
        Object.keys(activePackages).forEach(function (item) {
          if (
            dynamicSelector.data.element[configstepperSelector.data.active_step]
          ) {
            if (
              dynamicSelector.data.element[
              configstepperSelector.data.active_step
              ][item] !== ""
            ) {
              testdata.push(item);
            }
          }
        });
      }
    }

    if (cartButtons.length === 0) {
      if (cartButtons.length === 0 && testdata.length !== 0) {
        setAddCart(false);
      } else {
        setAddCart(true);
        dispatch(
          setProp({ slice: "dynamic", key: "actionNext", value: false })
        );
      }
    } else {
      if (cartButtons.length !== 0 && testdata.length === 0) {
        setAddCart(false);
      } else {
        setAddCart(true);
        dispatch(setProp({ slice: "dynamic", key: "actionNext", value: true }));
      }
    }
  };

  /**  getAddCartButtonStatus */
  const getAddCartButtonStatus = () => {
    if (
      (_.includes(["NDAC"],solutionCategory)) &&
      (configstepperSelector.data.active_step === "devices" ||
        configstepperSelector.data.active_step === "connectivity") && solutionType !== 'Standalone'
    ) {
      let isEnabled = false;
      const targetFields = dynamicSelector.data.setTargetFields; //getState('targetFields');
      if (
        configstepperSelector.data.active_step === "devices" &&
        dynamicSelector.data.element[configstepperSelector.data.active_step]
      ) {
        // Check sim_cards are set and not zero
        const sim_cards =
          dynamicSelector.data.element &&
            dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ] &&
            dynamicSelector.data.element[configstepperSelector.data.active_step][
            "sim_cards"
            ]
            ? dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ]["sim_cards"]
            : {};

        isEnabled = sim_cards.value && sim_cards.value > 0;
      }
      const currentTabTargetFields = targetFields[
        configstepperSelector.data.active_step
      ]
        ? targetFields[configstepperSelector.data.active_step]
        : [];
      currentTabTargetFields.forEach((target) => {
        const subItems = dynamicSelector.data.element[
          configstepperSelector.data.active_step
        ][target]
          ? Object.keys(
            dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ][target]
          )
          : [];
        subItems.forEach((item) => {
          isEnabled =
            isEnabled ||
            (dynamicSelector.data.element[
              configstepperSelector.data.active_step
            ][target][item] &&
              dynamicSelector.data.element[
                configstepperSelector.data.active_step
              ][target][item].length > 0 &&
              dynamicSelector.data.element[
                configstepperSelector.data.active_step
              ][target][item].some((row) => row.checked));
        });
      });
      if(configstepperSelector.data.active_substep) {
        isEnabled = true;
      }      
      return !isEnabled;
    }
    if (_.includes(["NDAC","MPW"],solutionCategory) && _.includes(["applications",'use_cases_apps'],configstepperSelector.data.active_step)
    ) {
      //@ts-ignore
      const formData = getPackageFieldList(configstepperSelector.data.active_step);
      const count_apps = getSelectedApplicationCount(formData);
      return !(count_apps > 0);
    } 
    else if((_.includes(["MPW"],solutionCategory)) && solutionType !== 'Standalone'){
      let isEnabled = false;
      const targetFields = dynamicSelector.data.setTargetFields; //getState('targetFields');
      // For Device step ---- This section should generic for all the solution
      // Need to refactor and seperate the logic
      if (
        configstepperSelector.data.active_step === "devices" &&
        dynamicSelector.data.element[configstepperSelector.data.active_step]
      ) {
        // Check sim_cards are set and not zero
        const sim_cards =
          dynamicSelector.data.element &&
            dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ] &&
            dynamicSelector.data.element[configstepperSelector.data.active_step][
            "sim_cards"
            ]
            ? dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ]["sim_cards"]
            : {};

        isEnabled = sim_cards.value && sim_cards.value > 0;

        const currentTabTargetFields = targetFields[
          configstepperSelector.data.active_step
        ]
          ? targetFields[configstepperSelector.data.active_step]
          : [];
        currentTabTargetFields.forEach((target) => {
          const subItems = dynamicSelector.data.element[
            configstepperSelector.data.active_step
          ][target]
            ? Object.keys(
              dynamicSelector.data.element[
              configstepperSelector.data.active_step
              ][target]
            )
            : [];
          subItems.forEach((item) => {
            isEnabled =
              isEnabled ||
              (dynamicSelector.data.element[
                configstepperSelector.data.active_step
              ][target][item] &&
                dynamicSelector.data.element[
                  configstepperSelector.data.active_step
                ][target][item].length > 0 &&
                dynamicSelector.data.element[
                  configstepperSelector.data.active_step
                ][target][item].some((row) => row.checked));
          });
        });
        return !isEnabled;
      }
      
      // Check whether All the mandatory fields for the current active sub step
      // are selected. 
      // Get current active step's active sub step fields
      const allfieldData = getListOfFields(dynamicSelector.data.element);
      const __requiredFieldList = [];
      Object.keys(activePackages).forEach((key) => {
        // Check whether current field is required based on condtion - State
        if(activePackages[key] && activePackages[key]["states"] && _.size(activePackages[key]["states"]) > 0 && activePackages[key]["states"]["required"]){
          // Require field based on condition
          const allStates = getStateOfElement(activePackages[key], allfieldData);
          if(allStates.required){
            __requiredFieldList.push(key);
          }
        }
        else{
          // Check field is required by default
          const allStatesForElement = getElementsStates(activePackages[key]);
          if (
            allStatesForElement.visibleValue !== "none" &&
            activePackages[key].required
          ) {
            __requiredFieldList.push(key);
          }
        }        
      });
      const activeStep = configstepperSelector.data.active_step;

      const __emptyFields = [];
      __requiredFieldList.forEach((rfk) => {
        if (
            dynamicSelector.data.element[activeStep] &&
            dynamicSelector.data.element[activeStep][rfk]
        ) {
          if((dynamicSelector.data.element[activeStep][rfk]).hasOwnProperty('value')) {
            if((dynamicSelector.data.element[activeStep][rfk]["value"] === "" || 
              dynamicSelector.data.element[activeStep][rfk]["value"] === null)) {
                __emptyFields.push(rfk);
            } else if(activePackages[rfk] && activePackages[rfk]['subtype'] === 'toggle' && dynamicSelector.data.element[activeStep][rfk]["value"] === 0) {
              __emptyFields.push(rfk);
            }
          } else if((dynamicSelector.data.element[activeStep][rfk]).length === 0) {
            __emptyFields.push(rfk);
          } 
        }
        else{
          __emptyFields.push(rfk);
        }
      });
      
      let isDisabled = __emptyFields.length > 0;
      
      // Handle connectivity > radio_solution_connectivity && radio_solution_type == "Radio Blueprint"
      if(!isDisabled && 
        activeStep === "connectivity" &&
        configstepperSelector.data.active_substep === 'radio_solution_connectivity' &&
        (dynamicSelector.data.element[activeStep].radio_solution_type && 
          dynamicSelector.data.element[activeStep].radio_solution_type.key === "Radio Blueprint")
        ){
          // Check whether count_of_rrh_types is set and not 0
          const count_of_rrh_types = dynamicSelector.data.element[activeStep]?.count_of_rrh_types;
          if(typeof count_of_rrh_types !== 'object'){
            isDisabled = true;
          }
          else{
            // isDisabled = (dynamicSelector.data.element[activeStep].count_of_rrh_types.key && dynamicSelector.data.element[activeStep].count_of_rrh_types.key === 0) ? true : isDisabled;
            isDisabled = ('key' in dynamicSelector.data.element[activeStep].count_of_rrh_types && dynamicSelector.data.element[activeStep].count_of_rrh_types.key === 0) ? true : isDisabled;
          }
          // Check whether configuration completed or not
          if(!isDisabled && dynamicSelector.data.element[activeStep].count_of_rrh_types.key > 0){
            isDisabled = true;
            if(dynamicSelector.data.modal[activeStep] && dynamicSelector.data.modal[activeStep].isRrhTypesDisabled && dynamicSelector.data.modal[activeStep].isRrhTypesDisabled === true){
              isDisabled = false;
            }
          }
        }

      else if(!isDisabled && 
        activeStep === "connectivity" &&
        configstepperSelector.data.active_substep === 'radio_solution_connectivity' &&
        (dynamicSelector.data.element[activeStep].radio_solution_type && 
          dynamicSelector.data.element[activeStep].radio_solution_type.key === "Radio Excel Upload")
        ){
          isDisabled = true;
          // In case radio_solution_type === "Radio Excel Upload"
          // Check radioExcelJson is set and not empty
          if(themeSelector.data.button.isToggle[`${stateMachineSelector.PTDAC.activeTab}_undo`] || (
            dynamicSelector.data.modal[activeStep] && 
            dynamicSelector.data.modal[activeStep][configstepperSelector.data.active_substep] && 
            dynamicSelector.data.modal[activeStep][configstepperSelector.data.active_substep]["radioExcelJson"] &&
            dynamicSelector.data.modal[activeStep][configstepperSelector.data.active_substep]["radioExcelJson"].length > 0)){
            isDisabled = false;
          }
          
        }
      return isDisabled
    }
    else {
      return addCart;
    }
  };

  /** getSelectedApplicationCount */
  const getSelectedApplicationCount = (formData) => {
    const applicationFieldDetails = formData ?? {};
    const selectedApplicationFields = [];
    Object.keys(applicationFieldDetails).forEach((key) => {
      if (
        applicationFieldDetails[key].type !== "hidden" &&
        applicationFieldDetails[key].subtype === "toggle"
      ) {
        // check dynamic.data.element.application has selection for applicationFieldDetails[key].id
        if (
          dynamicSelector.data.element[configstepperSelector.data.active_step] && dynamicSelector.data.element[configstepperSelector.data.active_step][
          applicationFieldDetails[key].id
          ] &&
          dynamicSelector.data.element[configstepperSelector.data.active_step][
            applicationFieldDetails[key].id
          ].key
        ) {
          selectedApplicationFields.push(applicationFieldDetails[key].id);
        }
      }
    });
    // set the selected application count to dynamic element
    const count_apps = selectedApplicationFields.length;
    return count_apps;
  };

  /** getAddAccessoriesButtonStatus */
  const getAddAccessoriesButtonStatus = () => {
    let isDisabled = true;
    // Common condition for devices and connectivity
    if (
      (_.includes(["NDAC","MPW"],solutionCategory)) &&
      (configstepperSelector.data.active_step === "devices" ||
        configstepperSelector.data.active_step === "connectivity") && solutionType !== 'Standalone'
    ) {
      // Check Connectivity,Bands and Duplex Mode are selected
      // And Search button has clicked

      isDisabled = !(
        dynamicSelector.data.setAccessoriesButton === false &&
        getState("searchButtonStatus") === false
      );
      // Check atleast one item has selected from the add to catalog popup and added in BoQ
      let catalogItems = {};
      if (
        dynamicSelector.data.element &&
        dynamicSelector.data.element[configstepperSelector.data.active_step]
      ) {
        if (
          configstepperSelector.data.active_step === "devices" &&
          dynamicSelector.data.element[configstepperSelector.data.active_step][
          "ndac_accessories"
          ]
        ) {
          catalogItems =
            dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ]["ndac_accessories"];
        }
        if (
          configstepperSelector.data.active_step === "connectivity" &&
          dynamicSelector.data.element[configstepperSelector.data.active_step][
          "catalog_items"
          ]
        ) {
          catalogItems =
            dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ]["catalog_items"];
        }
      }

      const BoQ =
        dynamicSelector.data.boq && dynamicSelector.data.boq.length > 0
          ? dynamicSelector.data.boq
          : [];
      const productIds = BoQ.map((row) => row.product_id);
      const catalogKeys = Object.keys(catalogItems);
      let isItemAdded = false;
      catalogKeys.forEach((key) => {
        isItemAdded =
          isItemAdded ||
          catalogItems[key].some((item) =>
            productIds.includes(item.product_code)
          );
      });
      isDisabled = (isDisabled) || isItemAdded
    }
    return isDisabled;
  }

  /** render action button */
  const renderActionButton = () => {
    return (
      <ActionButtons
        addAccessoriesButtonStatus={getAddAccessoriesButtonStatus()}
        prodCatalogApiLoad={getState("prodCatalogApiLoad")}
        // getDataProductCataLog={getState('getDataProductCataLog')}
        addCartButtonStatus={getAddCartButtonStatus()}
        activePackages={activePackages}
        selectors={selectors}
        setApplicationCount={setApplicationCount}
        allRefs={allRefs}
      />
    );
  };

  if (configstepperSelector.data.active_step === "review") {
    return <ReviewIndex />;
  }

  if (getState("isLoading")) {
    return <p>Loading...</p>;
  }

  if (solutionCategory === "MPW" && configstepperSelector.data.solution_selected === 'mpw_reference_solution') {
    return <MPW renderActionButton={renderActionButton} allRefs={allRefs} selectors={selectors} api_data_call={api_data_call}/>;
  }

  if(solutionCategory === "NDAC" && 
    configstepperSelector.data.active_step === "connectivity" && 
    solutionType !== 'Standalone'){
      return (
      <>
        {openProductDialogModal(api_data_call)}
        <NDACPlanner renderDynamicContent={renderDynamicContent} activePackages={activePackages} renderActionButton={renderActionButton} />
      </>
    );
  }
  
 
  return (
    <>
      <PageInlineFeedback />
      {/** ProductCatalogDialog*/}
      {openProductDialogModal(api_data_call)}
      {/** renderDynamicContent */}
      {renderDynamicContent(activePackages)}
      {/** renderDynamicContent */}
      {/** Action button row */}
      {renderActionButton()}
      {/** Action button row */}
     
    </>
  );
};


export default memo(Options);
