import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'Store/mainStore';
import { Bar } from "@nivo/bar";
import { HorizontalDivider, Skeleton } from '@nokia-csf-uxr/ccfk';
import { TextInputLabelContent } from '@nokia-csf-uxr/ccfk/TextInput';

const MXIEServerConsumptionChart = () => {
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  return (
    <div
        id="mxie-server-consumption-chart"
        className="mb"
        // hidden={!consumptionGraph}
      >
        <TextInputLabelContent>MXIE Server Consumption</TextInputLabelContent>

        {dynamicSelector.data.element[configstepperSelector.data.active_step] &&
        dynamicSelector.data.element[configstepperSelector.data.active_step][
          "mxie_server_consumption"
        ] &&
        dynamicSelector.data.element[configstepperSelector.data.active_step][
          "mxie_server_consumption"
        ].length !== 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <HorizontalDivider
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Bar
                maxValue={100}
                padding={0.2}
                margin={{
                  top: 40,
                  right: 50,
                  bottom: 40,
                  left: 50,
                }}
                innerPadding={0}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Capacity (%)",
                  legendPosition: "middle",
                  legendOffset: 30,
                }}
                indexBy={"label"}
                groupMode="stacked"
                labelTextColor={"white"}
                height={300}
                width={400}
                colors={["#005AFF"]}
                tooltip={(data) => {
                  return (
                    <div
                      style={{
                        background: "white",
                        padding: "10px 15px",
                        border: `1px solid ${data.color}`,
                        borderRadius: "5px",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            display: "inline-block",
                            width: "100px",
                          }}
                        >
                          Consumption
                        </span>
                        : {data.data.consumption}
                      </div>
                      <div>
                        <span
                          style={{
                            display: "inline-block",
                            width: "100px",
                          }}
                        >
                          Capacity
                        </span>
                        : {data.data.capacity}
                      </div>
                    </div>
                  );
                }}
                data={
                  configstepperSelector.data.active_step === "mxie" &&
                  dynamicSelector.data.element[
                    configstepperSelector.data.active_step
                  ] &&
                  dynamicSelector.data.element[
                    configstepperSelector.data.active_step
                  ]["mxie_server_consumption"].length > 0
                    ? dynamicSelector.data.element[
                        configstepperSelector.data.active_step
                      ]["mxie_server_consumption"]
                    : []
                }
                layout="horizontal"
              />
            </div>
          </div>
        ) : (
          <Skeleton style={{ width: "250px" }} />
        )}
      </div>
  )
}

export default MXIEServerConsumptionChart