import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import {
  getAdvancedThemeContext,
  getComponentAdvancedTheme,
  getValueFromVar,
} from "@nokia-csf-uxr/ccfk/AdvancedTheme";
import TOKENS from "@nokia-csf-uxr/nokia-design-system-tokens/js/tokens.es6";

import {
  getEasing,
  getDuration,
} from "@nokia-csf-uxr/ccfk/common/parse-tokens";

import Hyperlink from "@nokia-csf-uxr/ccfk/Hyperlink";
import Typography from "@nokia-csf-uxr/ccfk/Typography";
import InlineFeedbackMessage from "@nokia-csf-uxr/ccfk/InlineFeedbackMessage";
import HorizontalDivider from "@nokia-csf-uxr/ccfk/HorizontalDivider";

import { Button, FileUpload } from "@nokia-csf-uxr/ccfk";
import {
  FileUploadSection,
  FileUploadIcon,
  FileUploadList,
} from "@nokia-csf-uxr/ccfk/FileUpload";

import FileUploadListItem from "./FileUploadListItem";
import { ButtonLoadingIndicator, ButtonText } from "@nokia-csf-uxr/ccfk/Button";

const INLINEFEEDBACK_STYLE = {
  border: "none",
  margin: TOKENS.SPACING_XX_SMALL,
  position: "absolute",
  top: 0,
  width: "calc(100% - 0.5rem)",
  zIndex: 3,
};
const INLINEFEEDBACK_ANIMATION = {
  from: { opacity: 0, ...INLINEFEEDBACK_STYLE },
  enter: { opacity: 1, ...INLINEFEEDBACK_STYLE },
  leave: { opacity: 0, ...INLINEFEEDBACK_STYLE },
  config: {
    duration: getDuration(TOKENS.DURATION_MODERATE),
    easing: getEasing(TOKENS.TRANSITION_ACCELERATE),
  },
};

const sizeFormatter = (size) => `${(size / 1000).toFixed(1)} KB`;

const uploadTimeComparator = (a, b) => {
  if (a.lastModified < b.lastModified) {
    return -1;
  }
  if (a.lastModified > b.lastModified) {
    return 1;
  }
  return 0;
};

interface IFileUploader {
  accept: string[];
  minSize?: number;
  maxSize?: number;
  maxFiles?: number;
  multiple?: boolean;
  fileRequirementTitle?: string;
  setUploadedFiles?: Function;
  isReset?:boolean;
  customMessage?: string;
  isLoading?: boolean;
  loadingMessage?: string;
  isFileListDisabled?:boolean
}

const FileUploader = ({
  accept = [],
  // Minimum file size (in bytes)
  minSize = undefined,
  // Maximum file size (in bytes)
  maxSize = undefined,
  maxFiles = 0,
  multiple = false,
  fileRequirementTitle = "Secondary text line outlining file requirements",
  setUploadedFiles,
  isReset=false,
  customMessage = "",
  isLoading = false,
  loadingMessage = "",
  isFileListDisabled = false,
}: IFileUploader) => {
  //const FileUploader = (props) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [showGeneralErrorMessage, setShowGeneralErrorMessage] = useState(false);

  const generalErrorMessage = useRef(null);
  const acceptedFilesRef = useRef(acceptedFiles);
  const rejectedFilesRef = useRef([]);
  const openDialog = useRef();

  const allFiles = acceptedFiles
    .concat(rejectedFiles)
    .sort(uploadTimeComparator);

  const $AT = getAdvancedThemeContext(({ advancedTheme }) => advancedTheme);
  const resolvedFileUploadAT = getComponentAdvancedTheme(
    $AT,
    "FileUpload",
    "default"
  );
  const PRIMARYTEXT_COLOR = getValueFromVar(
    { ...$AT["GLOBAL-VARIABLES"], ...TOKENS },
    resolvedFileUploadAT.text.primaryContent
  );
  const SUBTEXT_COLOR = getValueFromVar(
    { ...$AT["GLOBAL-VARIABLES"], ...TOKENS },
    resolvedFileUploadAT.text.secondaryContent
  );
  const DRAG_AND_DROP_TEXT_COLOR = getValueFromVar(
    { ...$AT["GLOBAL-VARIABLES"], ...TOKENS },
    resolvedFileUploadAT.text.dragAndDropContent
  );

  // Component effects
  useEffect(() => {
    if (customMessage && customMessage.length > 0) {
      setShowGeneralErrorMessage(true);
      generalErrorMessage.current = customMessage.toString();
    }
  }, [customMessage]);

  // Reset if something went wrong
  useEffect(() => {
    if(isReset === true){
      setShowGeneralErrorMessage(true);
      if (customMessage && customMessage.length > 0) {
        generalErrorMessage.current = customMessage.toString();
      }
      handleDelete(acceptedFiles)
    }
  },[isReset]);

  const updateProgress = (file) => {
    let percentage = 0;
    let delay = 0;
    for (
      percentage = 0, delay = 0;
      percentage <= 100;
      percentage += 2, delay += 50
    ) {
      const p = percentage;
      setTimeout(() => {
        if (file != null && file.status === "uploading") {
          file.progress = p;
          if (p === 100) {
            file.status = "complete";
            updateUploadedFiles(acceptedFilesRef.current);
          }
          setAcceptedFiles([...acceptedFilesRef.current]);
        }
      }, delay);
    }
  };

  /** simulate the upload processing */
  const simulateUploading = () => {
    acceptedFilesRef.current.forEach(({ file }) => {
      if (file.status === "pending") {
        file.status = "uploading";
        file.progress = 0;
        setAcceptedFiles([...acceptedFilesRef.current]);
        setTimeout(() => {
          updateProgress(file);
        }, Math.floor(Math.random() * 2000));
      }
    });
  };

  const acceptedFilesCallback = (data) => {
    setShowGeneralErrorMessage(false);
    const newData = data
      .map((file) => {
        // if uploaded file name exists, set error message to inline notification.
        if (
          acceptedFilesRef.current.findIndex(
            ({ file: acceptedFile }) => acceptedFile.name === file.name
          ) !== -1
        ) {
          //@ts-ignore
          generalErrorMessage.current = "Same file has been already uploaded.";
          setShowGeneralErrorMessage(true);
        } else {
          file.status = "pending";
          return { file };
        }
      })
      .filter((element) => element != null);

    if (newData.length > 0) {
      acceptedFilesRef.current = newData.concat(acceptedFilesRef.current);
      setAcceptedFiles(acceptedFilesRef.current);
      simulateUploading();
    }
  };

  const fileRejections = (data) => {
    // console.log("fileRejections: ", data);
    if (data[0] && data[0].errors) {
      generalErrorMessage.current = data[0].errors[0].message;
      setShowGeneralErrorMessage(true);
    }
  };

  const handleFeedbackClose = () => {
    setShowGeneralErrorMessage(false);
  };

  const handleDelete = (deleteFile) => {
    if (deleteFile.error) {
      const files = [...rejectedFiles];
      const indexToDelete = files.findIndex(
        ({ file }) => file.name === deleteFile.name
      );
      files.splice(indexToDelete, 1);
      rejectedFilesRef.current = files;
      setRejectedFiles(files);
    } else {
      const files = [...acceptedFiles];
      const indexToDelete = files.findIndex(
        ({ file }) => file.name === deleteFile.name
      );
      files.splice(indexToDelete, 1);
      acceptedFilesRef.current = files;
      setAcceptedFiles(acceptedFilesRef.current);
      updateUploadedFiles(acceptedFilesRef.current);
    }
  };

  const updateUploadedFiles = (files) => {
    if (
      setUploadedFiles &&
      typeof setUploadedFiles === "function" &&
      !files.some((row) => row.file.status !== "complete")
    ) {
      setUploadedFiles(files);
    }
  };

  const handleRetry = (retryFile) => {
    if (retryFile.errorMessage) {
      acceptedFilesRef.current.forEach(({ file }) => {
        if (file.name === retryFile.name) {
          file.status = "pending";
          file.errorMessage = undefined;
          file.progress = 0;
        }
      });
      setAcceptedFiles([...acceptedFilesRef.current]);
      simulateUploading();
    }
  };

  const isFileUploadDisabled = () => {
    return (maxFiles && acceptedFiles.length >= maxFiles) || isLoading;
  }

  return (
    <>
      <div></div>
      <FileUpload
        dragStatus={(status) => {
          setIsDragActive(status.isDragActive);
        }}
        acceptedFiles={acceptedFilesCallback}
        fileRejections={fileRejections}
        open={(open) => {
          //@ts-ignore
          openDialog.current = open;
        }}
        accept={accept}
        minSize={minSize}
        maxSize={maxSize}
        multiple={multiple}
        disabled={isFileUploadDisabled() || isFileListDisabled}
      >
        <InlineFeedbackMessage
          variant="error"
          show={showGeneralErrorMessage}
          onClose={handleFeedbackClose}
          animation={INLINEFEEDBACK_ANIMATION}
          closeButton
        >
          {generalErrorMessage.current}
        </InlineFeedbackMessage>
        {isLoading && (
          <div style={{ padding: TOKENS.SPACING_MEDIUM }}>
            <Button
              data-ff-state="loading"
              variant="secondary-small"
              onClick={(event) => {
                event.persist();
                // console.log("CLICKED", { event });
              }}
            >
              <ButtonText>{loadingMessage}</ButtonText>
              <ButtonLoadingIndicator variant="secondary-small" />
            </Button>
          </div>
        )}
        
        {allFiles.length > 0 && !isDragActive && (
          <FileUploadList>
            {allFiles.map(({ file }, index) => (
              <React.Fragment key={`${file.name}-${index}`}>
                <FileUploadListItem
                  id={`${file.name}-${index}`}
                  key={`${file.name}-${index}`}
                  index={index}
                  fileName={file.name}
                  errorMessage={file.errorMessage}
                  secondaryContent={sizeFormatter(file.size)}
                  progress={file.progress}
                  status={file.status}
                  onDelete={() => handleDelete(file)}
                  onDownload={() => console.log(`Download file: ${file.name}.`)}
                  onRetry={() => file.errorMessage && handleRetry(file)}
                  disabled = {isLoading || isFileListDisabled}
                />
                {allFiles.length > 1 && index !== allFiles.length - 1 && (
                  <HorizontalDivider />
                )}
              </React.Fragment>
            ))}
          </FileUploadList>
        )}
        <FileUploadSection>
          {(acceptedFiles.length === 0 || isDragActive) && <FileUploadIcon />}
          {!isDragActive && !isFileUploadDisabled() && (
            <>
              <Typography
                typography="BODY"
                style={{ color: PRIMARYTEXT_COLOR }}
              >
                Drag and drop files here, or{" "}
                <Hyperlink
                  aria-label="Browse"
                  href=""
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    //@ts-ignore
                    openDialog.current && openDialog.current();
                  }}
                >
                  browse
                </Hyperlink>{" "}
              </Typography>
              <Typography typography="CAPTION" style={{ color: SUBTEXT_COLOR }}>
                {fileRequirementTitle}
              </Typography>
            </>
          )}
          {isDragActive && (
            <Typography
              typography="TITLE_16"
              style={{ color: DRAG_AND_DROP_TEXT_COLOR, fontSize: "0.875rem" }}
            >
              Drop to add files(s)
            </Typography>
          )}
        </FileUploadSection>
      </FileUpload>
    </>
  );
};

// export default React.memo(FileUploader);
export default FileUploader;
