
import { Skeleton, Spinner } from '@nokia-csf-uxr/ccfk';
// import { Button, Skeleton, Spinner, TextInput } from '@nokia-csf-uxr/ccfk';
// import { ButtonText } from '@nokia-csf-uxr/ccfk/Button';
// import _ from 'lodash';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { roundCount } from 'Utils';
interface IQuantityModifiersProps {
  value: number,
  onIncQty?: Function,
  onDecQty?: Function,
  onUpdateQty?: Function,
  isDisabled?: boolean,
  product: any,
  isEdit?: Function,  // Defines weather the qty is in buffer/updating stage, if true -> disable ATC, since update qty is not sent to Middleware.
}
const isException = (val) => ((Number.isInteger(val) == false) || isNaN(val) || (val == "0"))
const QuantityModifier = (props: IQuantityModifiersProps) => {
  // const onDecQty = _.debounce(() => props.onDecQty(), 0);
  // const onIncQty = _.debounce(() => props.onIncQty(), 0);
  const onDecQty = props.onDecQty;
  const onIncQty = props.onIncQty;
  const onUpdateQty = props.onUpdateQty;
  const minValue = props.product.hasOwnProperty('cui_min') ? roundCount(props.product.cui_min) : 1;
  const isDisabled = props.product.cui_type === "extension" || props.isDisabled
  const isEdit = props.isEdit;
  const [qtyInputVal, setQtyInputVal] = useState(props.product.quantity);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (props.hasOwnProperty('isEdit')) {
      isEdit(isEditMode)
    }
  }, [isEditMode])

  useEffect(() => {
    setQtyInputVal(props.product.quantity)
  }, [props.product.quantity]);

  const onQtyChange = (qty) => {
    if ((minValue === props.product.quantity) && (minValue > 1)) {
      setIsEditMode(false)
      return;
    }
    let currentValue = props.value;
    if ((isException(qty)) || qty === currentValue) {
      setIsEditMode(false)
      return;
    }
    if (isEditMode) {
      setQtyInputVal(qty)
      onUpdateQty(roundCount(qty));
      setIsEditMode(false)
    }
    else {
      if (qty >= currentValue) {
        onIncQty();
      } else {
        onDecQty();
      }
    }
  }

  if (!props.value) {
    return (<Skeleton style={{ width: '250px' }} />)
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} key={props.value}>
      {/* <div ><Button disabled={(props.product && props.product.quantity <= 1)} onClick={() =>onDecQty() }><ButtonText>-</ButtonText></Button></div>
      <div><TextInput style={{ width: '40px' }} value={(props.value).toString()} readOnly={true} /></div>
      <div> <Button onClick={() => onIncQty()}><ButtonText>+</ButtonText></Button> </div> */}
      <Spinner
        variant='outlined'
        value={qtyInputVal}
        // max={props.product.quantity}
        disabled={isDisabled}
        min={minValue}
        inputMode='numeric'

        inputProps={{
          onFocus: (e) => {
            setIsEditMode(true)

          },
        }}
        decrementButtonProps={{ disabled: props.product.cui_type === "extension" || props?.product?.quantity == 1 || props.isDisabled }}
        onChange={(e) => onQtyChange(e.value)} />
    </div>
  )
}

export default React.memo(QuantityModifier);

QuantityModifier.propTypes = {
  value: PropTypes.number,
  onIncQty: PropTypes.func,
  onDecQty: PropTypes.func,
  onUpdateQty: PropTypes.func,
  isDisabled: PropTypes.bool,
  product: PropTypes.object,
  isEdit: PropTypes.func,
}