// @ts-nocheck
import { Button, Label, List, RadioButton, Skeleton, Typography } from '@nokia-csf-uxr/ccfk';
import PhonesIcon from '@nokia-csf-uxr/ccfk-assets/latest/PhonesIcon';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import { ListItemBasic, ListItemHintText } from '@nokia-csf-uxr/ccfk/List';
import { MultiSelectSmartCheckboxList, MultiSelectSmartListCheckbox } from '@nokia-csf-uxr/ccfk/MultiSelectSmartList';
import { RadioButtonLabelContent } from '@nokia-csf-uxr/ccfk/RadioButton';
import { TextInputLabelContent } from '@nokia-csf-uxr/ccfk/TextInput';
import { useQuery } from '@tanstack/react-query';
import Plans from 'Components/Content/modelArea/Plans';
import AddCartBtn from 'Components/Elements/AddCartBtn';
import DialogModal from 'Components/Elements/DialogModal';
import Loader from 'Components/Elements/Loader';
import SelectBar from 'Components/Elements/SelectBar';
import { systemStyle } from 'Constants/StyleLayer';
import OOT from 'Constants/services/oot';
import { setProp } from 'Store/Actions';
import { showPopup } from 'Store/auth';
import { addCartItem } from 'Store/contentState/cartBoqSlice';
import { setWarranty } from 'Store/contentState/configurationArea/warrantySlice';
import { setWarrantyTabRules } from 'Store/contentState/stateMachine';
import { RootState } from 'Store/mainStore';
import { setTheme } from 'Store/theme';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionDurationOptions } from './system';
const Options = () => {
  const warrantySelector = useSelector((state: RootState) => state.warranty);
  const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
  const themeSelector = useSelector((state: RootState) => state.theme);
  const stateMachineSelector = useSelector((state: RootState) => state.stateMachine);
  const dispatch = useDispatch();
  const slice = "warranty";
  const isPlansState = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.plansWarrantyTab;
  const isextendDurationState = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.extendDurationWarrantyTab
  const [exchangeData, setExchangeData] = useState(null);

  const warrantyTab = useQuery({

    queryKey: ['warrantyTab', { account: caseDetailsSelector.data.account_id }],
    queryFn: async ({ queryKey }) => {
      //@ts-ignore
      const [_key, { account }] = queryKey;
      const response = await OOT.getWarrantyTab({ account: account, selector: caseDetailsSelector });
      return response;
    },
    refetchOnMount: false,
    onSuccess(res) {
      dispatch(setWarrantyTabRules(res.data));

      dispatch(setTheme({ element: "button", comp: "addToCart", prop: "isDisable", value: true }))
    },

  })

  const optionData = [{ id: 1, value: 1 }, { id: 2, value: 2 }, { id: 3, value: 3 }]

  if (warrantyTab.isLoading) {
    return (<Loader />)
  }

  //@ts-ignore
  const warrantyPlans = stateMachineSelector.PTDAC.selected?.warranty?.plansWarrantyTab?.data?.plans?.byPlan ? Object.values(stateMachineSelector.PTDAC.selected?.warranty?.plansWarrantyTab.data.plans.byPlan) : [];

  /* 
  The above fetch makes sure the data is passed to configurator to play with and re-send correct- values based on user -selection 
  */
  return (
    <>
      {
        (isPlansState && isPlansState.response_code) ? (<DialogModal isOpen={themeSelector.data.modal.isOpen.warranty_subscription}
          type='modal'
          dialogInfo={{
            title: 'Product Catalog',
          }}
          actionBtns={[
            { label: "Cancel", onAction: () => dispatch(setProp({ slice: "theme", key: "modal.isOpen.warranty_subscription", value: false })), variant: "neutral" },
            { label: "OK", onAction: () => dispatch(setProp({ slice: "theme", key: "modal.isOpen.warranty_subscription", value: false })), variant: "call-to-action" }]} >
          <Plans
            selected={warrantySelector.data.options.PTDAC.subscription_plan}
            onSelect={(plan) => dispatch(setProp({ slice: slice, key: "options.PTDAC.subscription_plan", value: plan.id }))}
            //cards={Object.values(subscriptionPlansOptions.byPlan)}
            //@ts-ignore
            cards={warrantyPlans}
          />
        </DialogModal>) : (<Skeleton style={{ width: '250px' }} />)
      }

      <div id="subscription-duration" className='mb'>
        <TextInputLabelContent>Subscription duration</TextInputLabelContent>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            subscriptionDurationOptions.map((op, i) => {
              return (
                <div key={i}>
                  <Label>
                    <RadioButton
                      onChange={(e) => {
                        if (e.target.checked) {
                          dispatch(setProp({ slice: slice, key: "options.PTDAC.subscription_duration", value: op.id }))
                          return;
                        }
                        dispatch(setProp({ slice: slice, key: "options.PTDAC.subscription_duration", value: op.id }));
                      }}

                      checked={warrantySelector.data.options.PTDAC.subscription_duration === (op.id)}
                    />
                    <RadioButtonLabelContent>{((op.value).toString())}</RadioButtonLabelContent>
                  </Label>
                </div>
              )
            })
          }
          <div>
            <SelectBar style={{ width: '60px' }}

              key={'subscription_duration'}
              isOpen={themeSelector.data.select.isOpen.warranty_subscription_duration}
              handOnSelect={(e) => {
                dispatch(setProp({ slice: slice, key: "options.PTDAC.subscription_duration", value: e*12 }))
              }}
              options={optionData}
              renderValue={warrantySelector.data.options.PTDAC.subscription_duration ? (warrantySelector.data.options.PTDAC.subscription_duration / 12).toString() : undefined}
              optionPath='id'
              displayKey='value'
              slice={slice}
              onlyHandOnSelect={true}
              variant='outlined'
              setProperty={{
                sliceProperty: '',
                themeProperty: "warranty_subscription_duration",
              }}
            />
          </div>
        </div>
      </div>
      <div id="subscription-plans" className='mb'>
        <TextInputLabelContent>Available Subscription Plans</TextInputLabelContent>

        <div >
          {
            (isPlansState && isPlansState.response_code) ? (
              //isPlansState.data.plans.byPlan as typeof subscriptionPlansOptions.byPlan
              //Object.values(subscriptionPlansOptions.byPlan).map((plan, i) => {
              Object.values(warrantyPlans).map((plan, i) => {
                //TODO: Convert this to MULTICHECKBOX
                return (
                  <List key={plan.id} hoverEffect={false} elevationProps={{ elevationIndex: 0 }}>
                    <ListItemBasic>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
                        <RadioButton
                          onChange={(e) => {

                            if (e.target.checked) {
                              dispatch(setProp({ slice: slice, key: "options.PTDAC.subscription_plan", value: plan.id }))
                              return;
                            }
                            dispatch(setProp({ slice: slice, key: "options.PTDAC.subscription_plan", value: plan.id }));
                          }}

                          checked={warrantySelector.data.options.PTDAC.subscription_plan === plan.id}
                        />  <RadioButtonLabelContent>{plan.plan}</RadioButtonLabelContent>
                        <ListItemHintText>{plan.duration.hours}h /{plan.duration.days} </ListItemHintText>
                      </div>

                    </ListItemBasic>
                  </List>
                )
              })) : (<Skeleton style={{ width: '250px' }} />)
          }

        </div>
      </div>

      <div id="warranty" className='mb'>
        <TextInputLabelContent>Warranty</TextInputLabelContent>
        <MultiSelectSmartCheckboxList aria-multiselectable={true}>
          <MultiSelectSmartListCheckbox
            aria-multiselectable={true}
            selected={warrantySelector.data.options.PTDAC.warranty.some((period) => period === 12)}
            onChange={(e) => {
              if (e.target.checked && e.type === "change") {
                dispatch(setWarranty({ method: "add", period: 12 }));
                return;
              } else {
                dispatch(setWarranty({ method: "remove", period: 12 }));
              }
            }}
            label="Include 12 months standard warranty"
            disabled={true}
          />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <MultiSelectSmartListCheckbox
              aria-multiselectable={true}
              // disabled={!(isextendDurationState && isextendDurationState.response_code)}
              disabled={!exchangeData}
              label="Extend the warranty"
              selected={warrantySelector.data.options.PTDAC.warranty.some((period) => period === exchangeData)}
              onChange={(e) => {
                if (e.target.checked && e.type === "change") {
                  dispatch(setProp({ slice: slice, key: "options.PTDAC.warranty", value: [warrantySelector.data.options.PTDAC.warranty[0],exchangeData] }))
                  //dispatch(setWarranty({ method: "add", period: exchangeData }));
                  return;
                } else {
                  // dispatch(setWarranty({ method: "remove", period: exchangeData }));.
                  dispatch(setProp({ slice: slice, key: "options.PTDAC.warranty", value: [warrantySelector.data.options.PTDAC.warranty[0]] }))
                  if (warrantySelector.data.options.PTDAC.warranty.length == 0) {
                    dispatch(showPopup({ msg: 'Please select duration first', show: true }))
                  }

                }
              }}
            />
            <SelectBar
              isOpen={themeSelector.data.select.isOpen.warranty_extend_warranty}
              handOnSelect={(e) => {
                //dispatch(setWarranty({ method: "add", product: e }));
                setExchangeData(e);

              }}
              slice={slice}
              onlyHandOnSelect={true}
              options={[{ id: 1, value: 1 }, { id: 2, value: 2 }, { id: 3, value: 3 }, { id: 4, value: 4 }]}
              renderValue={exchangeData ? (exchangeData).toString() : undefined}
              optionPath='id'
              displayKey='value'
              variant='outlined'
              setProperty={{
                sliceProperty: '',
                themeProperty: "warranty_extend_warranty",
              }}
            />
            <p> <Typography typography="BODY">Years</Typography></p>
          </div>


        </MultiSelectSmartCheckboxList>
      </div>

      <div style={systemStyle.flex({ justify: "flex-end", gap: "1rem" })}>
        <div className='mt' style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant='neutral' onClick={() => {
            dispatch(setProp({ slice: "theme", key: "modal.isOpen.warranty_subscription", value: true }));
          }}>
            <ButtonIcon>
              <PhonesIcon />
            </ButtonIcon>
            <ButtonText>
              Show detailed comparison
            </ButtonText>

          </Button>
        </div>
        <div> <AddCartBtn
          isLoading={(!(isPlansState && isPlansState.response_code))}
          onAction={() => {
            let extendedProducts = (isextendDurationState && isextendDurationState.response_code) ? isextendDurationState.data.products : [];
            let selectedPlanProducts = isPlansState.data.plans.byPlan.hasOwnProperty(warrantySelector.data.options.PTDAC.subscription_plan) && warrantySelector.data.options.PTDAC.subscription_plan !== 'basic' ? [isPlansState.data.plans.byPlan[warrantySelector.data.options.PTDAC.subscription_plan]?.products] : [];
            
            const finalData = warrantySelector.data.options.PTDAC.warranty.length === 2 ? selectedPlanProducts.concat(extendedProducts) : selectedPlanProducts;
            dispatch(addCartItem({
              products: finalData,
              history: true
            }))
          }} /></div>
      </div>
    </>
  )
}

export default Options
