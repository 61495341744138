import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from "lodash";
import { RootState } from 'Store/mainStore';
import DialogModal from 'Components/Elements/DialogModal';
import DeviceAccessories from '../DynamicField/deviceaccessories';
import { useLocalStateManagement } from '../hooks/useLocalStateManagement';

interface IProductCatalogDialog {
  onProductCatalogDialogCancel: () => void,
  onProductCatalogDialogAdd: () => void,
  apitocall: string,
  productDataList: {}
}


const ProductCatalogDialog = ({ onProductCatalogDialogCancel, onProductCatalogDialogAdd, apitocall, productDataList }: IProductCatalogDialog) => {
  const themeSelector = useSelector((state: RootState) => state.theme);
  const { setState } = useLocalStateManagement();
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);

  /** On component load */
  useEffect(()=>{
    // Disable the loader indicator on popup load
    setState('setProdCatalogApiLoad', false);
  },[])
  
  const isCatalogItemsAdded =() => {
    const activestep = dynamicSelector.data.element[configstepperSelector.data.active_step];
    let dialogname = 'ndac_accessories'
    if(configstepperSelector.data.active_step === 'connectivity') {
      dialogname =  'catalog_items';
    } else if(configstepperSelector.data.active_step === 'review') {
      dialogname = 'customise_offer'
    }    
    let newSelected = true; // Initialize with disable
    if (activestep && activestep[dialogname]) {
      const allDialogItems = _.flatten(_.values(activestep[dialogname]));
      if (allDialogItems.some(item => item['checked'])) {
        newSelected = false;
      }
    }
    return newSelected;
  }

  return (
    <DialogModal
      isOpen={themeSelector.data.modal.isOpen.connectivity_catalog}
      type="modal"
      dialogInfo={{ title: "Product Catalog" }}
      actionBtns={[
        {
          label: "Cancel",
          onAction: () => { onProductCatalogDialogCancel() },
          variant: "neutral",
        },
        {
          label: "Add",
          disabled: isCatalogItemsAdded(),
          onAction: () => { onProductCatalogDialogAdd() },
          variant: "neutral",
        },
      ]}
    >
      <DeviceAccessories
        catalog={productDataList}
        active_step={configstepperSelector.data.active_step}
        data_name={apitocall}
        connectivity="connectivity_devices"
      />
    </DialogModal>
  )
}

export default ProductCatalogDialog