import { DataGrid, Label } from "@nokia-csf-uxr/ccfk";
import _ from "lodash";
import React, { useCallback } from "react";
import PropTypes from 'prop-types';

interface DataGridList {
  boqGridList: any
}

const BoqDataGrid = (props: DataGridList) => {
  const autoSizeFitAll = (gridOptions) => {
    window['gridOptions'] = gridOptions;
    const allColumnIds = [];
    gridOptions.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridOptions.columnApi.autoSizeAllColumns(false);
  };


  return (
    <div id='cart' style={{ width: '100%', height: '100%' }}>
      {
        (props.boqGridList).map((eachSection, index) => {
          // loop each from list to show different sections of data grid
          return <div id={eachSection.id_key} className='mt mb' key={index}>
            <Label style={{ fontWeight: 'bold' }} className='mb'>{eachSection.label}</Label>
            <DataGrid
              suppressMovableColumns={true}
              isRowDrag={true}
              key={eachSection.id_key}
              wrapperProps={{
                style: {
                  width: '100%'
                }
              }}

              rowData={eachSection.rowData}
              defaultColDef={{
                editable: false,
                sortable: true,
                flex: 1,
                minWidth: 100,
                filter: true,
                resizable: true,
              }}
              overlayNoRowsTemplate={eachSection.overlayNoRowsTemplate}
              isInlineRowActions={true}
              domLayout='autoHeight'
              sideBar
              columnDefs={eachSection.columnDefs}
              onGridReady={(gridOptions) => {
                autoSizeFitAll(gridOptions)
              }}
            />
          </div>
        })
      }
    </div>
  )
}

export default React.memo(BoqDataGrid);
BoqDataGrid.propTypes = {
  boqGridList: PropTypes.array
}