import React, { useState } from "react";
import classes from "./CustomTab.module.css";

interface CustomTab {
  tabData: {
    index: number;
    label: string;
    tab: string;
    content: React.JSX.Element;
  }[];
}

const CustomTab = ({ tabData }: CustomTab) => {
  const [selected, setSelected] = useState(0);
  const clickHandler = (selected) => () => {
    setSelected(selected);
  };

  return (
    <>
      <div className={classes.tabs}>
        {tabData.map((tab, i) => {
          return (
            <div
              className={`${classes.tab} ${selected === i ? classes.active : ""}`}
              id={`tab-${i}`}
              aria-controls={`tab-content-${i}`}
              aria-posinset={tab && tab.label ? i + 1 : undefined}
              aria-setsize={tab && tab.label ? tabData.length : undefined}
              role="option"
              key={`tab-${i}`}
              onClick={clickHandler(i)}
            >
              {tab.label}
            </div>
          );
        })}
      </div>
      <div>
        {tabData.map((tab, i) => (
          <div hidden={i !== selected} key={i} className={`${classes["tab-conten"]}`}>
            {tab.content}
          </div>
        ))}
      </div>
    </>
  );
};

export default CustomTab;
