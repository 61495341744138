import {
  Button,
  ButtonsRow,
  HorizontalDivider,
  Skeleton
} from "@nokia-csf-uxr/ccfk";
import ChevronLeftIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ChevronLeftIcon';
import ChevronRightIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ChevronRightIcon';
import { ButtonIcon, ButtonText } from "@nokia-csf-uxr/ccfk/Button";
// import { DialogFooter } from "@nokia-csf-uxr/ccfk/Dialog";
import StepForm, {
  Step,
  StepConfigurationArea,
  StepFormArea,
  SubStep
} from "@nokia-csf-uxr/ccfk/StepForm";
import BusinessIndex from "Components/Content/configurationArea/Business";
import ConfiguratorIndex from "Components/Content/configurationArea/Configurator";
import ReviewIndex from "Components/Content/configurationArea/Review";
import { showModal } from "Store/auth";
import { setDataConfig } from "Store/contentState/configuration_stepperSlice";
import { RootState } from "Store/mainStore";
import { serializeFunction } from "Utils";
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { identifyMPWRedirect, identifySolutionRedirect } from "Components/Logics/systemLogics";
import { setProp } from "Store/Actions";
import { setTheme } from "Store/theme";
import _ from 'lodash';
import { setPTDACTab } from "Store/contentState/stateMachine";
import { useConfigurator } from "Components/Content/configurationArea/Configurator/hooks/useConfigurator";
import { getStateOfElement } from "Components/Logics/stateMachine/modules/CONFIG/configurator_statelogics";
import useDatamart from "Components/Content/configurationArea/Configurator/hooks/useDatamart";

enum StepStatus { current = 'current', future = 'future', error = 'error', warning = 'warning', completed = 'completed' };
interface IStepperProps {
  onSubmit: Function,
  onNext: Function,
  onBack: Function,
  isDisable: boolean,
  getStep?: Function,
}
const Stepper = (props: IStepperProps) => {
  const dispatch = useDispatch();
  const businessSelector = useSelector((state: RootState) => state.business);
  const themeSelector = useSelector((state: RootState) => state.theme);
  const configStepperSelector = useSelector((state: RootState) => state.config_stepper);
  const dynamicSelector = useSelector((state: RootState) => state.dynamic)
  const actionNext = useSelector((state: RootState) => state.dynamic.data.actionNext);
  const steps = configStepperSelector.data.stepper_data;
  const stateMachineSelector = useSelector((state: RootState) => state.stateMachine);
  const getStepIndex = useCallback((stepName) => steps.findIndex(item => (item.name === stepName)), [steps]);
  const getStep = useCallback((stepName) => steps.find(item => (item.name === stepName)), [steps]);
  const [makeDisable, setDisable] = useState(false);
  const [stepperState, setStepperState] = useState("");
  const [subStepState, setSubStepState] = useState({});

  let skipSteps = ['devices','applications']; //TODO: this has to come dynamically from the api response
  if(configStepperSelector.data.solution_selected === 'mpw_reference_solution') {
    skipSteps = ['devices','applications','connectivity','services_mpw']; //TODO: this has to come dynamically from the api response
  }
  // Hooks
  const { getListOfFields } = useConfigurator();
  const { saveBoQInDatamart } = useDatamart();

  const getConfigurationArea = useCallback((step) => {
    const currentStep = getStep(getCurrentStepName())
    props.getStep({ index: getStepIndex(getCurrentStepName()), step: currentStep });
    let configurationArea = null;
    switch (step) {
      case 'Business':
        configurationArea = <BusinessIndex />;
        setDisable(true)
        break;
      default:
        configurationArea = <ConfiguratorIndex />;
        const currentStepIndex = getStepIndex(getCurrentStepName());
        if (currentStepIndex > 1) {
          setDisable(false)
        } else {
          setDisable(true)
        }
    }

    return configurationArea;
  }, [steps, getStepIndex]);
  const NEXT_STEP = 'next'; const PREVIOUS_STEP = 'previous'; const CURRENT_STEP = 'current';
  // return the current step name
  const getCurrentStepName = useCallback(() => {
    const currentStepIndex = steps.findIndex(item => (item.status === 'current'));
    if (currentStepIndex < 0) return steps[steps.length - 1].name;
    return steps[currentStepIndex].name;
  }, [steps]);

  const getIndicatorVariant = (stepName: string): StepStatus => {
    const step = steps[getStepIndex(stepName)];
    if (!step.isValid) return 'error' as StepStatus.error;
    if (step.status === 'current') return 'current' as StepStatus.current;
    if (step.isComplete) return 'completed' as StepStatus.completed;

    return step.status as StepStatus;
  };

  /** getSubSteps */
  const getSubSteps = (currentStep) => {
    const childSteps = _.filter(steps,(obj) => obj.name === currentStep && obj['subSteps'] && (obj['subSteps']).length > 0);
    if(childSteps && childSteps.length > 0) {
      return childSteps[0]['subSteps'];
    }
    return undefined;
  }

  const currentStepArea = useMemo(() => getConfigurationArea(getCurrentStepName()), [getConfigurationArea, getCurrentStepName]);

  // validate current state and move to next step if validation succeeds
  const handleContinue = async () => {
    const currentStepName = getCurrentStepName();
    // Commenting the below line as Save BOQ in datamart is not temporarIlly required
    // const saveBoQResp =  await saveBoQInDatamart();    
    updateCurrentStep(currentStepName, NEXT_STEP);    
    props.onNext();
  };
  // return to previous step
  const handleBack = () => {
    dispatch(setTheme({ element: "button", comp: `options_global`, prop: "isDisable", value: true }))
    const currentStepName = getCurrentStepName();
    // Validate step data if this step was previously complete
    // const currentStepIndex = steps.findIndex(item => (item.status === 'current'));

    // if (steps[currentStepIndex].isComplete) {
    //   // const isValidated = validateConfigurationStep(currentStepName);
    //   // if (!isValidated) return;
    // }
    // Set current step complete and set previous step to be next step target
    updateCurrentStep(currentStepName, PREVIOUS_STEP, false);
    dispatch(setProp({ slice: "dynamic", key: 'actionNext', value: false }));
    props.onBack();
  };


  const handleActivate = (event) => {
    // validate all steps one last time
    // const isValidated = areaRef && areaRef.current && areaRef.current.validate();
    // if (!isValidated) return;
    // all steps validate successfully, set last step complete
    const currentStepName = getCurrentStepName();
    //updateCurrentStep(currentStepName, CURRENT_STEP);
    // dispatch(setCompleted(true));
    props.onSubmit();
  };
  // move current status to the appropriate step; only 1 step can be current
  const updateCurrentStep = (currentStepName, whichStep, updateCompletedStatus = true) => {
    const currentStepIndex = getStepIndex(currentStepName);
    // remove status = current from all steps
    const newSteps = steps.map((item) => {
      return {
        ...item,
        status: item.name !== currentStepName ? item.status : updateCompletedStatus ? 'completed' : 'future',
        isComplete: item.isComplete || (item.name === currentStepName && whichStep === NEXT_STEP),
        selected: false,
      };
    });
    // make sure current step status is set to valid
    newSteps[currentStepIndex].isValid = true;
    // Set the next current step
    let newSelectedStep = currentStepIndex;

    if (whichStep === NEXT_STEP) {
      const activeSubStepCount = (subStepState[newSteps[newSelectedStep]["name"]] ? subStepState[newSteps[newSelectedStep]["name"]] : 1)
      if(!newSteps[newSelectedStep]["isChildActive"] || (newSteps[newSelectedStep]["isChildActive"] && newSteps[newSelectedStep]["subSteps"].length === activeSubStepCount)){
        newSelectedStep = getNextStepIndex(currentStepIndex);
      }
      // Implement child step cases logic
      if(newSteps[newSelectedStep]["isChildActive"] && newSteps[newSelectedStep]["subSteps"]){
        newSteps[newSelectedStep].status = 'current';
        newSteps[newSelectedStep].selected = true;
        setStepperState(newSteps[newSelectedStep]["name"]);
        // temp
        // ---- Need to make dynamic on NEXT cliks
        let subSteps = [];
        let subStepActive = (subStepState[newSteps[newSelectedStep]["name"]] ? subStepState[newSteps[newSelectedStep]["name"]]+1 : 1);
        setSubStepState((currentState) => ({
          ...currentState,
          [newSteps[newSelectedStep]["name"]]:subStepActive
        }))
        
        let substeps_of_previousStep = [...newSteps[newSelectedStep-1]['subSteps']];

        subSteps = newSteps[newSelectedStep]["subSteps"].map((subStep,i)=>{
          const newSubStep = {...subStep}
          newSubStep.status = "future";
          newSubStep.selected = false;
          // if the current step's previous step has child then make the last substep as completed
          if(newSubStep.stepNumber === 1 &&  newSteps[newSelectedStep-1]['isChildActive']) {
            substeps_of_previousStep[substeps_of_previousStep.length - 1] = {...
              substeps_of_previousStep[substeps_of_previousStep.length - 1],
              status : 'completed',
              selected : false
            }
            substeps_of_previousStep = [...substeps_of_previousStep];
            newSteps[newSelectedStep-1]['subSteps'] = [...substeps_of_previousStep];
          }
          if(newSubStep.stepNumber === subStepActive){
            newSubStep.status = "current";
            newSubStep.selected = true;
            dispatch(setDataConfig({ key: "active_substep", value: newSubStep.stepname }));
            // set ptdac activestep in state machine
            dispatch(setPTDACTab({ index: currentStepIndex, id: newSubStep.stepname }))
          } else if(newSubStep.stepNumber < subStepActive || (newSteps[newSelectedStep]["subSteps"]).length === subStepActive) {
            newSubStep.status = "completed";
            newSubStep.selected = false;
          }
          return newSubStep;
        });
        
        newSteps[newSelectedStep]["subSteps"] = subSteps;
      }
      else{
        newSteps[newSelectedStep].status = 'current';
        newSteps[newSelectedStep].selected = true;
        if(newSelectedStep > currentStepIndex && newSteps[currentStepIndex]["isChildActive"]){
          const __currentActiveSubStep = subStepState[newSteps[currentStepIndex]["name"]];
          // Remove the active sub step of previous step
          setSubStepState((currentState) => ({
            ...currentState,
            [newSteps[currentStepIndex]["name"]]:undefined
          }));

          let currentStepSubSteps = [...newSteps[currentStepIndex]['subSteps']];
          currentStepSubSteps[__currentActiveSubStep-1] = {
              ...currentStepSubSteps[__currentActiveSubStep-1],
              status:'completed',
              selected:false
            }
            newSteps[currentStepIndex]["subSteps"] = currentStepSubSteps;
        }
        // --- Need to check and remove if not needed
        // if(newSteps[currentStepIndex]["isChildActive"] && newSteps[currentStepIndex]["subSteps"]){
        //   setStepperState(newSteps[currentStepIndex]["name"]);
        // }
      }

    } 
    else if (whichStep === PREVIOUS_STEP) {
      dispatch(setDataConfig({ key: "active_substep", value: "" }));
      dispatch(setPTDACTab({ index: newSelectedStep, id: "" }));

      const __currentStepIndex = currentStepIndex;
      const __prevStepIndex = getPreviousStepIndex(currentStepIndex);
      let activeSubStepName = "";
      // Check whether Current Step and Prev step are having sub steps
      if(!newSteps[__currentStepIndex]["isChildActive"] && !newSteps[__prevStepIndex]["isChildActive"]){
        // Normal previous flow - Both steps are not having sub steps
        newSelectedStep = __prevStepIndex;
      }
      else{
        // if the previous step has substeps
        if(newSteps[__prevStepIndex]["isChildActive"] &&
          newSteps[__prevStepIndex]["subSteps"] && 
          newSteps[__prevStepIndex]["subSteps"].length > 0
        ){
          // Handle previous step with sub step
          // Make lat sub step as active for prev step
          const lastSubStep = newSteps[__prevStepIndex]["subSteps"] ? newSteps[__prevStepIndex]["subSteps"].length : undefined;
          // Update local sub step state
          setSubStepState((currentState) => ({
            ...currentState,
            [newSteps[__prevStepIndex].name]:lastSubStep
          }));

          // Update active sub step state
          let currentStepSubSteps =  _.cloneDeep(newSteps[__prevStepIndex]['subSteps']).map((subStep,index) => {
            subStep.status = 'completed';
            subStep.selected = false;

            if(index === (lastSubStep-1)){
              // Make last sub step active
              subStep.status = 'current';
              subStep.selected = true;
              activeSubStepName = subStep.stepname
            }
            return subStep;
          });
          // Update stepper state
          newSteps[__prevStepIndex]["subSteps"] = currentStepSubSteps;
          // Make previous step seletion
          newSelectedStep = __prevStepIndex;          
        }

        // Current Or Prev step having sub steps
        // Handle current step with sub step
        if(newSteps[__currentStepIndex]["isChildActive"] &&
          newSteps[__currentStepIndex]["subSteps"] && 
          newSteps[__currentStepIndex]["subSteps"].length > 0
        ){
          // Current Step having sub step
          // Get active sub step number
          const activeSubStepCount = (subStepState[newSteps[__currentStepIndex].name] ? subStepState[newSteps[__currentStepIndex].name] : 1);

          if(activeSubStepCount === 1){
            // Handle moving back to previous main
            // Unset current step's sub step state
            // Remove the element from local state
            setSubStepState((currentState) => ({
              ...currentState,
              [newSteps[__currentStepIndex].name]:undefined
            }));
            
            // Reset active sub step state
            let currentStepSubSteps = [...newSteps[__currentStepIndex]['subSteps']];
            currentStepSubSteps[0] = {
              ...currentStepSubSteps[0],
              status:'completed',
              selected:false
            }
            if(activeSubStepName === "") {
              activeSubStepName = currentStepSubSteps[0].stepname;
            }
            newSteps[__currentStepIndex]["subSteps"] = currentStepSubSteps;
            // Make previous step seletion
            newSelectedStep = __prevStepIndex;
          }
          if(activeSubStepCount > 1){
            // Prepare new active sub step
            const __newSubStepActive = activeSubStepCount - 1;
            // Update active sub step state
            let currentStepSubSteps = [...newSteps[__currentStepIndex]['subSteps']];
            // Make current sub step completed (index starts from zero , 
            // hence __newSubStepActive = activeSubStepCount - 1 is curent active index)
            currentStepSubSteps[__newSubStepActive] = {
              ...currentStepSubSteps[__newSubStepActive],
              status:'completed',
              selected:false
            }
            // Make prev sub step active
            currentStepSubSteps[__newSubStepActive-1] = {
              ...currentStepSubSteps[__newSubStepActive-1],
              status:'current',
              selected:true
            }
           
            activeSubStepName = currentStepSubSteps[__newSubStepActive-1].stepname;
            // Update local sub step state
            setSubStepState((currentState) => ({
              ...currentState,
              [newSteps[__currentStepIndex].name]:__newSubStepActive
            }));

            // Update stepper state
            newSteps[__currentStepIndex]["subSteps"] = currentStepSubSteps;
            // Make previous step seletion
            newSelectedStep = __currentStepIndex;
          }          
        }
      }
      // Set the stepper state
      newSteps[newSelectedStep].status = 'current';
      newSteps[newSelectedStep].selected = true;
      setStepperState(newSteps[newSelectedStep]["name"]);
      dispatch(setDataConfig({ key: "active_substep", value: activeSubStepName }));
      dispatch(setPTDACTab({ index: newSelectedStep, id: activeSubStepName }));
    }

    localStorage.setItem('currentStepName', newSteps[newSelectedStep].name);
    // setSteps(newSteps as any);
    const dataConfigData = [{ key: "stepper_data", value: newSteps },{ key: "active_step", value: newSteps[newSelectedStep].stepname }];
    dispatch(setDataConfig(dataConfigData));
    // dispatch(setDataConfig({ key: "stepper_data", value: newSteps }));
    // dispatch(setDataConfig({ key: "active_step", value: newSteps[newSelectedStep].stepname }));
  };


  const getNextStepIndex = (currentStepIndex) => {
    const nextStepIndex = currentStepIndex + 1;
    if (nextStepIndex >= steps.length) {
      return currentStepIndex;
    }
    return nextStepIndex;
  };
  const getPreviousStepIndex = (currentStepIndex) => {
    const previousStepIndex = currentStepIndex - 1;
    if (previousStepIndex < 0) {
      return currentStepIndex;
    }
    return previousStepIndex;
  };

  /** isCurrentStepStateRequired */
  const isCurrentStepStateRequired = () => {
    let isStepRequired = false;
    const activeStep = configStepperSelector.data.active_step;
    // Enable/disable NEXT button based on state
    // Get active step's state
    const currentStep = steps.find(step=>step.stepname === activeStep);
    const stepState = currentStep["states"] ? currentStep["states"] : undefined;
    if(!stepState || _.size(stepState) === 0){
      if(currentStep["subSteps"] && _.size(currentStep["subSteps"]) > 0){
        // Logic for sub step state
        // Get current sub step
        const activeSubStep = configStepperSelector.data.active_substep;
        const currentSubStep = currentStep["subSteps"].find(subStep => subStep.stepname === activeSubStep);
        // Check current sub step's states
        // currentSubStep has items and having key required
        if(currentSubStep && currentSubStep["states"] && _.size(currentSubStep["states"]) > 0 && currentSubStep["states"]["required"]){
          const allfieldData = getListOfFields(dynamicSelector.data.element);
          const allStates = getStateOfElement({...currentSubStep,required:false}, allfieldData);
          isStepRequired = allStates.required;
        }        
      }
    }
    else{
      // Whether state has a key called required
      if(stepState && stepState["required"]){
        const allfieldData = getListOfFields(dynamicSelector.data.element);
        const allStates = getStateOfElement({...currentStep,required:false}, allfieldData);
        isStepRequired = allStates.required;
      }      
    }
    return isStepRequired;
  }

  /** isActionNextDisabled */
  const isActionNextDisabled = () => {
    let actionNextDisabled = actionNext;
    if ((themeSelector.data.button.isToggle.hasOwnProperty(`${stateMachineSelector.PTDAC.activeTab}_undo`)) || (themeSelector.data.button.isToggle[`${stateMachineSelector.PTDAC.activeTab}_undo`])) {
      actionNextDisabled = !(themeSelector.data.button.isToggle[`${stateMachineSelector.PTDAC.activeTab}_undo`])
      if(!actionNextDisabled){
        const isNextButtonEnabled = checkNdacPlannerStatus();
        return isNextButtonEnabled ? false : true;
      }
    }
    // Check current step state is required or optonal
    actionNextDisabled = isCurrentStepStateRequired();

    if(actionNextDisabled){
      // Do not proceed in case state condtion is true
      return actionNextDisabled;
    }
    // Custom conditional checks
    // ------------- Need to check the below statement
    // actionNextDisabled = actionNext;

    const isNextButtonEnabled = checkNdacPlannerStatus();
    actionNextDisabled = actionNextDisabled ? actionNextDisabled : !isNextButtonEnabled;    
    return actionNextDisabled;
  }

  /** checkNdacPlannerStatus */
  const checkNdacPlannerStatus = () => {
    if(['reference_solution','mining','manufacturing'].includes(businessSelector.data.options.PTDAC.caseIndustry) && 
    configStepperSelector.data.active_step === "connectivity"){
      // Check whether planner is avaialable and if avaialble plan is linked
      const isPlannerAvailable = dynamicSelector.data.reference_solution.ndacPlanner["connectivity"] && dynamicSelector.data.reference_solution.ndacPlanner["connectivity"].length > 0;
      const linkedPlan = dynamicSelector.data.reference_solution.linkedPlanner ? Object.keys(dynamicSelector.data.reference_solution.linkedPlanner) : [];
      const isNextButtonEnabled =  isPlannerAvailable ? linkedPlan.length > 0 : true;
      return isNextButtonEnabled;
    }
    return true;
  }

  /** -------------- actionNextCheck is current  */
  // -- Not in use 
  // -- Should delete after the code review
  // const actionNextCheck = () => {
  //   let actionNextStatus = true
  //   if (businessSelector.data.options.PTDAC.caseIndustry != '' && configStepperSelector.data.solution_selected != "") {
  //     actionNextStatus = actionNext;
  //     // Set devices and application step as optional steps
  //     if(businessSelector.data.options.PTDAC.caseIndustry === 'reference_solution' && _.includes(skipSteps,configStepperSelector.data.active_step)) {
  //       actionNextStatus = false;
  //     }
  //     else if ((themeSelector.data.button.isToggle.hasOwnProperty(`${stateMachineSelector.PTDAC.activeTab}_undo`)) || (themeSelector.data.button.isToggle[`${stateMachineSelector.PTDAC.activeTab}_undo`])) {
  //       actionNextStatus = !(themeSelector.data.button.isToggle[`${stateMachineSelector.PTDAC.activeTab}_undo`])
  //     } else {
  //       actionNextStatus = true;
  //     }      
  //   }
  //   if (businessSelector.data.options.PTDAC.caseIndustry === "mining") {
  //     actionNextStatus = false;
  //   }
  //   // if(businessSelector.data.options.PTDAC.caseIndustry === 'reference_solution' && configStepperSelector.data.active_step === "connectivity"){
  //   //   // Check whether planner is avaialable and if avaialble plan is linked
  //   //   const isPlannerAvailable = dynamicSelector.data.reference_solution.ndacPlanner["connectivity"] && dynamicSelector.data.reference_solution.ndacPlanner["connectivity"].length > 0;
  //   //   const linkedPlan = dynamicSelector.data.reference_solution.linkedPlanner ? Object.keys(dynamicSelector.data.reference_solution.linkedPlanner) : [];
  //   //   const isNextButtonEnabled =  isPlannerAvailable ? linkedPlan.length > 0 : true;
  //   //   actionNextStatus = actionNextStatus ? actionNextStatus : !isNextButtonEnabled
  //   // }
  //   return actionNextStatus
  // }

  const getStepElements = (item,x) => {
    if(item.isChildActive){
      return (
        <Step
          key={`${item.name}-${x}`}
          variant={getIndicatorVariant(item.name)}
          selected={item.selected}
          stepNumber={item.stepNumber}
          title={item.name == "Review" ? "Review & Submit" : item.name}
          lastStep={x === steps.length - 1}
          isChildActive={item.isChildActive}
          isOpen={stepperState === item.name}
          // isOpen={isOpen}
          // onClick={()=> setIsOpen((prevState)=>!prevState)}
          onClick={() => setStepperState((prevSelection) => prevSelection === item.name ? "":item.name)}
        >
          {
            item.subSteps.map(child=>(
              <SubStep 
                key={`${item.name}-${child.name}`}
                variant={child.status}
                title={child.name} 
                selected={child.selected}
                internalStepProps={{
                  role: "option",
                  'aria-selected': child.selected,
                }}
                />
            ))
          }
          
        </Step>
      )      
    }
    return (
      <Step
        key={`${item.name}-${x}`}
        variant={getIndicatorVariant(item.name)}
        selected={item.selected}
        stepNumber={item.stepNumber}
        onClick={() => setStepperState(item.name)}
        title={item.name == "Review" ? "Review & Submit" : item.name}
        lastStep={x === steps.length - 1}
      />
    );
  }

  const submitButtonStatus = () => {
    const currentStepName = getCurrentStepName();
    const isActivate = businessSelector.data.view.PTDAC.additionalFeature.length > 0 || currentStepName === 'Review' ? true : false;

    if(businessSelector.data.options.PTDAC.caseIndustry === "mining" && currentStepName !== 'Review') {
      const miningStatus = identifySolutionRedirect([businessSelector.data.options.PTDAC.ndac_ref.fullSolType, businessSelector.data.options.PTDAC.ndac_ref.fullMiningType]);
      return !(miningStatus.next);
    } 
    else if(_.includes(['logistics',"manufacturing"],businessSelector.data.options.PTDAC.caseIndustry) && currentStepName !== 'Review') {
      const manfStatus = identifyMPWRedirect([dynamicSelector.data.reference_solution.solution]);
      return !(manfStatus.next);
    }
    return isActivate;
    
  }
  
  // const isActivate = (businessSelec,tor.data.options.PTDAC.caseIndustry === "reference_solution" && dynamicSelector.data.reference_solution.solution === 'MPW') || businessSelector.data.view.PTDAC.additionalFeature.length > 0 || getCurrentStepName() === 'Review' ? true : false;

  return (
    <>
      <div className="stepFormSegment">
        <StepFormArea >
          <StepForm style={{ width: '20%' }}>
            {
              !configStepperSelector.data.isStepperLoading && steps && steps.length >= 1 ?
                (steps && steps.map((item, x) => getStepElements(item, x) )) :
                (<div style={{ display: 'flex', alignItems: "center", flexDirection: "column" }}>
                  <Skeleton style={{ width: '200px' }} />
                </div>)
            }
          </StepForm>
          <div style={{ width: '100%' }} >
            <StepConfigurationArea style={{ padding: '0 0 0 1rem' }}>
              {currentStepArea}
            </StepConfigurationArea>
          </div>
        </StepFormArea>
        {/* <HorizontalDivider /> */}
        <ButtonsRow>
          <Button onClick={() => dispatch(showModal({
            msg: "Are you sure you want to cancel the Case? All the changes done will be lost.",
            title: "Cancel Case",
            variant: "WARNING",
            show: true,
            action: [
              {
                label: "No",
                variant: "neutral",
                onAction: serializeFunction(() => {
                  window['store'].dispatch(window['authSlice'].showModal({ msg: "", panel: { title: '', msg: '' }, show: false, }))
                })
              },
              {
                label: "Yes",
                variant: "call-to-action",
                onAction: serializeFunction(() => {
                  window.location.href = "/";
                }),
                fullWidth: true,
              }
            ]
            //

          }))}>Cancel</Button>
          <Button disabled={makeDisable} onClick={() => {
            handleBack();
          }}>
            <ButtonIcon>
              <ChevronLeftIcon />
            </ButtonIcon>
            <ButtonText>
              Previous</ButtonText></Button>
          {
            submitButtonStatus() ? (<Button disabled={props.isDisable} variant='call-to-action'
              onClick={handleActivate}>
              <ButtonText>
                Submit
              </ButtonText>
              </Button>) : (
                // <Button disabled={actionNextCheck()} variant='call-to-action'
                <Button disabled={isActionNextDisabled()} variant='call-to-action'
              onClick={() => {
                handleContinue();
              }}
            >
              <ButtonText>
                Next
              </ButtonText>
              <ButtonIcon>
                <ChevronRightIcon />
              </ButtonIcon>

            </Button>)
          }

        </ButtonsRow>
      </div >

    </>
  )
}

export default Stepper

Stepper.propType = {
  onSubmit: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  isDisable: PropTypes.bool
}