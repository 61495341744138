import { ButtonsRow, IconButton } from '@nokia-csf-uxr/ccfk';
import DeleteIcon from '@nokia-csf-uxr/ccfk-assets/latest/DeleteIcon';
import { removeCartItem } from 'Store/contentState/cartBoqSlice';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
interface ActionBtns {
    product: any,
  }
const ActionBtns = (props:ActionBtns) => {
    const dispatch = useDispatch();
    return (
        <ButtonsRow>
            <IconButton
                disabled={props.product.cui_type == "extension"}
                //hidden={props.product.cui_type == "extension"}
                onClick={() => {
                    dispatch(removeCartItem({products:(props.product?.hasOwnProperty('extensionData')) ? [...[props.product], ...props.product.extensionData] : [props.product],history:true}))
                }}
            ><DeleteIcon /></IconButton>
        </ButtonsRow>
    )
}

export default ActionBtns

ActionBtns.propType = {
    product: PropTypes.object
}