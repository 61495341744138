import React, { useState, useEffect } from "react";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import ConfiguratorSelectBar from "Components/Elements/ConfiguratorSelectBar";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import { ToggleButtonGroup, Typography } from "@nokia-csf-uxr/ccfk";
import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import { ToggleGroupButton } from "@nokia-csf-uxr/ccfk/ToggleButtonGroup";
import { error } from "console";
import { setProp } from "Store/Actions";
import { HelpText } from "../hooks/useHelpText";

interface DataList {
    data: Array<any>,
    optionsData: object,
    active_step: string,
    updateDispatchStore?: Function
    isDisabled: boolean,
    errorMessageText?: Function,
    ref: object,
    getReturnValue?: Function
    dataStore:object
    sliceProperty?:string,
    showdropdown?:boolean,
    placeholder?:string,
    selectStyle?:object
}

const SelectFields = (props: DataList) => {
    const themeSelector = useSelector((state: RootState) => state.theme);
    const dynamicSelector = useSelector((state: RootState) => state.dynamic);
    const label = props.data[1].required ? props.data[1].label + ' *' : props.data[1].label
    const sliceProperty = props.sliceProperty ? `${props.sliceProperty}.${props.data[0]}` : 'element.' + props.active_step + "." + props.data[0]
    const [errorDisabled, setErrorDisabled] = useState(props.data[1].required);
    const emptyOptions: any = [];
    const disableState = props.data[1].disabled ? props.data[1].disabled : props.isDisabled;
    const dispatch = useDispatch();

    const {
        showHelpText
    } = HelpText();

    useEffect(() => {
        if (props.data[1].required) {
            if (props.dataStore && props.dataStore[props.data[0]] !== undefined) {
                const optionValue = props.dataStore[props.data[0]].key;
                if (optionValue !== "" && optionValue !== undefined) {
                    setErrorDisabled(false)
                }
                else {
                    setErrorDisabled(true)
                }
            }
        }
    }, [props.dataStore])

    const getReturnValue = () => {
        //props.getReturnValue(props.data[1],'')
        dispatch(setProp({ slice: "dynamic", key: `element.${props.active_step}.${[props.data[0]]}`, value: "" }));
    }

    const getReturnSelectValue = (optionsList, item) => {
        let selectedValue = "";
        if (props.dataStore) {
            let optionSelect = optionsList.find(e => e.id === props.dataStore[item].key)
            if (optionSelect) {
                selectedValue = optionSelect.value
            }
        }
        return selectedValue
    }

    const getSelectValue = (itemData, item, value) => {
        let status = false;
        if (itemData[1].defaultValue && itemData[1].defaultValue === "1") {
            status = true;
        } else {
            status = false;
        }
        // ---- Need to cross check this statement and logic. // should be dynamic with props.dataStore
        if (dynamicSelector.data.element && Object.entries(dynamicSelector.data.element).length === 0) {
            status = false
        }
        else {
            if (props.dataStore) {
                if (props.dataStore[item] && props.dataStore[item].length !== 0) {
                    if (props.dataStore[item].length !== undefined) {
                        let valueSelect = props.dataStore[item][0].key === value;
                        if (valueSelect) {
                            status = true
                        }
                    }
                    else {
                        if (props.dataStore[item].key === value) {
                            status = true
                        }
                    }
                }
                else {
                    if (props.dataStore[item] && props.dataStore[item].key === value) {
                        status = true
                    }
                }
            }

        }
        return status
    }

    const getKeyvalueData = (dataSet, selectkey) => {
        // let optionsList = props.optionsData[props.active_step][props.data[0]]
        let optionsList = props.optionsData[props.data[0]];
        let selectKeyValue = {}
        optionsList.forEach(element => {
            if (element.id === selectkey) {
                selectKeyValue['key'] = element.id
                selectKeyValue['value'] = element.value
            }
        });
        props.updateDispatchStore(dataSet, selectKeyValue);
    }

    return (
        <>
            <div style={{ display: 'flex' }}>
                {label && 
                <TextInputLabelContent>
                    {label}
                </TextInputLabelContent>
                }
                {label && showHelpText(props.data)}
            </div>
            {
                // props.optionsData && Object.entries(props.optionsData).length === 0 ? emptyOptions : props.optionsData[props.active_step][props.data[0]] && props.optionsData[props.active_step][props.data[0]].length > 2 ? (
                props.optionsData && props.optionsData[props.data[0]] && props.optionsData[props.data[0]].length === 0 ? emptyOptions : props.optionsData[props.data[0]] && (props.optionsData[props.data[0]].length > 2 || props.showdropdown) ? (
                    <>
                        <div> <ConfiguratorSelectBar
                            key={props.data[0].id}
                            isDisabled={props.isDisabled}
                            eleref={props.ref as React.Ref<HTMLElement>}
                            handOnSelect={(e) => {
                                getKeyvalueData(props.data[0], e)
                            }}

                            isOpen={themeSelector.data.select.isOpen[props.data[0]]}
                            options={Object.entries(props.optionsData).length === 0 ? emptyOptions : props.optionsData[props.data[0]]}
                            slice="dynamic"
                            onlyHandOnSelect={true}
                            renderValue={getReturnSelectValue(Object.entries(props.optionsData).length === 0 ? emptyOptions : props.optionsData[props.data[0]], props.data[0])}
                            optionPath="id"
                            displayKey='value'
                            error={errorDisabled}
                            setProperty={{
                                sliceProperty: sliceProperty,
                                themeProperty: props.data[0],
                            }}
                            getReturnValue={getReturnValue}
                            placeholder={props.placeholder}
                            style={props.selectStyle}
                        />
                        </div>
                    </>
                ) : (
                    (<>
                        <ToggleButtonGroup>
                            {Object.entries(props.optionsData).length === 0 ? emptyOptions : props.optionsData[props.data[0]]?.map((bTValue, i) => {
                                return (
                                    <>
                                        <ToggleGroupButton
                                            key={i}
                                            disabled={disableState}
                                            className={"toggleButton"}
                                            isSelected={getSelectValue(props.data, props.data[0], bTValue.id)}
                                            onChange={(event) => {
                                                getKeyvalueData(props.data[0], bTValue.id)
                                            }}
                                            ref={props.ref as React.Ref<HTMLElement>}
                                        >
                                            <ButtonText>{bTValue.value}</ButtonText>
                                        </ToggleGroupButton>
                                    </>
                                );
                            })}

                        </ToggleButtonGroup>
                        {(errorDisabled ? <>{props.errorMessageText(props.data[1].label)}</> : '')}
                    </>
                    )
                )}
        </>
    )
}


export default SelectFields;