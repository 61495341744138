
import { ButtonsRow, Skeleton, ToggleButtonGroup } from '@nokia-csf-uxr/ccfk';
import { ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import { MultiSelectSmartListCheckbox } from '@nokia-csf-uxr/ccfk/MultiSelectSmartList';
import { TextInputLabelContent } from '@nokia-csf-uxr/ccfk/TextInput';
import { ToggleGroupButton } from '@nokia-csf-uxr/ccfk/ToggleButtonGroup';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ProductCatalog from 'Components/Content/modelArea/ProductCatalog';
import AddCartBtn from 'Components/Elements/AddCartBtn';
import AddCatalogBtn from 'Components/Elements/AddCatalogBtn';
import DialogModal from 'Components/Elements/DialogModal';
import MultiCheckBoxList from 'Components/Elements/MultiCheckBoxList';
import SelectBar from 'Components/Elements/SelectBar';
import OOT from 'Constants/services/oot';
import { setProp } from 'Store/Actions';
// import { showPopup } from 'Store/auth';
// import { addCartItem, incCartItemQty, prepareCart } from 'Store/contentState/cartBoqSlice';
import { addCartItem, prepareCart, removeCartItem } from 'Store/contentState/cartBoqSlice';
import { setConnectivityTabRules, undoHistory } from 'Store/contentState/stateMachine';
import { RootState } from 'Store/mainStore';
import { setTheme } from 'Store/theme';
// import _ from 'lodash';
import { ListItemTextContainer } from '@nokia-csf-uxr/ccfk/List';
import QuantityModifier from 'Components/Elements/QuantityModifier';
import { showModal } from 'Store/auth';
import { batchGroupBy, getRemainingSteps } from 'Utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bandwidth, connectivity_solution, private_wireless_users } from './system';


const Options = () => {
    const connectivitySelector = useSelector((state: RootState) => state.connectivity);
    const themeSelector = useSelector((state: RootState) => state.theme);
    const cartBoqSelector = useSelector((state: RootState) => state.cartBoq);
    const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
    const stateMachineSelector = useSelector((state: RootState) => state.stateMachine);
    const dispatch = useDispatch();
    const slice = "connectivity";
    const isrAState = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.getrecommendedApplicationLogic
    const isrDstate = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.getrecommendedDevicesLogic
    const iscatalogstate = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.getcatalogLogic
    const isrAPstate = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.getrAPConnectivityTabLogic
    const isCatalogState = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.getcatalogConnectivityTabLogic
    const isSparesState = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.getspareAdditionConnectivityTab
    const isUserSelectionState = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.userSelectionProductsConnectivityTab
    const spareItems = cartBoqSelector.data.cart.items.filter((e) => e.type === "spares");
    const [addToCart, setAddToCart] = useState(true) // Make ATC Button == Active when True.
    const queryClient = useQueryClient();
    
    const remainingSteps = getRemainingSteps(stateMachineSelector.PTDAC.activeTab, stateMachineSelector.PTDAC.selected);
    const connectivityTab = useQuery({

        queryKey: ['connectivityTab', caseDetailsSelector.data.country, { account: caseDetailsSelector.data.account_id, radioBand: connectivitySelector.data.options.PTDAC.radio_frequency_band }],
        queryFn: async ({ queryKey }) => {
            //@ts-ignore
            const [_key, country, { account, radioBand }] = queryKey;
            const response = await OOT.getConnectivityTab({ account: account, radioBand: radioBand, selector: caseDetailsSelector });
            return response;
        },
        refetchOnMount: false,
        enabled: false,
        onSuccess(res) {
            dispatch(setConnectivityTabRules(res.data));
        },
    })
    const setBands = useQuery({
        refetchOnMount: false,
        queryKey: ['setBands', caseDetailsSelector.data.country],
        queryFn: async ({ queryKey }) => {
            const [_setBands, country] = queryKey;
            const response = await OOT.getCountryBands({ country: country })
            return response;
        },
        onSuccess: (response) => {
            if (response.status) {
                dispatch(setProp({ "slice": slice, key: "system.radio_frequency_band", value: response.data }));
                dispatch(setProp({ slice: "cartBoq", key: `system.prepare.suggest.aps`, value: [] }))
            }
        }
    })
    const undoStep = (step) => {
        const removeProducts = stateMachineSelector.PTDAC.history[step].filter((e) => e.cui_type != "extension"); //TODO: Nitesh- Think about it- why filter works.
        dispatch(removeCartItem({ products: removeProducts, parentSync: !false, unWrap: !false, history: true }))//TODO: Nitesh- Think about it - why false works.
        dispatch(undoHistory({ key: `PTDAC.history.${step}` }))
        dispatch(setTheme({ element: "button", comp: `${step}_undo`, prop: "isToggle", value: false }))
    }
    useEffect(() => {
        if (connectivitySelector.data.options.PTDAC.private_wireless_users) {
            connectivityTab.refetch();

        }
    }, [connectivitySelector.data.options.PTDAC.private_wireless_users])
    useEffect(() => {
        //gaurd statement - not good.
        if (cartBoqSelector.data.cart.items.some((e) => e?.product_name ? e.product_name.includes("RRH") : false)) {
            if (cartBoqSelector.data.system.prepare.catalog.length > 0) { //only during catalog event
                if (!(cartBoqSelector.data.cart.items.some((e) => e.hasOwnProperty('cui_product_category')))) {
                    dispatch(showModal({ show: true, msg: "Please add the Indoor bbu and power solutions from Catalog manually", title: "Suggested Solutions", variant: "WARNING" }));
                }
            }
        }

    }, [cartBoqSelector.data.cart.items])


    return (
        <>
            <DialogModal isOpen={themeSelector.data.modal.isOpen.connectivity_catalog}
                type='modal'
                dialogInfo={{ title: 'Product Catalog' }}
                bannerText={Object.keys(stateMachineSelector.PTDAC.history).length>2 ? 'Please Note: All forward steps will be reseted.' : undefined}
                actionBtns={[
                    {
                        label: "Cancel", onAction: () => {
                            dispatch(setTheme({
                                element: "modal",
                                comp: "connectivity_catalog",
                                prop: "isOpen",
                                value: false,
                            }))

                        }, variant: "neutral"
                    },
                    {
                        label: "Add", onAction: () => {
                            //handling Reseting
                            if (Object.keys(stateMachineSelector.PTDAC.history).length > 2) {
                                // const currentStep =stateMachineSelector.PTDAC.activeTab;
                                const currentStep = Object.keys(stateMachineSelector.PTDAC.history)[stateMachineSelector.PTDAC.activeIndex];//from edge (index is n+1)
                                const remainingSteps = getRemainingSteps(stateMachineSelector.PTDAC.activeTab, stateMachineSelector.PTDAC.selected);
                                if (remainingSteps.length && (!remainingSteps.includes(currentStep))) {
                                        for (let index = 0; index < remainingSteps.length; index++) {
                                            const activeTab = remainingSteps[index] ? remainingSteps[index] : currentStep;
                                            if (Object.keys(stateMachineSelector.PTDAC.history).includes(activeTab)) {
                                                undoStep(activeTab)
                                            }
                                        }
                                    }
                            }
                     
                            
                            
                            if (!(themeSelector.data.button.isToggle[`${stateMachineSelector.PTDAC.activeTab}_undo`])) {
                                dispatch(setTheme({ element: "button", comp: `${stateMachineSelector.PTDAC.activeTab}_undo`, prop: "isToggle", value: true }))
                            }

                            batchGroupBy.start(stateMachineSelector.PTDAC.activeTab);
                            if (isrAPstate.data) {
                                // check if already items are present in boq then increment existing
                                dispatch(addCartItem({ products: isUserSelectionState.data.products, history: true }))
                            }
                            if (isSparesState) {
                                const catalogConcatSpares = (!isSparesState.response_code) ?
                                    cartBoqSelector.data.system.prepare.catalog :
                                    [...cartBoqSelector.data.system.prepare.catalog.filter((e) => e.cui_type != "extension"),
                                    ...isSparesState.data.spares];
                                dispatch(
                                    addCartItem({ products: catalogConcatSpares, parentSync: true, history: true })
                                );

                            }
                            //dispatch(setProp({slice: "cartBoq", key: `system.prepare.suggest.aps`, value: [] }))
                            dispatch(setProp({ slice: "cartBoq", key: `system.prepare.catalog`, value: [] }))
                            dispatch(setTheme({
                                element: "modal",
                                comp: "connectivity_catalog",
                                prop: "isOpen",
                                value: false,
                            }))
                            //reseting the state 2
                            batchGroupBy.end();
                            // show a warning if BBU/POW doesn't exists- we are using setTimeout to check after certain interval of addCartItem Dispatch action.
                        }, variant: "call-to-action"
                    }]} >
                {
                    (isCatalogState?.data?.menus.length > 0 && isCatalogState?.data?.catalog) && (<ProductCatalog
                        catalog={isCatalogState.data.catalog}
                        menus={isCatalogState.data.menus}
                        onTabSelect={(e) => {
                        }}
                        getCatalog={(cartItems, selectedTab) => {
                            //Rule: add the selected products + mandatory products linked to selected tab to be added.
                            dispatch(prepareCart([...cartItems]))
                        }}
                        getSelectedProduct={(product) => {
                        }}
                    />)
                }
            </DialogModal >
            <div id="connectivity-solution" className='mb'>
                <TextInputLabelContent >Connectivity Solution *</TextInputLabelContent>
                <ToggleButtonGroup  >
                    {
                        connectivity_solution.map((cs, i) => {
                            return (<ToggleGroupButton key={i} isSelected={connectivitySelector.data.options.PTDAC.connectivity_solution === cs.id} onChange={(event) => {
                                dispatch(setProp({ slice: "connectivity", key: "options.PTDAC.connectivity_solution", value: cs.id }))
                            }}> <ButtonText>{cs.value}</ButtonText> </ToggleGroupButton>)
                        })

                    }
                </ToggleButtonGroup>
            </div>
            <div id="radio-frequency-band" className='mb'>
                <TextInputLabelContent >Radio Frequency Band *</TextInputLabelContent>
                {
                    (setBands.isLoading) ? (<>
                        <Skeleton width={'250px'} />

                    </>) :
                        (<>
                            <SelectBar
                                key={'radio_frequency_band'}
                                isOpen={themeSelector.data.select.isOpen.connectivity_radio_frequency_band}
                                options={connectivitySelector.data.system.radio_frequency_band}
                                renderValue={connectivitySelector.data.options.PTDAC.radio_frequency_band}
                                variant='outlined'
                                optionPath='id'
                                displayKey={"value"}
                                slice='connectivity'
                                setProperty={{
                                    sliceProperty: "options.PTDAC.radio_frequency_band",
                                    themeProperty: "connectivity_radio_frequency_band",
                                }}
                                handOnSelect={(radio_frequency_band) => {
                                    dispatch(prepareCart({ method: "remove", product: [], type: "suggest.aps" }));
                                    setTimeout(() => connectivityTab.refetch(), 1200);
                                }}
                            />
                        </>)
                }

            </div>
            <div id="bandwidth" className='mb'>
                <TextInputLabelContent >Bandwidth (MHz)</TextInputLabelContent>

                <SelectBar
                    isOpen={themeSelector.data.select.isOpen.connectivity_bandwidth}
                    options={bandwidth}
                    renderValue={(connectivitySelector.data.options.PTDAC.bandwidth).toString()}
                    variant='outlined'
                    optionPath='id'
                    displayKey={"value"}
                    slice='connectivity'
                    setProperty={{
                        sliceProperty: "options.PTDAC.bandwidth",
                        themeProperty: "connectivity_bandwidth",
                    }}
                />
            </div>
            <div id="pvt-wireless" className='mb'>
                <TextInputLabelContent >Private wireless users * </TextInputLabelContent>
                <SelectBar
                    isOpen={themeSelector.data.select.isOpen.connectivity_private_wireless_users}
                    options={private_wireless_users}
                    renderValue={connectivitySelector.data.options.PTDAC.private_wireless_users}
                    variant='outlined'
                    optionPath='id'
                    displayKey={"value"}
                    slice='connectivity'
                    setProperty={{
                        sliceProperty: "options.PTDAC.private_wireless_users",
                        themeProperty: "connectivity_private_wireless_users",
                    }}
                    handOnSelect={(e) => {  
                        
                    }}
                />
            </div>
            <div id="recommended-aps" className='mb' hidden={!Boolean(isrAPstate?.response_code)}>
                <MultiCheckBoxList title='Recommended Access Points' >
                    {
                        (isrAPstate?.response_code) ? (<>
                            {
                                isrAPstate.data.products.map((rD, i) => {
                                    return (
                                        <MultiSelectSmartListCheckbox key={i}
                                            disabled={themeSelector.data.button.isToggle.connectivity_undo}
                                            selected={cartBoqSelector.data.system.prepare.suggest.aps.some((product) => product.product_id === rD.product_id)}
                                            // label={`${rD.product_name} #${rD.quantity}`} subLabel={rD.product_id}
                                            label={`${rD.product_name}`} subLabel={rD.product_id}
                                            icon={<div className='mr'>
                                                {/* <Typography typography="BODY">{`#${rD.quantity}`}</Typography> */}
                                                <ListItemTextContainer key={i}>
                                                    <QuantityModifier key={i}
                                                        product={rD}
                                                        isEdit={(e) => {
                                                            setTimeout(() => dispatch(setTheme({ element: "button", comp: `${slice}_editSelection`, prop: "isToggle", value: !e, })), 1000)
                                                        }}
                                                        isDisabled={(!(cartBoqSelector.data.system.prepare.suggest.aps.find((product) => product.product_id === rD.product_id)))}
                                                        onUpdateQty={
                                                            (val) =>
                                                                Math.sign((rD.quantity - val)) == - 1 ?
                                                                    dispatch(addCartItem({ products: [new Object({ ...rD, quantity: Math.abs((rD.quantity - val)) })], targetItems: "suggest.aps", parentSync: false, unWrap: false }))
                                                                    : dispatch(removeCartItem({ products: [new Object({ ...rD, quantity: Math.abs((rD.quantity - val)) })], targetItems: "suggest.aps", unWrap: false }))
                                                        }
                                                        onDecQty={() => dispatch(removeCartItem({ products: [new Object({ ...rD, quantity: 1 })], targetItems: "suggest.aps", parentSync: false, unWrap: false }))}
                                                        onIncQty={() => {
                                                            dispatch(addCartItem({ products: [new Object({ ...rD, quantity: 1 })], targetItems: "suggest.aps", parentSync: false, unWrap: false }))

                                                        }}
                                                        value={Math.round(rD.quantity)} />
                                                </ListItemTextContainer>
                                            </div>}
                                            onChange={(e) => {
                                                if (e.target.checked && e.type === "change") {
                                                    dispatch(prepareCart({ method: "add", product: rD, type: "suggest.aps" }));

                                                    return;
                                                } else {
                                                    if (e.type === "change") {
                                                        dispatch(prepareCart({ method: "remove", product: rD, type: "suggest.aps" }));
                                                    }
                                                }
                                            }} />
                                    )
                                })
                            }

                        </>) : (<Skeleton style={{ width: '250px' }} />)
                    }
                </MultiCheckBoxList>
            </div>
            <ButtonsRow>
                <AddCatalogBtn isLoading={!(isCatalogState && isCatalogState.response_code)}
                    onAction={() => {
                        dispatch(prepareCart([]))
                        // dispatch(setProp({ slice: "cartBoq", key: `system.prepare.suggest.aps`, value: [] }));
                    }}
                    renderSkip={{
                        showSkip: false,
                        onSkip: () => {

                        }
                    }} />
                <AddCartBtn isLoading={!(isrAPstate && isrAPstate.response_code && themeSelector.data.button.isToggle[`${slice}_editSelection`])}
                    onAction={() => {
                        if (isSparesState) {
                            const catalogConcatSpares = (!isSparesState.response_code) ?
                                cartBoqSelector.data.system.prepare.catalog :
                                [...cartBoqSelector.data.system.prepare.catalog.filter((e) => e.cui_type != "extension"), ...isSparesState.data.spares];
                            dispatch(addCartItem({ products: catalogConcatSpares, history: true }));
                        }
                        dispatch(addCartItem({ products: cartBoqSelector.data.system.prepare.suggest.aps, parentSync: true, history: true }));
                        dispatch(addCartItem({ products: isUserSelectionState.data.products, history: true }))

                        //dispatch(setProp({ slice: "cartBoq", key: `system.prepare.suggest.aps`, value: [] }))
                        dispatch(setProp({ slice: "cartBoq", key: `system.prepare.catalog`, value: [] }))
                        // check if extra products present in boq then increment else add to cart
                        // dispatch(addCartItem(cartBoqSelector.data.system.prepare.suggest.aps.concat(isUserSelectionState.data.products)))
                    }} />
            </ButtonsRow>

        </>
    )
}


export default React.memo(Options)