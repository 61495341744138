import { InlineFeedbackMessage } from '@nokia-csf-uxr/ccfk';
import { Body } from 'Components/Body';
import { useConfigurator } from 'Components/Content/configurationArea/Configurator/hooks/useConfigurator';
import StepperOthers from 'Components/Elements/Stepper_others';
import StepperPort from 'Components/Elements/Stepper_port';
import Header from 'Components/Header';
import { useAuthState } from "Components/Logics/ensoCommonFunctions";
import { handleOffer } from "Components/Logics/systemLogics";
import { setPTDACTab } from 'Store/contentState/stateMachine';
import { RootState } from 'Store/mainStore';
import { capitalizeFirstLetter } from 'Utils';
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import LoadingOverlay from 'react-loading-overlay-ts';
// import { useDispatch, useSelector } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
export const Segment = () => {

    const state = useSelector((state: RootState) => state);
    const authSelector = useSelector((state: RootState) => state.authSlice);
    const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
    const businessSelector = useSelector((state: RootState) => state.business);
    const stateMachineSelector = useSelector((state: RootState) => state.stateMachine);
    useAuthState();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const themeSelector = useSelector((state: RootState) => state.theme);
    const {getListOfFields} = useConfigurator();

    // const dispatch = useDispatch();
    useEffect(() => {
        if (caseDetailsSelector.data.account.length == 0) {
            window.location.href = '/?q=need_data';
        }
    }, [])
    const [Error, setError] = useState(undefined)
    const isValid = useMemo((): boolean => {
        if ((businessSelector.data.options.PTDAC.caseIndustry === "")) {
            return false;
        }
        if (!(stateMachineSelector.PTDAC.selected.hasOwnProperty(stateMachineSelector.PTDAC.activeTab))) {
            return true;
        }
        const validation: any = Object.values(stateMachineSelector.PTDAC.selected[stateMachineSelector.PTDAC.activeTab]).find((e: any) => !e.response_code);
        setError(validation);
        //Route for isDisable-> user click on next: next@true->use click atc:next@false->user click undo:next@true->uset click on previous:next@false
        return Object.values(stateMachineSelector.PTDAC.selected[stateMachineSelector.PTDAC.activeTab]).every((e: any) => e.response_code) && themeSelector.data.button.isToggle[`${stateMachineSelector.PTDAC.activeTab}_undo`];

    }, [stateMachineSelector.PTDAC.selected])

    return (
        <>
            <LoadingOverlay
                active={authSelector.system.isLoading}
                className="scroll"
                spinner
                classNamePrefix="MyLoader_"
                text="Please wait "
            >

                <Header />
                <Body
                    crumbs={[
                        caseDetailsSelector.data.caseName
                            ? "Create Case: " + caseDetailsSelector.data.caseName + ` | ${capitalizeFirstLetter(businessSelector.data.options.PTDAC.caseIndustry)}`
                            : "Create Case",
                    ]}
                    bodyContent={
                        <>
                            {
                                (!authSelector.system.isLoading) ? (
                                    <>
                                        {
                                            (Error) && (<InlineFeedbackMessage show={Error} variant={Error && Error?.error?.type && Error?.error?.type == 'pending' ? "warning" : "error"} >
                                                {Error.response_msg}
                                            </InlineFeedbackMessage>)
                                        }
                                        {
                                            (!_.includes(["reference_solution","mining","manufacturing"],businessSelector.data.options.PTDAC.caseIndustry)) ? (
                                                <StepperPort
                                                    isDisable={!isValid}
                                                    onBack={() => { }}
                                                    onNext={() => { }}
                                                    onSubmit={async () => {
                                                        const res = await handleOffer(state);
                                                        if (typeof res === 'object') {
                                                            if (res.response_code) {
                                                                navigate('/')
                                                            }
                                                        }
                                                    }}
                                                    getStep={(current) => {
                                                        dispatch(setPTDACTab({ index: current.index, id: current.step.id }))
                                                    }}
                                                />
                                            ) : (
                                                <StepperOthers
                                                    isDisable={!isValid}
                                                    onBack={() => { }}
                                                    onNext={() => { }}
                                                    onSubmit={async () => {
                                                        const res = await handleOffer(state,getListOfFields);
                                                        if (typeof res === 'object') {
                                                            if (res.response_code) {
                                                                navigate('/')
                                                            }
                                                        }
                                                    }}
                                                    getStep={(current) => {
                                                        if(!current.step.isChildActive) {
                                                            dispatch(setPTDACTab({ index: current.index, id: current.step.id }))
                                                        }
                                                    }}
                                                />
                                            )
                                        }
                                    </>
                                ) : (<></>)
                            }
                        </>
                    }
                />

            </LoadingOverlay>
        </>
    )
}
