import { HorizontalDivider, ToggleSwitch, Typography } from "@nokia-csf-uxr/ccfk";
import { SimpleCard } from "@nokia-csf-uxr/ccfk/Card";
import { ROLE } from "Components/Misc/CaseDetails_System";
import { setRole } from "Store/auth";
// import { IStore } from "Store/contentState/ts";
import { RootState } from "Store/mainStore";
// import React, { useMemo } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
const TesterPanel = () => {
    const dispatch = useDispatch();
    // const loginSelector = useSelector((state: IStore) => state.authSlice);
    // const themeSelector = useSelector((state: RootState) => state.theme);
    const authSelector = useSelector((state: RootState) => state.authSlice);
    // const firstName = loginSelector.login.givenName;
    // const lastName = loginSelector.login.surname;
    // const isTester = useMemo(() => authSelector.tester, [authSelector.role]);
    return (
        <div style={{ display: (authSelector.tester) ? "block" : "none" }}>
            {/* <SimpleCard className="p">
                <Typography typography="CAPTION">You are a tester🐱‍👤</Typography>
                <HorizontalDivider />
                <Typography typography="MICRO">Switch Role (Current:{authSelector.role})</Typography>
                <ToggleSwitch
                    checked={authSelector.role === ROLE.PARTNER}
                    onChange={(e) => {
                        if (e.value) {
                            dispatch(setRole(ROLE.PARTNER))
                            return;
                        }
                        dispatch(setRole(ROLE.INTERNAL))
                    }}
                />
            </SimpleCard> */}
        </div>
    )
}

export default TesterPanel