import StateMachine from 'Components/Logics/stateMachine';
import _ from 'lodash';
// import { Dispatch, Middleware, MiddlewareAPI } from 'redux'
import { Dispatch, MiddlewareAPI } from 'redux';
import { store } from 'Store/mainStore';
// import { store } from './mainStore';
import { setPTDACLogics } from './contentState/stateMachine';
import pkg from "Utils/package.json";
console.log(`%c DOT v${pkg.version} 🚀`, 'background: #222; color: #bada55');

var debounce_fun = _.debounce(() => {
  const sm = new StateMachine(store.getState());
  const mandatoryApp = sm.navigateBusiness()?.navigateApplication()?.interceptApplication()?.navigateConnectivity()?.interceptConnectivity()?.navigateEdge()?.navigateDevices()?.navigateServices()?.navigateWarranty();
  store.dispatch(setPTDACLogics(mandatoryApp?.response))
  // console.log(`🔬 RES:`,mandatoryApp?.response)
}, 800)
export const myLoggingMiddleware = (_: MiddlewareAPI) => (next: Dispatch) => (action: any) => {
  if (window.location.href.includes('/segment') && action.type !== 'stateMachine/setPTDACLogics') {
    /*
    This makes sure the RULE-ENGINE only  runs on the SEGMENT SOLUTIONS.
    */
    debounce_fun();
  }
  return next(action)
}