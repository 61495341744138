import React, { useState, useEffect } from "react";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import MultiCheckBoxList from 'Components/Elements/MultiCheckBoxList'
import { MultiSelectSmartListCheckbox } from '@nokia-csf-uxr/ccfk/MultiSelectSmartList'
import { ListItemBasic, ListItemTextContainer } from '@nokia-csf-uxr/ccfk/List'
import QuantityModifier from 'Components/Elements/QuantityModifier'
import { setProp } from "Store/Actions";
import { Spinner, Label, Checkbox, HorizontalDivider, Button } from '@nokia-csf-uxr/ccfk';
import ExpansionPanelsContainer, {
    ExpansionPanel,
    ExpansionPanelTitle,
    ExpansionPanelHeader,
    ExpansionPanelBody,
    ExpansionPanelButton
} from '@nokia-csf-uxr/ccfk/ExpansionPanels';
import TOKENS from '@nokia-csf-uxr/nokia-design-system-tokens/js/tokens.es6';
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import { HelpText } from "../hooks/useHelpText";

interface DataList {
    data: Array<any>,
    optionsData: object,
    active_step: string,
    updateDispatchStore?: Function
    isDisabled: boolean,
    errorMessageText?: Function,
    expandVisibility: boolean,
    dataStore?:Object
}
const PADDING = `${TOKENS.SPACING_MEDIUM} ${TOKENS.SPACING_LARGE}`;
const emptyMessage = 'No Items are available based on selected parameters';

const Multicheckboxs = (props: DataList) => {
    // const dynamicSelector = useSelector((state: RootState) => state.dynamic);
    const dispatch = useDispatch();
    const {
        showHelpText
    } = HelpText();

    const [expanded, setExpanded] = React.useState(undefined);
    const headerRef = React.useRef(null);
    const expansionPanelVisibility = props.expandVisibility;
    const getDataList = () => {
        let dataList = {}
        // if (dynamicSelector.data.element[props.active_step]) {
        if (props.dataStore) {
            if (props.dataStore[props.data[0]]) {
                dataList = props.dataStore[props.data[0]]
            }
        }
        return dataList
    }

    const categoryList = props.optionsData ? props.optionsData : getDataList()
    const updateCheckbox = (event, device_name, product, product_list) => {
        let productcheck = {...product}
        productcheck.checked = event.target.checked ? true : false
        let productChanges = product_list && product_list.map((item) => item.id === product.id ? productcheck : item)
        dispatch(setProp({ slice: "dynamic", key: `element.${props.active_step}.${[props.data[0]]}.${[device_name]}`, value: productChanges }));

    }

    const selectedProduct = (device_name, product) => {
        let check = false;
        
        // if (dynamicSelector.data.element[props.active_step]) {
        if (props.dataStore) {
            if (props.dataStore[props.data[0]]) {
                if (props.dataStore[props.data[0]][device_name]) {
                    let checkProduct = props.dataStore[props.data[0]][device_name].find((item) => item.checked && item.id === product.id ? item : null)
                    check = checkProduct ? true : false
                }
            }
        }
        return check
    }

    const getReturnValue = (device, product) => {
        let value = 1
        if (props.dataStore) {
            if (props.dataStore[props.data[0]] && props.dataStore[props.data[0]][device]) {
                let returnvalue = props.dataStore[props.data[0]][device].find(item => item.id === product.id)
                if(returnvalue){
                    value = returnvalue.quantity
                }
            }
        }
        return value
    }

    const incrDecrValue = (device_name, product) => {
        let path = ''
        if (props.dataStore) {
            if (props.dataStore[props.data[0]] && props.dataStore[props.data[0]][device_name]) {
                let index = props.dataStore[props.data[0]][device_name].findIndex(item => item.id === product.id)
                let indexData = props.dataStore[props.data[0]][device_name][index]
                if(indexData){
                    path = props.dataStore[props.data[0]][device_name][index]['quantity']
                }
            }
        }
        return path
    }

    const getKeyvalueData = (device_name, product, value) => {
        let productquantity = {...product}
        productquantity.quantity = value
        productquantity.checked = true
        let productquantityChanges = props.dataStore[props.data[0]][device_name].map((item) => item.id === product.id ? productquantity : item)
        dispatch(setProp({ slice: "dynamic", key: `element.${props.active_step}.${[props.data[0]]}.${[device_name]}`, value: productquantityChanges }));
    }

    const handleExpansion = id => (e) => {
        const newId = expanded === id ? undefined : id;
        if (e.type === 'keydown') {
            if (
                e.target.getAttribute('data-test') === 'header' && // check if keydown from header
                (e.key === ' ' || e.key === 'Enter')
            ) {
                setExpanded(newId);
            }
        }
        if (e.type === 'click') {
            setExpanded(newId);
        }
    };

    const loadDevices = (rD) => {
        return (
            <ul>
                { rD.ruggedization && <li style={{ listStyle: 'disc' }}>Ruggedization: {rD.ruggedization}</li>}
                {rD.certification && <li style={{ listStyle: 'disc' }}>
                    Certification: 
                        <ul>
                        lte-fdd:<li>{rD.certification.ce_certified && (rD.certification.ce_certified['lte-fdd']).length > 0 && (rD.certification.ce_certified['lte-fdd']).toString()}</li>
                        lte-tdd:<li>{rD.certification.ce_certified && (rD.certification.ce_certified['lte-tdd']).length > 0 && (rD.certification.ce_certified['lte-tdd']).toString()}</li>
                        nr:<li>{rD.certification.ce_certified && (rD.certification.ce_certified['nr']).length > 0 && (rD.certification.ce_certified['nr']).toString()}</li>
                        </ul>
                </li>}
                {rD.sim_type && <li style={{ listStyle: 'disc' }}>SIM type: {(rD.sim_type.data).toString()}</li>}
                {rD.wifi && <li style={{ listStyle: 'disc' }}>Wifi: {(rD.wifi.data).toString()}</li>}
                {rD.gps && <li style={{ listStyle: 'disc' }}>GPS: {(rD.gps.data).toString()}</li>}
                {rD.vpn_capability && <li style={{ listStyle: 'disc' }}>VPN Capability: {rD.vpn_capability} </li>}
                {rD.rbue && <li style={{ listStyle: 'disc' }}>RBUE Supported: {rD.rbue}</li> }
                {rD.nidm_iot && <li style={{ listStyle: 'disc' }}>NIDM-IOT Supported: {rD.nidm_iot}</li>}
                {rD.nidm_emm && <li style={{ listStyle: 'disc' }}>NIDM-EMM Supported: {rD.nidm_emm}</li>}
                {rD.ndt && <li style={{ listStyle: 'disc' }}>NDT Supported: {rD.ndt}</li>}
            </ul>
        )
    }

    const loadKitContents = (rD) => {
        return (
            <ul style={{width:'100%'}}>
                {
                (rD.product_kits).map((kit, index) => (
                    <li style={{ listStyle: 'disc'}} key={index}>
                        <div style={{display:'flex',justifyContent:'space-between' }}>
                            <span>
                                {kit.product_kit_description} 
                                <br></br>{kit.product_kit_code}
                            </span>
                            <span style={{marginLeft:'5%'}}>Quantity : {kit.product_kit_quantity}</span>
                        </div>
                    </li>
                ))}
            </ul>
        )
    }

    return (
        <>
        {Object.entries(categoryList).length > 0 ?
            Object.entries(categoryList).map((data, _index) => {
                return (
                    <>
                        <div style={{ marginTop: '10px', display: 'flex'}}>
                            <TextInputLabelContent>
                                {data[0]}
                            </TextInputLabelContent>
                            {showHelpText(props.data)}
                        </div>
                        <HorizontalDivider className='mb mt' />
                            { 
                            expansionPanelVisibility ?
                            (
                                <ExpansionPanelsContainer key={data[0]} style={{ marginBottom: '25px' }}>
                                        {categoryList[data[0]].map(rD => (
                                            <ExpansionPanel
                                                expanded={expanded === rD.id}
                                                key={rD.id}
                                                id={rD.id}
                                                hoverEffect
                                                focusEffect
                                                mountContent={false}
                                            >
                                                <ExpansionPanelHeader
                                                    data-test="header"
                                                    ref={headerRef}
                                                    role="button"
                                                    aria-expanded={expanded === rD.id}
                                                    style={{ justifyContent: 'space-between' }}

                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '25.75rem' }}>
                                                        <Checkbox
                                                            style={{ top: '10px' }}
                                                            checked={selectedProduct(data[0], rD)}
                                                            disabled={props.isDisabled}
                                                            onChange={(e) => {
                                                                if (e.type === "change") {
                                                                    updateCheckbox(e, data[0], rD, props.dataStore && props.dataStore[props.data[0]] ? props.dataStore[props.data[0]][data[0]] : data[1])
                                                                    return
                                                                }
                                                            }}
                                                            key={rD.id}
                                                        />
                                                        <Label style={{ marginLeft: "0px" }}>{rD.product_description}<br></br>{rD.product_code}</Label>
                                                    </div>
                                                    <ExpansionPanelButton
                                                        onClick={handleExpansion(rD.id)}
                                                        iconButtonProps={{
                                                            'aria-hidden': true,
                                                        }}
                                                    />
                                                    <Spinner
                                                        variant='outlined'
                                                        id={rD.id}
                                                        value={getReturnValue(data[0], rD)}
                                                        disabled={props.isDisabled ? true : !selectedProduct(data[0], rD)}
                                                        min={1}
                                                        max={100000}
                                                        step={1}
                                                        inputMode='numeric'
                                                        incrementButtonProps={{ 'aria-label': `Value ${props.dataStore ? incrDecrValue(data[0], rD) : 1}. Increment` }}
                                                        decrementButtonProps={{ 'aria-label': `Value ${props.dataStore ? incrDecrValue(data[0], rD) : 1}. Decrement` }}
                                                        onChange={(e) => {
                                                            getKeyvalueData(data[0], rD, e.value);
                                                        }}
                                                    />
                                                </ExpansionPanelHeader>

                                                <ExpansionPanelBody>
                                                    <div style={{ display: 'flex', justifyContent: 'left', padding: PADDING }}>
                                                        <div style={{ width: 'auto' }}>
                                                            <p>{rD.title}</p>
                                                            {
                                                                rD ? (
                                                                    rD['product_kits'] ? loadKitContents(rD) : loadDevices(rD)
                                                                )
                                                                : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </ExpansionPanelBody>
                                            </ExpansionPanel>
                                        ))}
                                </ExpansionPanelsContainer>
                            ) : (
                                <div style={{maxHeight:'200px',overflowY:'auto'}}>
                                {categoryList[data[0]].map((rD,i) => (
                                    <>
                                    <div style={{ display: 'flex',justifyContent: 'space-between'}} key={i}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '25.75rem' }}>
                                            <Checkbox
                                                style={{ top: '10px' }}
                                                checked={selectedProduct(data[0], rD)}
                                                disabled={false}
                                                onChange={(e) => {
                                                    if (e.type === "change") {
                                                        updateCheckbox(e, data[0], rD, props.dataStore  ? props.dataStore[props.data[0]][data[0]] : data[1])
                                                        return
                                                    }
                                                }}
                                                key={i}
                                            />
                                            <Label style={{ marginLeft: "0px" }}>{rD.product_description}<br></br>{rD.product_code}</Label>
                                        </div>
                                        <Spinner
                                            variant='outlined'
                                            id={rD.id}
                                            value={getReturnValue(data[0], rD)}
                                            disabled={!selectedProduct(data[0], rD)}
                                            min={1}
                                            max={100000}
                                            step={1}
                                            inputMode='numeric'
                                            incrementButtonProps={{ 'aria-label': `Value ${props.dataStore ? incrDecrValue(data[0], rD) : 1}. Increment` }}
                                            decrementButtonProps={{ 'aria-label': `Value ${props.dataStore ? incrDecrValue(data[0], rD) : 1}. Decrement` }}
                                            onChange={(e) => {
                                                getKeyvalueData(data[0], rD, e.value);
                                            }}
                                        />
                                    </div>
                                    <HorizontalDivider className='mb mt' /></>
                                    ))}
                                </div>
                                )
                            }

                    </>
                )
            })
            
            : <>{emptyMessage}</>
        }
        </> 
    )

}



export default Multicheckboxs;