import {
  Button,
  InlineFeedbackMessage,
  Label,
  RadioButton,
  TextArea,
  Typography
} from "@nokia-csf-uxr/ccfk";
import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import { SimpleCard } from "@nokia-csf-uxr/ccfk/Card";
import { CoachmarkFooter } from "@nokia-csf-uxr/ccfk/Coachmarks";
import { RadioButtonLabelContent } from "@nokia-csf-uxr/ccfk/RadioButton";
import {
  TextInputLabelContent
} from "@nokia-csf-uxr/ccfk/TextInput";
import ToggleSwitch, {
  ToggleSwitchLabelContent
} from "@nokia-csf-uxr/ccfk/ToggleSwitch";
import { EmptyState } from "Components/Content/EmptyState";
import SliderComponent from 'Components/Elements/Slider';
import {
  useRenderState
} from "Components/Logics/ensoCommonFunctions";
// import {
//   useRenderState,
//   useValidate
// } from "Components/Logics/ensoCommonFunctions";
import ToggleDisabledIcon from '@nokia-csf-uxr/ccfk-assets/latest/ToggleDisabledIcon';
import ToggleIcon from '@nokia-csf-uxr/ccfk-assets/latest/ToggleIcon';
import {
  fetchAccountData,
  handleExceptionR6InternalLogic,
  handleOffer,
  handleSalesChannelLogic
} from "Components/Logics/systemLogics";
import { Validator } from "Components/Logics/validations";
import {
  STATUS,
  caseCategory,
  sCRMRegex
} from "Components/Misc/CaseDetails_System";
import { systemStyle } from "Constants/StyleLayer";
import ErrorSlash from "Constants/errorHandler";
import OOT from "Constants/services/oot";
import { setProp } from "Store/Actions";
import { setError, setendCustomer, showModal } from "Store/auth";
import {
  setData
} from "Store/contentState/caseDetailsSlice";
import { RootState, store } from "Store/mainStore";
import { getEmptyOOTfields } from "Utils";
import React, { Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CaseDetailsOptions from "./CaseDetailsOptions";
import { ToolSelection } from "./ToolSelection";
import { commericalModel } from "./configurationArea/Business/system";

const CaseDetails = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
  const findCaseSelector = useSelector((state: RootState) => state.findCase);
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const businessSelector = useSelector((state: RootState) => state.business);
  // const contract_duration = caseDetailsSelector.data.contract_duration;
  const validator = useMemo(() => {
    const v = new Validator("caseDetails")
    return v.validate().report
  }, [caseDetailsSelector.data])
  
  const loadedTool = authSelector.OOT.status === STATUS.IDLE && authSelector.OOT?.data.hasOwnProperty('case_name') && authSelector.OOT?.data.case_name //since oot doesn't send casename for invalid scrm
    ? "OOT"
    : "None";
  const hideSalesChannel = useMemo(() => {
    return handleExceptionR6InternalLogic(state);
  }, [findCaseSelector]);
  const getEndCustomerByAccount = async (account_nid) => {
    try {
      var endCust = await OOT.getCustomer({ account_nid: account_nid });
      if (!endCust.status) {
        if (endCust.errorCode === 401) {
          dispatch(
            setError({
              key: "error",
              value: {
                errorCode: null,
                errorMsg: "Token Expired! Please re-login to continue",
              },
            })
          );
        }
      } else if (Object.keys(endCust.data).length === 0) {
        dispatch(showModal({ msg: endCust.errorMessage, show: true, variant: "ERROR", title: "Unable to get customers" }))
      } else {
        const customersList = endCust.data.customers;
        const endCustomerList = customersList.map(
          (z) => new Object({ id: z.nid, value: z.name, "3_star_number": z['3_star_number'] }));
        (loadedTool === "OOT") ? dispatch(setendCustomer([...authSelector.system.endCustomer, ...endCustomerList])) : dispatch(setendCustomer([...endCustomerList]));
        var clearECBtn: HTMLButtonElement = document.querySelector("#ClearButton_endCustomer");
        if (endCustomerList.length == 1) {
          //AutoSet the endCustomer on UI
          dispatch(setProp({ key: "endCustomer", value: endCustomerList[0].id, slice: "caseDetails" }))
        } else if (clearECBtn && clearECBtn !== null) {
          setTimeout(() => {
            if (clearECBtn) {
              clearECBtn.click();

            }
          }, 2000);
        } else {
        }
        if (
          authSelector.OOT.status === STATUS.IDLE ||
          !sCRMRegex.test(findCaseSelector.data.sCRMID)
        ) {

        }
      }
      return endCust;

    } catch (error) {
      return authSelector.system.endCustomer;
    }
  };

  useEffect(() => {
    // For Case Type "New" and Sub Type "Perimeter Network" we need to limit Commercial model option to "Capex" Only
    // Make the the default selection to Capex
    if(findCaseSelector.data.caseType === "new" && findCaseSelector.data.subType === "perimeter_nw"){
      dispatch(
        setData({
          key: "contract_duration",
          value: 1,
        })
      );
      dispatch(
        setData({
          key: "commercial_model",
          value: "capex",
        }));
    }
  }, [findCaseSelector.data.caseType,findCaseSelector.data.subType]);


  useEffect(() => {
    let isCancelled = false;
    function fetchCustomer() {
      if (
        loadedTool === "None" &&
        caseDetailsSelector.data.sales_channel !== ""
        // && caseDetailsSelector.data.sales_channel !== "DIR"
      ) {
        getEndCustomerByAccount(caseDetailsSelector.data.account).then((e) => {
          if (!isCancelled) {

          }

        });
      }
      /*DECREPTED - 2/12024 */
      // else if (caseDetailsSelector.data.sales_channel === "DIR") {
      //   /*
      //      This will make sure the option list for account and endcustomer are same ,
      //      so Selectbar can assign them same selected value,
      //      since both contain same id in casedetail selector
      //   */
      //   dispatch(setendCustomer(authSelector.system.account));
      // }
      /*DECREPTED - 2/12024 */
    }
    fetchCustomer();
    
    return () => {
      // cancel the subscription
      isCancelled = true;
    };
  }, [caseDetailsSelector.data.account]);
  useEffect(() => {
    handleSalesChannelLogic(store.getState());
  }, [caseDetailsSelector.data.sales_channel])
  // const isFormValid = useValidate();
  const navigate = useNavigate();
  const handleConfiguring = () => {
    //Don't allow to Start Configuring until account id is set, since account_id is needed for All 8 steps of PTDAC
    let account_id = authSelector.system.account.find((e) => e.id === caseDetailsSelector.data.account)?.account_id;
    if (!account_id || findCaseSelector.data.subType !== "help") {
      // dispatch(showModal({ msg: "Account Id doesn't exist for this Account", title: "Invalid Account", show: true, variant: "ERROR" }));
      dispatch(setData({ key: "account_id", value: "" }))
      // return;
    }
    dispatch(setData({ key: "account_id", value: account_id }));
    fetchAccountData(state);
    navigate("/segment");
  }
  const selectedFields = useMemo(() => getEmptyOOTfields(authSelector.OOT.data), [authSelector.OOT.data]);

  return (
    <div>
      <div className="caseDetailsSlice"
        hidden={!useRenderState("slice", "caseDetailsSlice")}
      >
        <div style={systemStyle.genrateGridCols(2)} className="mt">
          <div
            id="caseDetails"
            style={{
              display: useRenderState("form", "caseDetails") ? "block" : "none",
            }}
          >
            <SimpleCard
              className={`p ${loadedTool === "OOT" ? "readonly" : ""}`}
              hoverEffect={false}
              style={{ height: '100%' }}
              focusEffect={false}
            >
              <Typography typography="TITLE_16">Case Details</Typography>
              <InlineFeedbackMessage show={findCaseSelector.data.sCRMID.length === 0} variant="error">
                {findCaseSelector.data.sCRMID.length === 0 && (<Typography>sCRM ID does not exist, please check or fill the details</Typography>)}

              </InlineFeedbackMessage>
              <InlineFeedbackMessage show={!validator['success']} variant="warning">
                {
                  //@ts-ignore
                  (!validator['success']) ? (<Typography>Please add valid values for all the highlighted fields in Case Details section</Typography>) : (<></>)
                }
              </InlineFeedbackMessage>
              <div className="mt" style={systemStyle.genrateGridCols(2)}>
                <div id="left">
                  <CaseDetailsOptions selectedFields={selectedFields} />
                </div>
                <div id="right">
                  <CaseDetailsOptions selectedFields={selectedFields} />
                </div>
              </div>

              <div className="w-100" style={systemStyle.genrateGridCols(2)} id="">
                <div
                  id="summary"
                  className="mb"
                  hidden={!useRenderState("form", "summary") || loadedTool === "OOT"}
                >
                  <Label>
                    <TextInputLabelContent>Case summary</TextInputLabelContent>
                  </Label>
                  <TextArea
                    value={caseDetailsSelector.data.summary}
                    placeholder="Offer summary"
                    onChange={(e) =>
                      dispatch(
                        setData({ key: "summary", value: e.target.value })
                      )
                    }
                  ></TextArea>
                </div>
                <div
                  id="co_owners"
                  className="mb"
                  hidden={!useRenderState("form", "co_owners") || loadedTool === "OOT"}
                >
                  <Label>
                    <TextInputLabelContent>Co-owners</TextInputLabelContent>
                  </Label>
                  <TextArea
                    value={caseDetailsSelector.data.co_owner}
                    placeholder="Enter email comma - separated"
                    onChange={(e) =>
                      dispatch(
                        setData({ key: "co_owner", value: e.target.value })
                      )
                    }
                  ></TextArea>
                </div>
              </div>
            </SimpleCard>
          </div>
          <div id="configureCase">
            <SimpleCard
              className="p"
              hoverEffect={false}
              // style={{ minHeight: "580px" }}
              style={{ height: '100%' }}
              focusEffect={false}
            >
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <div id="configurecase-header">
                  <Typography typography="TITLE_16">Pre-requisite</Typography>
                  <div
                    className="mt mb"
                    id="caseCategory"
                    hidden={!useRenderState("form", "caseCategory")}
                  >
                    <Label
                      htmlFor="selectitem-component-input"
                      maxWidth={true}
                      variant="vertical-layout"
                      hidden={!useRenderState("form", "caseCategory")}
                    >
                      <TextInputLabelContent>Case category</TextInputLabelContent>
                      <ErrorSlash>
                        <div
                          className="mt"
                          style={{
                            display: "flex",
                            width: "50%",
                            justifyContent: "flex-start",
                          }}
                        >
                          {Object.values(caseCategory).map((caseCat: string, i) => {
                            return (
                              <Fragment key={i}>
                                <RadioButton
                                  key={i}
                                  disabled={
                                    !sCRMRegex.test(findCaseSelector.data.sCRMID) ||
                                    findCaseSelector.data.sCRMID.length === 0 ||
                                    !authSelector.OOT.data?.case_name
                                  }
                                  onChange={(e) =>
                                    dispatch(
                                      setData({
                                        key: "caseCategory",
                                        value: caseCat,
                                      })
                                    )
                                  }
                                  checked={
                                    caseDetailsSelector.data.caseCategory ===
                                    caseCat
                                  }
                                />
                                <RadioButtonLabelContent>

                                  {caseCat}

                                </RadioButtonLabelContent>
                              </Fragment>
                            );
                          })}
                        </div>
                      </ErrorSlash>
                    </Label>
                  </div>
                  <div
                    id="commerical-model"

                  >
                    <Label maxWidth={true}
                      variant="vertical-layout"
                      hidden={!useRenderState("form", "commercial_model")}>
                      <TextInputLabelContent>Commercial Model *</TextInputLabelContent>
                      <div
                        className='ml'
                        style={{
                          display: "flex",
                          width: "50%",
                        }}
                      //caseDetailsSelector.data.commercial_model
                      >
                        {commericalModel.allIds.map((option, i) => {
                          // For Case Type "New" and Sub Type "Perimeter Network" we need to limit Commercial model option to "Capex" Only
                          if(findCaseSelector.data.caseType === "new" && findCaseSelector.data.subType === "perimeter_nw"){
                            if(option === "capex"){
                              return (
                                <Fragment key={i}>
                                  <RadioButton
                                    key={i}
                                    // onChange={(e) => {}}
                                    checked={true} // Selected by default
                                  />
                                  <RadioButtonLabelContent>
                                    {commericalModel.byIds[option].value}
                                  </RadioButtonLabelContent>
    
                                </Fragment>
                              );                              
                            }
                            else{
                              return <></>
                            }
                          }
                          else{
                            return (
                              <Fragment key={i}>
                                <RadioButton
                                  key={i}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      dispatch(
                                        setData({
                                          key: "contract_duration",
                                          value: 1,
                                        })
                                      )
                                      dispatch(
                                        setData({
                                          key: "commercial_model",
                                          value: option,
                                        })
                                        // dispatch(setProp({ slice: "business", key: "view.PTDAC.contract_duration", value: 1 }));
                                      )
                                    }
                                    // dispatch(setProp({ slice: "business", key: "view.PTDAC.commercial_model", value: "" }));
                                  }}
                                  checked={caseDetailsSelector.data.commercial_model === option} //TODO : Make is general.
                                />
                                <RadioButtonLabelContent>
                                  {commericalModel.byIds[option].value}
                                </RadioButtonLabelContent>
  
                              </Fragment>
                            );
                          }
                        })}
                      </div>
                    </Label>


                  </div>
                  <div id='contract-duration'
                    className="mt mb"

                    hidden={!useRenderState("form", "contract_duration")}
                  >
                    <Label >Contract Duration ({(commericalModel.byIds[caseDetailsSelector.data.commercial_model].data.duration)})</Label>
                    <SliderComponent
                      maxDuration={(commericalModel.byIds[caseDetailsSelector.data.commercial_model].data.max).toString()}
                      sliderStateValue={caseDetailsSelector.data.contract_duration}
                      handleChange={(e) => {
                        dispatch(
                          setData({
                            key: "contract_duration",
                            value: e.value,
                          })
                        )
                        //dispatch(setProp({ slice: "business", key: "view.PTDAC.contract_duration", value: e.value }))
                      }}

                    />
                  </div>
                  <div
                    className="mt"
                    id="extensionTool"
                    style={{
                      display: useRenderState("form", "extensionTool")
                        ? "block"
                        : "none",
                    }}
                  >
                    <ToolSelection />
                  </div>
                  <div
                    className="mt mb"
                    id="public_funding_required"
                    style={{
                      display: useRenderState("form", "public_funding_required")
                        ? "block"
                        : "none",
                    }}
                  >
                    <Label htmlFor="selectitem-component-input" maxWidth>
                      <ToggleSwitch
                        variant="plain"
                        checked={caseDetailsSelector.data.public_funding_required}
                        toggleIcon={caseDetailsSelector.data.public_funding_required ? <ToggleIcon /> : <ToggleDisabledIcon />}
                        onChange={(e) => {
                          dispatch(
                            setData({
                              key: "public_funding_required",
                              value: e.value,
                            })
                          );
                        }}
                      />
                      <ToggleSwitchLabelContent>
                        {/* <TextInputLabelContent> */}
                        Direct Nokia funding required ?
                        {/* </TextInputLabelContent> */}
                      </ToggleSwitchLabelContent>
                    </Label>
                  </div>




                </div>
                <div id="configurecase-footer">
                  <CoachmarkFooter>
                    <InlineFeedbackMessage show={true} variant="warning" >
                      Please Note: Radio Network Plan needs to be imported in case connectivity is included in the case.
                    </InlineFeedbackMessage>


                  </CoachmarkFooter>
                </div>
              </div>

            </SimpleCard>
          </div>
        </div>
      </div>
      <div id="footer" className="" >
        <CoachmarkFooter>
          {
            (findCaseSelector.data.subType === "help") ? (<>
              <Button
                onClick={() => {
                  handleConfiguring()


                }
                }
                variant="call-to-action"
                aria-label="Start Configuring"
                disabled={authSelector.OOT.status === STATUS.LOADING || !(validator['success'])}
              >
                <ButtonText>Start Configuring</ButtonText>
              </Button>
            </>) : (<>
              <Button
                variant="call-to-action"
                disabled={!(validator['success'])}
                onClick={() => {
                  handleOffer(state)

                }}
              >
                <ButtonText>Submit</ButtonText>
              </Button>
            </>)
          }
        </CoachmarkFooter>
      </div>
      <div id="redirectCaseWindow"
        style={{
          display: useRenderState("form", "redirectCaseWindow")
            ? "block"
            : "none",
        }}
      >
        <EmptyState msg="This case type will be directly supported by external tools, click continue to proceed." />
      </div>
    </div>
  );
};
export default CaseDetails;
