import React from "react";
import { Button, Chip, IconButton, Tooltip } from "@nokia-csf-uxr/ccfk";
import { ChipLabel } from "@nokia-csf-uxr/ccfk/Chip";
import AntennaManagementIcon from "@nokia-csf-uxr/ccfk-assets/legacy/AntennaManagementIcon";
import EditIcon from "@nokia-csf-uxr/ccfk-assets/latest/EditIcon";
import DeleteIcon from "@nokia-csf-uxr/ccfk-assets/latest/DeleteIcon";
import { ButtonIcon } from "@nokia-csf-uxr/ccfk/IconButton";
import AddIcon from "@nokia-csf-uxr/ccfk-assets/legacy/AddIcon";
import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";

interface IRrhConfigChips {
  configChips: Object;
  isConfigured: boolean;
  maxConfigCount:number;
  isUndoButtonEnabled:boolean;
  onEditConfiguration: Function;
  onDeleteConfiguration: Function;
  onAddMoreConfiguration: Function;
}
const RrhConfigChips = ({
  configChips,
  maxConfigCount,
  isConfigured,
  isUndoButtonEnabled,
  onEditConfiguration,
  onDeleteConfiguration,
  onAddMoreConfiguration,
}: IRrhConfigChips) => {
  
  /** addNewConfigHandler */
  const addNewConfigHandler = () => {
    onAddMoreConfiguration();
  };
  
  return (
    <>
      {Object.keys(configChips).map((chipKey) => (
        <Chip
          key={`config-chip-${chipKey}`}
          rippleProps={{ disabled: false }}
          chipStyle={{
            background: `${
              configChips[chipKey].status === "done" ? "#ebf7ef" : ""
            }`,
            border: "solid 1px #ddd",
            margin: "10px 2px",
          }}
        >
          <AntennaManagementIcon />
          <ChipLabel label={chipKey} />
          {configChips[chipKey].status === "done" && (
            <div style={{ display: "flex", gap: "5px", margin: "0 5px" }}>
              <Tooltip placement="bottom" tooltip={isUndoButtonEnabled ? "Disabled edit option" : `Edit ${chipKey}}`}>
                <IconButton
                  size="small"
                  disabled={isUndoButtonEnabled}
                  onClick={() => onEditConfiguration(chipKey)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip placement="bottom" tooltip={isUndoButtonEnabled ? "Disabled delete option" : `Delete ${chipKey}`}>
                <IconButton
                  size="small"
                  disabled={isUndoButtonEnabled}
                  onClick={() => onDeleteConfiguration(chipKey)}
                >
                  <DeleteIcon color="red" />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </Chip>
      ))}
      {isConfigured && Object.keys(configChips).length < maxConfigCount && !isUndoButtonEnabled && (
        <div style={{ width: "100%", margin: "10px 0" }}>
          <Tooltip placement="bottom" tooltip={`Add more configuration`}>
            <Button variant="neutral" onClick={addNewConfigHandler}>
              <ButtonIcon>
                <AddIcon />
              </ButtonIcon>
              <ButtonText>Add more configuration</ButtonText>
            </Button>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default RrhConfigChips;
