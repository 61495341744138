import React, { ReactNode } from 'react';
interface MyProps {
    children: ReactNode,
}
const Frame: React.FunctionComponent<MyProps> = (props) => {
  return (
      <div style={{ display: "flex", alignItems: "center", gap: "1rem", }}>
            {props.children}
    </div>
  )
}

export default Frame