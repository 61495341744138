import { z } from "zod";
const homeSchema = z.object({
    caseName: z.string(),
    caseCategory: z.string(),
    caseIndustry: z.string(), // FIXME: Remove this as added in segmentSlice
    country: z.string().length(2), //n/w loc country
    currency: z.string().length(3),
    account: z.number().or(z.string()),
    account_id: z.string(),
    distributor: z.string().or(z.number()), //TODO: Make it to single type : Reason : on empty scrm -> number | or valid scrm we get type ->string
    indirect_resellers: z.string().or(z.number()),
    resellers: z.number().or(z.string()),
    shipping_country: z.string().length(2),
    sales_channel: z.string(),
    commercial_model: z.string(),
    market: z.string(),
    endCustomer: z.number().or(z.string()),
    summary: z.string(),
    co_owner: z.string().optional(),
    public_funding_required: z.boolean(),
    additionalFeature: z.array(z.number()), // FIXME: Remove this as added in segmentSlice
    contract_duration: z.number(), // default cannot be 0 as if(0) fails when checking isFormValid
    network_id: z.string()
})
export default homeSchema;