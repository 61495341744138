export const productsServices = {
    allIds: ["pj_mgmt", "tech_mgmt", "ebm", "cpm", "pmo", "handover", "hse_mgmt", "log_cost", "care_pm", "qa_mgmt"],
    byService: [
        { service: 'Project Management', price: 5260, id: "pj_mgmt" },
        { service: 'Technical Project Management', price: 9468, id: "tech_mgmt" },
        { service: 'EBM (Enterprise Business Manager)', price: 2367, id: "ebm" },
        { service: 'CPM (Cost & Progress Manager)', price: 2367, id: "cpm" },
        { service: 'Project Management Office', price: 1315, id: "pmo" },
        { service: 'Handover (Deliver to support/operations)', price: 263, id: "handover" },
        { service: 'HSE Management', price: 263, id: "hse_mgmt" },
        { service: 'Logistic cost', price: 263, id: "log_cost" },
        { service: 'Care PM', price: 263, id: "care_pm" },
        { service: 'Quality Management', price: '263', id: "qa_mgmt" }
    ]
}
export const userSelectionsFields = {
    allIds: ["subcon_implmentation", "travelcost", "sitesurvey", "no_of_installation_locations", "PM_dur_months", "HSE_Value"],
    byIds: {
        subcon_implmentation: { label: "Implementation Service - Subcon Cost", type: "input" },//Implementation Service - Subcon Cost,
        travelcost: { label: "Implementation Service - Subcon Travel Cost", type: "input" },//Implementation Service - Subcon Travel Cost,
        sitesurvey: { label: "Site Survey - Subcon Cost", type: "input" },//Site Survey - Subcon Cost
        no_of_installation_locations: { default: 1, label: "# of installation locations/sites", type: "select", options: Array.from({ length: 10 }, (_, i) => new Object({ id: i + 1, value: i + 1 })) }, //|Default: 1
        PM_dur_months: { label: "Project Duration (Months)", type: "input" },//Project Duration - replace filler {{pmdurmonths }},if this exisits.
        HSE_Value: { default: "Low", label: "Health & Safety Management", type: "select", options: [{ id: "Low", value: "Low" }, { id: "Medium", value: "Medium" }, { id: "High", value: "High" }] },///Health & Safety Management | Default "Low", - based on selection,align with filler
        Nokia_DAC_Aware: { label: "Nokia DAC Aware", type: "input" },
        Training_Service_on_site_ : { label: "Training Service (on-site)", type: "input" },
        Nokia_DAC_Installer : { label: "Nokia DAC Installer", type: "input" },
        Nokia_DAC_Operator: { label: "Nokia DAC Operator", type: "input" },
        Training_Service_remote_ : { label: "Training Service (remote)", type: "input" },
        Nokia_DAC_Edge_Networking: { label: "Nokia DAC Edge Networking", type: "input" },
        NDAC_Sub_con_Training_Physical_Install: { label: "NDAC Sub-con Training", type: "input" }
    },

}
export const ndacServices = {
    allIds: ["imp_mgr", "sub_mgmt", "sub_imp", "remote"],
    byService: [
        { service: 'Implementation Manager', price: 23, id: "imp_mgr" },
        { service: 'Subcon Management', price: 32, id: "sub_mgmt" },
        { service: 'Subcon Cost - Implementation,Walk test', price: 3, id: "sub_imp" },
        { service: 'Travel Cost', price: 3 },
        { service: 'Remote Integration', price: 3, id: "remote" }
    ]
}

export const ndacPreServices = {
    allIds: ["imp_mgr", "sub_mgmt", "sub_imp", "remote"],
    byService: [
        { service: 'Implementation Manager', price: 23, id: "imp_mgr" },
    ]
}