import { App, Typography } from "@nokia-csf-uxr/ccfk";
import SignIn, {
  SignInBackground,
  SignInBody,
  SignInContent,
  SignInFooter,
  SignInHeader,
  SignInLogo
} from "@nokia-csf-uxr/ccfk/SignIn";

import { Body } from "Components/Body";
import { SignInButton } from "Components/SignInButton";
import { systemStyle } from "Constants/StyleLayer";
import pkg from "Utils/package.json";
import React from "react";
export const Login = () => {
  return (
    <>
   
      <Body
        
          crumbs={null}
          bodyContent={
            <>
              <SignIn>
                <SignInContent>
                  <SignInHeader>
                    <SignInLogo aria-label="Nokia logo" />
                  </SignInHeader>
                  <SignInBody>
                    <div style={systemStyle.genrateGridRows(2)}>
                      <div id="title" className="mb" >
                        <Typography typography={"TITLE_24"}>
                          Digital Automation for Campus Offer Tool
                        </Typography>
                        <Typography typography={"MICRO"}>Version {pkg.version}</Typography>
                      </div>
                      <SignInButton />
                    </div>
                  </SignInBody>
                  <SignInFooter>
                    <Typography typography="CAPTION">
                      <span style={{ marginRight: "0.125rem" }}>
                        © {new Date().getFullYear()} Nokia
                      </span>
                    </Typography>
                  </SignInFooter>
                </SignInContent>
                <SignInBackground />
              </SignIn>
            </>
          }
        />
   

    </>
  );
};
