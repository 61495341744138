import React from "react";
import ExpansionPanelsContainer, {
    ExpansionPanel,
    ExpansionPanelTitle,
    ExpansionPanelHeader,
    ExpansionPanelBody,
    ExpansionPanelButton
} from '@nokia-csf-uxr/ccfk/ExpansionPanels';
import TOKENS from '@nokia-csf-uxr/nokia-design-system-tokens/js/tokens.es6';
import { Label } from '@nokia-csf-uxr/ccfk';
import DOMPurify from 'dompurify';
import InfoCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/InfoCircleFillIcon';

interface DataList {
  data: Array<any>;
  active_step: string;
  updateDispatchStore?: Function;
  isDisabled: boolean;
  errorMessageText?: Function;
  ref: object;
}
const PADDING = `${TOKENS.SPACING_MEDIUM} ${TOKENS.SPACING_LARGE}`;

const getSanitizedHtml = (html) => {
    //sanitize the html not to allow dangerous elements
    return DOMPurify.sanitize(html,{ALLOWED_TAGS: ['p', 'a','b'], ALLOWED_ATTR: ['href', 'target','rel']});
};

const MarkupField = (props: DataList) => {
    const label = props.data[1].label ? props.data[1].label : "Sample Text"
    const markup = props.data[1].markup ? props.data[1].markup : "<h2>h2 Header</h2><br/><hr/><p>Add your text here, closing the line</p><br/><p><a href='https://www.google.com/'target='_blank' rel='noopener noreferrer'>Click here </a></p>"
    const data = props.data[1]
    const [expanded, setExpanded] = React.useState(undefined);
    const headerRef = React.useRef(null);

    const handleExpansion = id => (e) => {
        const newId = expanded === id ? undefined : id;
        if (e.type === 'keydown') {
            if (
                e.target.getAttribute('data-test') === 'header' && // check if keydown from header
                (e.key === ' ' || e.key === 'Enter')
            ) {
                setExpanded(newId);
            }
        }
        if (e.type === 'click') {
            setExpanded(newId);
        }
    };


    return (
        <>
            <div style={{ marginTop: '10px' }}>
                <ExpansionPanelsContainer key={data.id} style={{ marginBottom: '25px' }}>
                    <ExpansionPanel
                        expanded={expanded === data.id}
                        key={data.id}
                        id={data.id}
                        hoverEffect
                        focusEffect
                        mountContent={false}
                    >
                        <ExpansionPanelHeader
                            data-test="header"
                            ref={headerRef}
                            role="button"
                            aria-expanded={expanded === data.id}
                            style={{ justifyContent: 'space-between' }}

                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '25.75rem' }}>
                                <InfoCircleFillIcon style={{ marginBottom: "3px" }} size="22px"/>
                                <Label style={{ marginLeft: "5px" }}>{label}</Label>
                            </div>
                            <ExpansionPanelButton
                                onClick={handleExpansion(data.id)}
                                iconButtonProps={{
                                    'aria-hidden': true,
                                }}
                            />

                        </ExpansionPanelHeader>

                        <ExpansionPanelBody>
                            <div style={{ display: 'flex', justifyContent: 'left', padding: PADDING }}>
                                <div dangerouslySetInnerHTML={{ __html: getSanitizedHtml(markup) }} />
                            </div>
                        </ExpansionPanelBody>
                    </ExpansionPanel>

                </ExpansionPanelsContainer>

            </div>
        </>
    )
}

export default MarkupField;
