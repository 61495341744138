import { Bar } from "@nivo/bar";
import {
  HorizontalDivider,
  Label,
  RadioButton,
  Skeleton,
  ToggleButtonGroup
} from "@nokia-csf-uxr/ccfk";
import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import { RadioButtonLabelContent } from "@nokia-csf-uxr/ccfk/RadioButton";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import { ToggleGroupButton } from "@nokia-csf-uxr/ccfk/ToggleButtonGroup";
import { useQuery } from "@tanstack/react-query";
import AddCartBtn from "Components/Elements/AddCartBtn";
import Loader from "Components/Elements/Loader";
import MultiCheckBoxList from "Components/Elements/MultiCheckBoxList";
import OOT from "Constants/services/oot";
import { setProp } from "Store/Actions";
import { showModal } from "Store/auth";
import {
  addCartItem,
  prepareCart
} from "Store/contentState/cartBoqSlice";
import { setEdgeTabRules } from "Store/contentState/stateMachine";
import { RootState } from "Store/mainStore";
import { roundCount } from "Utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  connectorSwitchOptions,
  connectorTypeOptions,
  georedundancyOptions,
  powerOptions
} from "./system";
const Options = () => {
  const edgeSelector = useSelector((state: RootState) => state.edge);
  const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
  const dispatch = useDispatch();
  const slice = "edge";
  const stateMachineSelector = useSelector((state: RootState) => state.stateMachine);
  const isconnectorSwitchState = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.connectorSwitchEdgeTabLogic;
  const isswitchVaraintState = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.switchVariantEdgeTabLogic;
  const ismixeSubscriptionState = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.mIXESubscriptionTabLogic;
  // const ismxieServerState = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.mxieServerEdgeTabLogic;
  const ismxieGraphState = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.mxieConsumptionEdgeTabLogic;
  const isUserSelectionState = stateMachineSelector.PTDAC.selected?.hasOwnProperty('connectivity') && stateMachineSelector.PTDAC.selected?.['connectivity']?.userSelectionProductsConnectivityTab
  const cartBoqSelector = useSelector((state: RootState) => state.cartBoq);
  const [GR, setGR] = useState(edgeSelector.data.options.PTDAC.georedundancy && edgeSelector.data.options.PTDAC.georedundancy == "yes" ? 1 : 0);
  // const [sortedMixieServers, setSortedMixieServers] = useState([]);
  const edgeTabQuery = useQuery({
    queryKey: ['edgeTab', { account: caseDetailsSelector.data.account_id }],
    queryFn: async ({ queryKey }) => {
      //@ts-ignore
      const [_, { account }] = queryKey;
      const response = await OOT.getEdgeTab({ account: account, selector: caseDetailsSelector });
      return response;
    },
    refetchOnMount: false,
    onSuccess(res) {
      dispatch(setEdgeTabRules(res.data));
    },
  });

  useEffect(() => {
    if (ismxieGraphState?.data?.set?.mixie_server.length > 0 && cartBoqSelector.data.system.prepare.suggest.mixie_server.length === 0) {
      dispatch(
        prepareCart({
          method: "add",
          product: ismxieGraphState?.data?.set?.mixie_server[0],
          type: "suggest.mixie_server",
        })
      );
    }
  }, [ismxieGraphState?.data?.set?.mixie_server]);

  if (edgeTabQuery.isLoading) {
    return (<Loader />)
  }
  if (edgeTabQuery.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div id="Connector-switch" className="mb">
        {edgeTabQuery.isLoading ? (
          <Skeleton style={{ width: "250px" }} />
        ) : (
          <>
            <TextInputLabelContent>Connector Switch *</TextInputLabelContent>
            <ToggleButtonGroup>
              {connectorSwitchOptions.map((gO, i) => {
                return (
                  <ToggleGroupButton
                    key={i}
                    isSelected={
                      edgeSelector.data.options.PTDAC.connector_switch === gO.id
                    }
                    onChange={(event) => {
                      dispatch(
                        setProp({
                          slice: slice,
                          key: "options.PTDAC.connector_switch",
                          value: gO.id,
                        })
                      );
                      cartBoqSelector.data.system.prepare.suggest.mixie_server.forEach(
                        (element) => {
                          dispatch(
                            prepareCart({
                              method: "remove",
                              product: element,
                              type: "suggest.mixie_server",
                            })
                          );
                        })
                    }}
                  >
                    {" "}
                    <ButtonText>{gO.value}</ButtonText>{" "}
                  </ToggleGroupButton>
                );
              })}
            </ToggleButtonGroup>
          </>
        )}
      </div>
      <div id="Georedundancy" className="mb">
        <TextInputLabelContent>
          Georedundancy (1+1 units) *
        </TextInputLabelContent>
        <ToggleButtonGroup>
          {georedundancyOptions.map((gO, i) => {
            return (
              <ToggleGroupButton
                key={i}
                isSelected={
                  edgeSelector.data.options.PTDAC.georedundancy === gO.id
                }
                onChange={(event) => {
                  dispatch(
                    setProp({
                      slice: slice,
                      key: "options.PTDAC.georedundancy",
                      value: gO.id,
                    })
                  );
                  //@ts-ignore
                  if (event.target.innerText == "YES") {
                    setGR(roundCount(
                      stateMachineSelector.PTDAC.rules.connectivity[
                        "userSelectionProducts"
                      ].rule.fillers.conditional.default
                    ));

                    if (!isUserSelectionState?.response_code) {
                      dispatch(
                        showModal({
                          msg: "Unable to Add Geo Georedundancy to 2, since Private wireless isn't selected.",
                          title: "Unable to update Capacity Bundles",
                          show: true,
                          variant: "WARNING",
                        })
                      );
                      return;
                    }

                  } else {
                    setGR(0);
                  }
                  // remove whatever in cart after GR change
                  cartBoqSelector.data.system.prepare.suggest.mixie_server.forEach(
                    (element) => {
                      dispatch(
                        prepareCart({
                          method: "remove",
                          product: element,
                          type: "suggest.mixie_server",
                        })
                      );
                    })
                }}
              >
                <ButtonText>{gO.value}</ButtonText>{" "}
              </ToggleGroupButton>
            );
          })}
        </ToggleButtonGroup>
      </div>
      <div id="Power" className="mb">
        <TextInputLabelContent>Power</TextInputLabelContent>
        <ToggleButtonGroup>
          {powerOptions.map((gO, i) => {
            return (
              <ToggleGroupButton
                key={i}
                isSelected={edgeSelector.data.options.PTDAC.power === gO.id}
                onChange={(event) => {
                  dispatch(
                    setProp({
                      slice: slice,
                      key: "options.PTDAC.power",
                      value: gO.id,
                    })
                  );
                }}
              >
                {" "}
                <ButtonText>{gO.value}</ButtonText>{" "}
              </ToggleGroupButton>
            );
          })}
        </ToggleButtonGroup>
      </div>
      <div id="Connector-type" className="mb">
        <TextInputLabelContent>Connector Type</TextInputLabelContent>
        <ToggleButtonGroup>
          {connectorTypeOptions.map((gO, i) => {
            return (
              <ToggleGroupButton
                key={i}
                isSelected={
                  edgeSelector.data.options.PTDAC.connector_type === gO.id
                }
                onChange={(event) => {
                  dispatch(
                    setProp({
                      slice: slice,
                      key: "options.PTDAC.connector_type",
                      value: gO.id,
                    })
                  );
                }}
              >
                {" "}
                <ButtonText>{gO.value}</ButtonText>{" "}
              </ToggleGroupButton>
            );
          })}
        </ToggleButtonGroup>
      </div>
      <div
        id="mxie-server"
        className="mb"
      >
        {ismxieGraphState?.data?.set?.mixie_server && ismxieGraphState?.data?.set?.mixie_server.length > 0 ? (
          <MultiCheckBoxList title="MXIE Server *">
            {ismxieGraphState?.data?.set?.mixie_server.map((rD, i) => {
              return (
                <div
                  key={i}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Label>
                      <RadioButton
                        checked={cartBoqSelector.data.system.prepare.suggest.mixie_server.some(
                          (product) => product.product_id === rD.product_id
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            cartBoqSelector.data.system.prepare.suggest.mixie_server.forEach(
                              (element) => {
                                dispatch(
                                  prepareCart({
                                    method: "remove",
                                    product: element,
                                    type: "suggest.mixie_server",
                                  })
                                );
                              }
                            );
                            dispatch(
                              prepareCart({
                                method: "add",
                                product: rD,
                                type: "suggest.mixie_server",
                              })
                            );
                            return;
                          }

                          // dispatch(prepareCart({ method: "remove", product: rD, type: "suggest.mixie_server" }));
                        }}
                      />

                      <RadioButtonLabelContent>{`${rD.product_name}`}</RadioButtonLabelContent>
                    </Label>
                  </div>
                  <div>
                    <Label>{`${rD.list_price} ${caseDetailsSelector.data.currency}`}</Label>
                  </div>
                </div>
              );
            })}
          </MultiCheckBoxList>
        ) : (
          <>
            <MultiCheckBoxList title="MXIE Server *">
              <Skeleton style={{ width: "250px" }} />
            </MultiCheckBoxList>
          </>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <AddCartBtn
          isLoading={
            isUserSelectionState &&
            !isUserSelectionState.response_code
          }
          onAction={() => {
            const capProducts = (GR !== 0) ? (isUserSelectionState.data?.set?.userSelectionProducts) : [];
            dispatch(
              addCartItem({
                products: [
                  ...capProducts,
                  ...cartBoqSelector.data.system.prepare.suggest.mixie_server,
                  ...ismixeSubscriptionState.data.set.products
                ],
                history: true
              })
            );
            //dispatch(updateCartItem({products: isUserSelectionState.data.products,quantity: GR})); 
          }}
        />
      </div>

      <div
        id="mxie-server-consumption-chart"
        className="mb"
        hidden={!Boolean(ismxieGraphState && ismxieGraphState?.response_code)}
      >
        <TextInputLabelContent>MXIE Server Consumption</TextInputLabelContent>

        {ismxieGraphState && ismxieGraphState.response_code ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <HorizontalDivider
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <Bar
                maxValue={100}
                padding={0.2}
                margin={{
                  top: 40,
                  right: 50,
                  bottom: 40,
                  left: 50,
                }}
                innerPadding={0}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Capacity (%)",
                  legendPosition: "middle",
                  legendOffset: 30,
                }}
                indexBy={"label"}
                groupMode="stacked"
                labelTextColor={"white"}
                height={300}
                width={400}
                colors={["#005AFF"]}
                //@ts-ignore
                tooltip={(data) => {
                  return (
                    <div
                      style={{
                        background: 'white',
                        padding: '10px 15px',
                        border: `1px solid ${data.color}`,
                        borderRadius: '5px'
                      }}
                    >
                      <div>
                        <span style={{ display: 'inline-block', width: '100px' }}>Consumption</span>: {data.data.consumption}
                      </div>
                      <div>
                        <span style={{ display: 'inline-block', width: '100px' }}>Capacity</span>: {data.data.capacity}
                      </div>
                    </div>
                  )
                }}
                data={ismxieGraphState.data.set.barGraph.data}
                layout="horizontal"
              />
            </div>
            {/* <div style={{ display: 'flex', justifyContent: 'end' }}>
                <AddCartBtn
                    // isLoading={(isconnectorSwitchState && !isconnectorSwitchState.response_code) && (isswitchVaraintState && !isswitchVaraintState.response_code) && (ismixeSubscriptionState && !ismixeSubscriptionState.response_code)}
                    onAction={() => {
                        dispatch(
                            addCartItem([
                                // ...isconnectorSwitchState.data.products,
                                // ...isswitchVaraintState.data.set.products,
                                // ...ismixeSubscriptionState.data.set.products,
                                ...cartBoqSelector.data.system.prepare.suggest.mixie_server
                            ])
                        );
                    }} />
            </div> */}
          </div>
        ) : (
          <Skeleton style={{ width: "250px" }} />
        )}
      </div>
    </>
  );
};

export default Options;
