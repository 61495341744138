// @ts-nocheck
import { Typography } from '@nokia-csf-uxr/ccfk';
import { CheckCircleIcon, CloseCircleIcon } from '@nokia-csf-uxr/ccfk-assets/legacy';
import { ChipIcon, ChipLabel, InfoChip } from '@nokia-csf-uxr/ccfk/Chip';
import PropTypes from 'prop-types';
import React from 'react';
// import { subscriptionPlansOptions } from 'Components/Content/configurationArea/Warranty/system';
// import { subscriptionPlansOptions } from './configurationArea/Warranty/system';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/mainStore';
type TPlanTypes = "basic" | "pro" | "premium";
interface ISinglePlanCard {
    planID: TPlanTypes,
}
const SinglePlanCard = (props: ISinglePlanCard) => {

    const stateMachineSelector = useSelector((state: RootState) => state.stateMachine);
    const subscriptionPlansOptions = stateMachineSelector.PTDAC.selected?.warranty?.plansWarrantyTab?.data?.plans;
    const channels = subscriptionPlansOptions.byPlan[props.planID].channels;
    return (
        <>
            <div id="plan-card" style={{ width: '300px', }}>
                <div id="plan-card-header" style={{ padding: '1rem', flexDirection: 'column', display: 'flex', height: '100px', justifyContent: 'center', background: '#05A18F', }}>
                    <div ><Typography style={{ color: 'white' }} typography="TITLE_16">{subscriptionPlansOptions.byPlan[props.planID].plan}</Typography></div>
                    <div>
                        <Typography style={{ color: 'white' }} typography="HEADLINE_ALPHA" >
                            {subscriptionPlansOptions.byPlan[props.planID].cost} 
                            <Typography style={{ color: 'white', display: 'inline' }} variant="TITLE_24">
                                .00
                                {
                                    props.planID === "basic" &&
                                    <span style={{margin:"0 2px",color: "var(--legacy-color-nokia-blue-300)"}}> (already included by default) </span>
                                }                                
                            </Typography>
                        </Typography>

                    </div>
                </div>
                <div id="plan-card-body" style={{ height: '100%', padding: '1rem' }}>
                    <div id="service-desk" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div><Typography variant="LABEL">Service desk</Typography></div>
                        <div><Typography variant="HEADLINE_ALPHA">{subscriptionPlansOptions.byPlan[props.planID].duration.hours}/<Typography style={{ display: 'inline' }} variant="TITLE_24">{subscriptionPlansOptions.byPlan[props.planID].duration.days}</Typography></Typography></div>
                    </div>
                    <div id="service" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div><Typography variant="LABEL">Service request</Typography></div>
                        <div><Typography variant="HEADLINE_ALPHA">{subscriptionPlansOptions.byPlan[props.planID].service}</Typography></div>                        
                    </div>
                    <div id="Resolution" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div><Typography variant="LABEL">Resolution</Typography></div>
                        <div style={{ display: 'flex',gap: "10px"}}>
                            {
                                subscriptionPlansOptions.byPlan[props.planID].resolution.map((res,i)=>(
                                    <InfoChip key={res.replace(" ", "_")+i} size="small" ><ChipLabel label={res} /></InfoChip>
                                ))
                            }                            
                        </div>
                    </div>
                    <div id="support-channel" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div><Typography variant="LABEL">Support Channels</Typography></div>
                        <div style={{ width: '100%' }}>
                            <div id="reso" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div><Typography typography="MICRO">Dac Info Center</Typography></div>
                                <div>
                                    <ChipIcon>
                                    { channels.includes("dac_info") ? (<CheckCircleIcon />) : (<CloseCircleIcon />) }
                                    </ChipIcon>
                                </div>
                            </div>
                            <div id="reso" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div><Typography typography="MICRO">Dac Manager</Typography></div>
                                <div>
                                    <ChipIcon>
                                    { channels.includes("dac_manger") ? (<CheckCircleIcon />) : (<CloseCircleIcon />) }
                                    </ChipIcon>
                                </div>
                            </div>
                            <div id="reso" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div><Typography typography="MICRO">eMail</Typography></div>
                                <div>
                                    <ChipIcon>
                                    { channels.includes("email") ? (<CheckCircleIcon />) : (<CloseCircleIcon />) }
                                    </ChipIcon>
                                </div>
                            </div>
                            <div id="reso" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div><Typography typography="MICRO">Escalation Process</Typography></div>
                                <div>
                                    <ChipIcon>
                                    { channels.includes("escalation") ? (<CheckCircleIcon />) : (<CloseCircleIcon />) }
                                    </ChipIcon>
                                </div>
                            </div>
                            <div id="reso" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div><Typography typography="MICRO">Chat</Typography></div>
                                <div>
                                    <ChipIcon>
                                    { channels.includes("chat") ? (<CheckCircleIcon />) : (<CloseCircleIcon />) }
                                    </ChipIcon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SinglePlanCard;

SinglePlanCard.propType = {
    planID: PropTypes.string
}
