import {
  HorizontalDivider,
  Label,
  Typography,
} from "@nokia-csf-uxr/ccfk";
import VoiceIcon from "@nokia-csf-uxr/ccfk-assets/latest/VoiceIcon";
import LaunchPad, {
  LaunchPadTile,
  LaunchPadTileIcon,
  LaunchPadTileSubtitle,
} from "@nokia-csf-uxr/ccfk/LaunchPad";
import RadioButton, {
  RadioButtonLabelContent,
} from "@nokia-csf-uxr/ccfk/RadioButton";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import TabsDemo from "Components/Elements/Tabs";
import { systemStyle } from "Constants/StyleLayer";
import { setProp } from "Store/Actions";
import { RootState } from "Store/mainStore";
import React, { Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fullMiningType, fullSolType } from "../system";
import _ from "lodash";

interface ISolutionsTab {
  setSolution: Function;
  solutionSelected: String;
}
const SolutionsTab = ({ setSolution, solutionSelected }: ISolutionsTab) => {
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const bizSelector = useSelector((state: RootState) => state.business);
  const businessSelector = useSelector((state: RootState) => state.business);
  const tabSelected = useSelector((state: RootState) => state.dynamic.data.tabSelect);

  /** Hooks */
  const dispatch = useDispatch();

  /** updateMiningSolution */
  const updateMiningSolution = (miningType, fullSolType) => {
    const __miningTypeName = fullMiningType.byIds[miningType]["name"];
    // Exclude mpw solution for mining
    // Temporrary condition
    const csId = (fullSolType !== 'mpw') ? `cdm_${__miningTypeName}_${fullSolType}` : undefined;
    if (csId) {
      setSolution(csId)
    }
  }

  const renderMiningOptions = (label) => {
    return <Label maxWidth={true} variant="vertical-layout">
      <TextInputLabelContent>{label}</TextInputLabelContent>
      <div
        className="ml"
        style={{
          display: "flex",
          width: "50%",
        }}
      >
        {fullMiningType.allIds.map((option, i) => {
          return (
            <div style={{ display: "flex" }} key={i}>
              <Fragment key={i}>
                <RadioButton
                  key={i}
                  onChange={(e) => {
                    if (e.target.checked) {
                      dispatch(
                        setProp({
                          slice: "business",
                          key: "options.PTDAC.ndac_ref.fullMiningType",
                          value: option,
                        })
                      );
                      updateMiningSolution(option, businessSelector.data.options.PTDAC.ndac_ref.fullSolType);

                    }
                    // dispatch(setProp({ slice: "business", key: "view.PTDAC.commercial_model", value: "" }));
                  }}
                  checked={
                    bizSelector.data.options.PTDAC.ndac_ref
                      .fullMiningType === option
                  } //TODO : Make is general.
                />
                <RadioButtonLabelContent>
                  {fullMiningType.byIds[option].value}
                </RadioButtonLabelContent>
              </Fragment>
            </div>
          );
        })}
      </div>
    </Label>
  }

  const renderSolutionOptions = (label) => {
    return <Label
      style={{ paddingTop: "2rem" }}
      maxWidth={true}
      variant="vertical-layout"
    >
      <TextInputLabelContent>{label}</TextInputLabelContent>
      <div
        className="ml"
        style={{
          display: "flex",
        }}
      >
        {fullSolType.allIds.map((option, i) => {
          return (
            <Fragment key={i}>
              <RadioButton
                key={i}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(
                      setProp({
                        slice: "business",
                        key: "options.PTDAC.ndac_ref.fullSolType",
                        value: option,
                      })
                    );
                    updateMiningSolution(businessSelector.data.options.PTDAC.ndac_ref.fullMiningType, option);
                  }
                }}
                checked={
                  bizSelector.data.options.PTDAC.ndac_ref
                    .fullSolType === option
                } //TODO : Make is general.
              />
              <RadioButtonLabelContent>
                {fullSolType.byIds[option].value}
              </RadioButtonLabelContent>
            </Fragment>
          );
        })}
      </div>
    </Label>
  }

  // Mining UI
  const renderMiningUI = (mininglabel,solutionlabel) => {
    return <div style={{ textAlign: "center", margin: "2rem" }}>
      {renderMiningOptions(mininglabel)}
      {renderSolutionOptions(solutionlabel)}
    </div>
  }

  /** fullsolution_content */
  const fullsolution_content = () => {
    const caseIndustry = businessSelector.data.options.PTDAC.caseIndustry;
    const mininglabel = "Mining Type *";
    const solutionlabel = "Solution Type *";
    return (
      <Fragment key={0}>
        <>
          {
            _.includes(["mining"], caseIndustry) ? renderMiningUI(mininglabel,solutionlabel) : 
                <>
                  <div style={{ textAlign: "center", margin: "2rem" }}></div>
                </>
          }
        </>
      </Fragment>
    );
  };

  /** standalone_content */
  const standalone_content = () => {
    const standaloneContent = (authSelector.system.configuratorSolution).filter((solution) => solution.solutionType === 'Standalone');
    return (
      <Fragment key={1}>
        <div style={{ marginTop: "2rem" }}>
          <span>Business Parameters</span>
          <HorizontalDivider style={{ marginBottom: "2rem" }} />
        </div>
        <LaunchPad
          style={{
            backgroundColor: "white",
            minHeight: "unset",
            marginBottom: "2rem",
          }}
          id="_launchPadTile"
        >
          <div style={systemStyle.genrateGridCols(4)}>
            {standaloneContent.map((cs, index) => {
              return (
                <LaunchPadTile
                  key={index}
                  onClick={() => { setSolution(cs.segment) }}
                  style={{
                    border: `${solutionSelected ===
                      cs.segment
                      ? "2px solid #124191"
                      : "none"
                      }`,
                    height: "100%",
                    width: "138%",
                    paddingLeft: "20px",
                    background: "#EBEFF5",
                  }}
                >
                  <LaunchPadTileIcon
                    title={cs.title}
                    style={{
                      transform: "scale(1.3)",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ margin: "auto" }}>
                      {" "}
                      {cs.icon ? cs.icon() : VoiceIcon()}
                    </span>
                  </LaunchPadTileIcon>
                  <LaunchPadTileSubtitle id="csname" title={cs.solution}>
                    <Typography maxLines={1} typography="CAPTION">
                      {cs.solution}
                    </Typography>
                  </LaunchPadTileSubtitle>
                </LaunchPadTile>
              );
            })}
          </div>
        </LaunchPad>
      </Fragment>
    );
  };

  const TAB_DATA_FULL = useMemo(
    () => [
      {
        tab: {
          children: "Full Solutions",
          label: "Full Solutions",
          index: 0,
          content: fullsolution_content(),
        },
      },
      {
        tab: {
          children: "Standalone",
          label: "Standalone",
          index: 1,
          content: standalone_content(),
        },
      },
    ],
    [fullsolution_content, standalone_content]
  );

  const TAB_DATA_ONLY_FULL = useMemo(
    () => [
      {
        tab: {
          children: "Full Solutions",
          label: "Full Solutions",
          index: 0,
          content: fullsolution_content(),
        },
      },
    ],
    [fullsolution_content]
  );

  /** getSelectedTabIndex */
  const getSelectedTabIndex = () => {
    const TAB_DATA = _.includes(["reference_solution","manufacturing"],businessSelector.data.options.PTDAC.caseIndustry) ? TAB_DATA_FULL : TAB_DATA_ONLY_FULL;
    const __tabSelected = TAB_DATA.find(data => data.tab.label === tabSelected);
    return (__tabSelected && __tabSelected.tab && __tabSelected.tab.index) ? __tabSelected.tab.index : 0
  }

  return (
    <>
      {authSelector.system.configuratorSolution.length > 0 ? (
        businessSelector.data.options.PTDAC.caseIndustry ===
          "reference_solution" ? (
          <TabsDemo config={TAB_DATA_FULL} tabSelected={getSelectedTabIndex()}></TabsDemo>
        ) : (
          <TabsDemo config={TAB_DATA_ONLY_FULL}></TabsDemo>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(SolutionsTab);
