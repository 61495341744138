import { HorizontalDivider, Typography } from '@nokia-csf-uxr/ccfk';
import { SimpleCard } from '@nokia-csf-uxr/ccfk/Card';
import React, { ReactNode } from 'react';
interface MyProps {
    children: ReactNode,
    title: string
}
const OptionsFrame: React.FunctionComponent<MyProps> = (props) => {
    return (
        <>
            <div style={{ width: '38%' , height: '100%'}}>
            <SimpleCard style={{ height: "100%", minHeight: '40rem',  padding: '0.5rem 1rem 0rem'  }} hoverEffect={false} focusEffect={false}>
            <div style={{marginTop: "1rem", marginBottom: "0.5rem"}}><Typography typography="TITLE_16" style={{ flex: "initial" }}>{props.title}</Typography></div>
                <HorizontalDivider style={{  marginBottom: "2rem" }} />
                {props.children}
            </SimpleCard>
            </div>
        </>
    )
}

export default OptionsFrame