export const commericalModel = {
  allIds: ["opex", "capex"],
  byIds: {
    opex: {
      id: "opex",
      value: "OPEX",
      data: { min: 1, max: 120, stepBy:1,duration: "Months" },
    },
    capex: {
      id: "capex",
      value: "CAPEX",
      data: { min: 1, max: 10, stepBy:1,duration: "Years" },
    },
  },
};
export const ndacCompact_commericalModel = {
  allIds: ["opex", "capex","operating_lease"],
  byIds: {
    opex: {
      id: "opex",
      value: "OPEX",
      data: { min: 1, max: 120,stepBy:1, duration: "Months" },
    },
    capex: {
      id: "capex",
      value: "CAPEX",
      data: { min: 1, max: 10,stepBy:1, duration: "Years" },
    },
    operating_lease: {
      id: "operating_lease",
      value: "Operating Lease (aaS) Model",
      data: { min: 36, max: 60,stepBy:12, duration: "Months" },
    }
  },
};
export const fullMiningType = {
  allIds: ["open", "underground"],
  byIds: {
    open: {
      id: "open",
      value: "Open pit",
      name:"op"
    },
    underground: {
      id: "underground",
      value: "Underground",
      name:"ug"
    },
  },
};

export const fullSolType = {
  allIds: ["mpw", "ndac"],
  byIds: {
    mpw: {
      id: "mpw",
      value: "MPW",
    },
    ndac: {
      id: "ndac",
      value: "NDAC",
    },
  },
};
