import { moveElement } from "Components/Logics/ensoCommonFunctions";
import { currencyList } from "Components/Misc/CaseDetails_System";
import { setOBJ } from "Store/contentState/caseDetailsSlice";
// import { AuthSlice, CaseDetails } from "Store/contentState/ts";
import { store } from "Store/mainStore";
import { convertNumberToString } from "Utils";
// Helper Function for currency,sales_channel,market

type convertOptionsDefaults = {
  ootHook: {
    options: {}
    default: string
  }
  type: string | null,
  defaultList: { id: number|string, value: string }[],
  redX: {
    dispatch: typeof store.dispatch,
    selector: any, //the selection can be any as the app grows.
    auth: any,
  },
  idSrc:string 
}
export const convertOptions = ({
  ootHook,
  type = null,
  defaultList,
  redX,
  idSrc,
}:convertOptionsDefaults) => {
  if (!ootHook) {
    return defaultList;
  }
  switch (type) {
    case "OOT":
      // redX.dispatch((setOBJ({ path: [id]`, value: ooTDATA.default })))
      var res = Object.entries(ootHook.options).map(
        ([key, value]) => new Object({ id: key, value: value })
      );
      if (ootHook.default) {
        //const resIndex = res.findIndex((z, i) => z.id == ootHook.defaultList);
        if (true) {
          redX.dispatch(
            setOBJ({ path: "caseName", value: redX.auth.OOT.data.case_name })
          ); //caseName
          const ele: HTMLInputElement = document.querySelector("#caseName input");
          if (ele) {
            ele.value = redX.auth.OOT.data.case_name;
          }
          
          switch (idSrc) {
            case 'currency':
              if (Object.keys(ootHook.options).includes(ootHook.default)) {
                redX.dispatch(setOBJ({ path: idSrc, value: ootHook.default })); //currency,sales_channel,market
              }
              break;
          
            default:
              redX.dispatch(setOBJ({ path: idSrc, value: convertNumberToString(ootHook.default) })); //currency,sales_channel,market
              break;
          }
         
        }
        return moveElement(res, 2, 0);
      } else {
        return res;
      }
    case "None":
      // if (state.findCase.data.caseType===Object.keys(caseTypesMap)[3]) { //internal -R6
      //   // redX.selector.data[idSrc]
      //   return [...defaultList[0]];
      // }
      return defaultList;
    default:
      return defaultList;
      break;
  }
};

//Helper function for account,re-seller,indirect-seller
