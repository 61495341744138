import _ from 'lodash';
import { useDispatch, useSelector, useStore } from "react-redux";
import { RootState } from "Store/mainStore";
import CONFIG_API from "Constants/services/configurator";
import { setError } from "Store/auth";
import { setProp } from "Store/Actions";
import { useStatesOfElement } from "./useStatesOfElement";
import { useConfigurator } from "./useConfigurator";
import { useFormatters } from "./useFormatters";
import { useLocalStateManagement } from './useLocalStateManagement';
import { useProductDataList } from './useProductDataList';

export const useDataCallBack = (allRefs) => {
  const configstepperSelector = useSelector((state: RootState) => state.config_stepper);
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const caseDetailsSelector = useSelector(
    (state: RootState) => state.caseDetails
  );
  const dispatch = useDispatch();
  const { setPageFeedbacks, getPackageFieldList, getListOfFields } = useConfigurator();
  const { getSelectOptions } = useFormatters();
  const { getState, setState, getRef } = useLocalStateManagement();
  const { groupProductsIntoCategory } = useProductDataList();
  const solutionCategory = configstepperSelector.data.solutionCategory;
  const solutionType = configstepperSelector.data.solutionType;

  const getCurrentStep = (configstepperSelector.data.stepper_data).find((item) => item.status === "current");
  const resduxStore = useStore();

  const getKeysForArgs = (allfieldData, data, pra) => {
    let keys = JSON.stringify(_.map(allfieldData[data['data_callback']['args'][pra]], 'key'));
    if (_.includes(["NDAC","MPW"],solutionCategory)) {
      /*  this is for all multiple values got from multiselect / checkbox
          to send in api url as params as below logic from configurator requirement
          Following formats can be sent based on requirement
          1. ?value[0]=a&value[1]=b" => for OR / AND based on configuration
          2. band=B48&service_id=4G,5G&deployment_type=In,Out => for OR
          3. band=B48&service_id=4G+5G&deployment_type=In+Out => for AND
      */
      let condition = 'OR';
      const allKeys = _.map(allfieldData[data['data_callback']['args'][pra]], 'key');
      keys = condition === 'OR' ? _.join(allKeys) : _.join(allKeys, '+');
    }
    return keys;
  }

  const getDataCallback = async (data, allfieldData, postData?, defaultFlag?) => {
    try {
      const method = data['data_callback']['method'];
      const endpoint = data['data_callback']['endpoint'];
      let params = '';
      const urlargs = data['data_callback']['args'];
      let checkValuesList = [];
      let post_data;

      if (Object.entries(urlargs).length !== 0) {
        Object.entries(urlargs).forEach(function (item: any) {
          if (allfieldData[urlargs[item[1]]] !== "") {
            checkValuesList.push(true)
          } else {
            checkValuesList.push(false)
          }
        })
      } else if (method === 'POST') {
        // if method is post then send all the selected fields
        post_data = postData;
      }

      if (!checkValuesList.includes(false)) {
        let i = 0;
        Object.keys(urlargs).forEach(function (pra) {
          let keys = allfieldData[urlargs[pra]].key;
          if (_.isArray(allfieldData[urlargs[pra]])) {
            keys = getKeysForArgs(allfieldData, data, pra);
          }
          if (allfieldData[urlargs[pra]] !== "") {
            if (i === Object.keys(urlargs).length - 1) {
              params = params + pra + '=' + keys;
            }
            else {
              params = params + pra + '=' + keys + "&";
            }
          }
          i = i + 1
        });
      }
      if (post_data && _.isEmpty(post_data["data"]) && defaultFlag) {
        // Skip API call in case of empty data
        return {
          "data": 1,
          "status": true,
          "errorCode": "",
          "errorMessage": "",
          "requestId": "",
          "stacktrace": ""
        }
      }
      // if (params !== '') {
      setPageFeedbacks("inlineMessageAPI", true, `Please wait data is loading... ${getCurrentStep.name} section`);
      const resp = await CONFIG_API.configuratorDataCallback(method, endpoint, params, post_data);
      if (resp && resp.status && resp.data) {
        return resp;
      } else {
        dispatch(
          setError({
            key: "error",
            value: {
              errorCode: resp.errorCode,
              errorMsg: resp.errorMessage,
            },
          })
        );
        // setisDisabled(false);
      }
      setPageFeedbacks("inlineMessageAPI", false);
      // }
    } catch (e) {
      dispatch(
        setError({
          key: "error",
          value: {
            errorCode: e,
            errorMsg: "Timeout",
          },
        })
      );
      // setisDisabled(false);
    } finally {
      dispatch(setError("reset"));
      setPageFeedbacks("inlineMessageAPI", false);
      // setAPILoad(false);
    }
  }

  /** addCustomCallBack */
  const addCustomCallBack = (status = true, value: string | any = '', item: string = '', fieldDetails = undefined, dataStore = undefined,optionKey=undefined,currentStep = undefined) => {
    let datacall = {};
    if (fieldDetails && !_.isEmpty(fieldDetails)) {
      // check and update the call back
      Object.entries(fieldDetails).map((data, _index) => {
        if (status) {
          if (data[1]['data_callback']) {
            datacall[data[0]] = data[1]['data_callback'].args;
            // Hardcoded for now - need to change this to some event onload to callback on load
            if(data[1]['id'] === "radio_technology") {
              const allCurrentStepElements = dynamicSelector.data.element[configstepperSelector.data.active_step];
              checkCustomDataCallback(data, value, data[1]['id'], fieldDetails, allCurrentStepElements,optionKey,currentStep);
            }
          }
        }
        else {
          checkCustomDataCallback(data, value, item, fieldDetails, dataStore,optionKey,currentStep);
        }
      })
    }
    if (status) {
      dispatch(setProp({ slice: "dynamic", key: `callBack`, value: datacall }));
    }
  }

  /** checkCustomDataCallback */
  const checkCustomDataCallback = async (data, value, item, fieldDetails, dataStore,optionKey,currentStep=undefined) => {
    // if there is data callback json then do api call and get the data
    if (data[1]['data_callback']) {
      
      const allfields = {};

      //check if all the items are set for given args
      //_.every(data[1]['data_callback']['args'], (item) => getRef(allRefs, item) === true);
      let checkIfValueExists = true;
      if (checkIfValueExists) {
        Object.keys(data[1]['data_callback']['args']).forEach(function (imt) {
          if (data[1]['data_callback']['args'][imt] === item) {
            allfields[item] = value
          }
          else {
            allfields[data[1]['data_callback']['args'][imt]] = dataStore[data[1]['data_callback']['args'][imt]];
          }
        })

        const resp = await getDataCallback(data[1], allfields);
        if (resp && resp.status && resp.data) {
          const ele_id = data[1].id;
          let checkValueSetStatus = false
          const value_to_set = resp.data;
          let valueSet = [{ key: value_to_set, value: value_to_set }];

          if (typeof (resp.data) === 'object') {
            let respData = []
            Object.keys(resp.data).forEach(function (keyitem) {
              let respDataObj = { key: keyitem, value: resp.data[keyitem] }
              respData.push(respDataObj)
            })
            const keydata = _.keys(resp.data)[0];
            const valuedata = _.values(resp.data)[0];
            // Do not set the first item from response as default selection
            // valueSet = respData[0]
            let displayData = {};
            displayData[keydata] = valuedata
            let storeElementList = {}

            // let selectElement = {}
            let storeselectElement = {}
            Object.entries(fieldDetails).map((data, _index) => {
              if (data[1]['type'] === 'select' || data[1]['type'] === 'radio' || data[1]['type'] === 'checkbox') {
                if (ele_id === data[0]) {
                  storeselectElement[data[0]] = getSelectOptions(resp.data)
                }
                // else {
                //   storeselectElement[data[0]] = getSelectOptions(data[1]['options'])
                // }
              }
              if (data[1]['type'] === 'hidden' && data[0] === ele_id) {
                storeselectElement[data[0]] = typeof(resp.data) === 'object' ? _.values(resp.data)[0] : resp.data;
                // store hidden element inside modal data into modal data
                if (!_.isEmpty(storeselectElement[data[0]]) && currentStep) {
                  dispatch(setProp({ slice: "dynamic", key: `modal.${configstepperSelector.data.active_step}.${configstepperSelector.data.active_substep}.RRH.${currentStep}.${[ele_id]}`, value: { 'key' : storeselectElement[data[0]] , 'value':storeselectElement[data[0]]} }));
                }
                // ------ Should be deleted
                // if (_.isEmpty(storeselectElement[data[0]]) && currentStep) {
                //   dispatch(setProp({ slice: "dynamic", key: `modal.${configstepperSelector.data.active_step}.${configstepperSelector.data.active_substep}.RRH.${currentStep}.${[ele_id]}`, value: { 'key' : 1 , 'value': 1} }));
                // }                
              }
            });
            if (!_.isEmpty(storeselectElement)) {
              const reduxState = resduxStore.getState();
              //@ts-ignore
              const storedOptionsState = reduxState['dynamic'].data.localStates.setOptionsData;
              const optionsData = _.cloneDeep(storedOptionsState);
              
              const stepOption = { ...optionsData[configstepperSelector.data.active_step] };
              if(optionKey){
                stepOption[optionKey] = {...stepOption[optionKey],...storeselectElement};
              }
              else{
                // Custom datacallback for "radio_excel_technology" Or similar config
                stepOption[data[0]] = storeselectElement[data[0]];
              }
              
              optionsData[configstepperSelector.data.active_step] = stepOption;
              setState('setOptionsData', optionsData);
            }
          }
          // dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[ele_id]}`, value: valueSet }));
        }
        setPageFeedbacks("inlineMessageAPI", false);
      }
      // else {
      //   console.log("Not entering API call...", item)
      // }
    }
    // else {
    //   console.log("Else part data[1]['data_callback']", data[1]['data_callback'])
    // }
  }
  /** addCallBack */
  const addCallBack = (status, value: string | any = '', item: string = '', deviceBlock) => {
    let datacall: any = {};
    
    if ((_.includes(["NDAC","MPW"],solutionCategory)) && (configstepperSelector.data.active_step === "devices" || configstepperSelector.data.active_step === "connectivity") &&  solutionType !== 'Standalone' ) {
      Object.entries(deviceBlock).map((data, _index) => {
        if (status) {
          if (data[1]['data_callback']) {
            datacall[data[0]] = data[1]['data_callback'].args;
            // if the element has datacallback and has event as onload then call the datacallback
            if(data[1]['data_callback']['event'] && data[1]['data_callback']['event'] === 'onload') {
              checkDataCallback(data, value, item, true)
            }
          }
        }
        else {
          checkDataCallback(data, value, item, true)
        }
      });
      if (status) {
        dispatch(setProp({ slice: "dynamic", key: `callBack`, value: datacall }));
      }
    }
    else {
      addAllCallbacks(status,value,item);
    }
    // if (status) {
    //   dispatch(setProp({ slice: "dynamic", key: `callBack`, value: datacall }));
    // }
  }

  // called when solution is cmu. either adds all the callbacks or calls existing callbacks from list
  const addAllCallbacks = (status, value: string | any = '', item: string = '') => {
    let packagesList = configstepperSelector.data.solution_form_data['packages']
    let datacall: any = {};
    Object.keys(packagesList).forEach(function (key) {
      if (packagesList[key].field_details) {
        Object.entries(packagesList[key].field_details).map((data, _index) => {
          if (status) {
            if (data[1]['data_callback']) {
              datacall[data[0]] = data[1]['data_callback'].args
            }
          }
          else {
            checkDataCallback(data, value, item, true)
          }
        })
      }
    });
    if (status) {
      dispatch(setProp({ slice: "dynamic", key: `callBack`, value: datacall }));
    }
  }

  const setBandValues = (response, field, storeselectElement, selectedvalue_parent) => {
    const connectivity_selected = selectedvalue_parent;
    let errorflag = false;
    if (connectivity_selected) {
      let bands4gdata = {}
      let bands5gdata = {}
      Object.entries(response).map((item) => {
        const regex4g = /\bB\d+\b/i;
        const regex5g = /\b[n]\d+\b/i;
        if (regex4g.test(item[0].toLowerCase()) && !regex5g.test(item[0].toLowerCase())) {
          bands4gdata[item[0]] = response[item[0]];
        } else {
          bands5gdata[item[0]] = response[item[0]];
        }
      });
      // code to check if connectivity has just 4g,5g,both
      const hasOnly4G = _.some(connectivity_selected, { key: '4G' });
      const hasOnly5G = _.some(connectivity_selected, { key: '5G' });
      let bandData = response;
      if (hasOnly4G && hasOnly5G) {
        bandData = response;
      } else if (hasOnly4G) {
        bandData = bands4gdata;
      } else if (hasOnly5G) {
        bandData = bands5gdata;
      }
      if (_.isEmpty(bandData)) {
        // setEmptyErrorMessage(true);
        setPageFeedbacks("emptyErrorMessage", true, `No Items are available based on selected parameters in ${getCurrentStep.name} section`);
      } else {
        // setEmptyErrorMessage(false);
        setPageFeedbacks("emptyErrorMessage", false);
      }
      storeselectElement[field] = getSelectOptions(bandData);
    }
  }

  /** checkDataCallback */
  const checkDataCallback = async (data, value, item, direct_call) => {
    // if there is data callback json then do api call and get the data
    if (data[1]['data_callback']) {
      const allfields = getListOfFields(dynamicSelector.data.element);
      //check if all the items are set for given args
      let checkIfValueExists = false;
      if (data[1]['data_callback']['args']['country'] === 'country' || data[1]['data_callback']['args']['country_code'] === 'country' || data[1]['data_callback']['args']['sid'] === 'sid' || data[0] === 'mxie_server') {
        checkIfValueExists = true
      }
      else {
        checkIfValueExists = _.every(data[1]['data_callback']['args'], (item) => getRef(allRefs, item) === true);
      }
      if (checkIfValueExists) {
        Object.keys(data[1]['data_callback']['args']).forEach(function (imt) {
          if (_.includes(['country','country_code'],imt)) {
            let datafield = {}
            datafield['key'] = caseDetailsSelector.data.country
            datafield['value'] = caseDetailsSelector.data.country
            allfields['country'] = datafield
          }
          if (imt === 'sid') {
            let datafield = {}
            datafield['key'] = dynamicSelector.data.sid
            datafield['value'] = dynamicSelector.data.sid
            allfields['sid'] = datafield;
          }
          if (data[1]['data_callback']['args'][imt] === item) {
            allfields[item] = value
          }
        });
        const resp = await getDataCallback(data[1], allfields);
        if (resp && resp.status && resp.data) {
          
          const ele_id = data[1].id;
          let checkValueSetStatus = false
          if (direct_call) {
            const value_to_set = resp.data;
            let valueSet = [{ key: value_to_set, value: value_to_set }];
            let productCategoryData = {};

            if (typeof (resp.data) === 'object') {
              let respData = []
              Object.keys(resp.data).forEach(function (keyitem) {
                let respDataObj = { key: keyitem, value: resp.data[keyitem] }
                respData.push(respDataObj)
              })
              const keydata = _.keys(resp.data)[0];
              const valuedata = _.values(resp.data)[0];
              // Do not set the first item from response as default selection
              // valueSet = respData[0]
              valueSet = []
              let displayData = {};
              displayData[keydata] = valuedata
              let storeElementList = {}
              let packagesListData = configstepperSelector.data.solution_form_data['packages']
              Object.keys(packagesListData).forEach(function (key) {
                let selectElement = {}
                let storeselectElement = {}
                // Take Pakcage list from common place
                // let dataListPackage = packagesListData[key].field_details
                let dataListPackage = getPackageFieldList(key);

                Object.entries(dataListPackage).map((data, _index) => {
                  selectElement[data[0]] = ""
                  if (data[1]['type'] === 'checkbox') {
                    selectElement[data[0]] = []
                  }
                  if (data[1]['type'] === 'select' || data[1]['type'] === 'radio' || data[1]['type'] === 'checkbox') {
                    if (ele_id === data[0]) {
                      if (ele_id === "bands") {
                        setBandValues(resp.data, data[0], storeselectElement, value);
                        valueSet = [];
                      }
                      else {
                        if (_.includes(["NDAC","MPW"],solutionCategory) && data[0] === 'mxie_server') {
                          productCategoryData = groupProductsIntoCategory(resp.data, "");
                          _.map(productCategoryData, (val, key) => {
                            productCategoryData[key] = _.sortBy(productCategoryData[key], ['list_price']);
                          });
                          storeselectElement[data[0]] = productCategoryData;
                          checkValueSetStatus = true
                        }
                        else {
                          storeselectElement[data[0]] = getSelectOptions(resp.data)
                        }
                      }
                    }
                    else {
                      storeselectElement[data[0]] = getSelectOptions(data[1]['options'])
                    }
                  }
                  if (data[1]['type'] === 'hidden' && data[0] === ele_id) {
                    storeselectElement[data[0]] = resp.data;
                  }
                  //allRefs.current[data[1]['id']] = false;
                });
                if (!_.isEmpty(storeselectElement)) {
                  storeElementList[key] = storeselectElement;
                }
              });
              
              // fix added for elements to be rendered
              if (!_.isEmpty(storeElementList)) {
                const reduxState = resduxStore.getState();
                //@ts-ignore
                const optionsData = _.cloneDeep(reduxState['dynamic'].data.localStates.setOptionsData);
                optionsData[configstepperSelector.data.active_step][data[0]] = storeElementList[configstepperSelector.data.active_step][data[0]]
                setState('setOptionsData', optionsData);
                /**
                 * ---- Should remove once tested
                 * // setState('setOptionsData', storeElementList);
                // const reduxState = resduxStore.getState();
                // //@ts-ignore
                // const storedOptionsState = _.cloneDeep(reduxState['dynamic'].data.localStates.setOptionsData);
                // // reduxState['dynamic'].data.localStates.setOptionsData;

                // console.log("-------storedOptionsState",storedOptionsState)
                // const optionsData = {...storedOptionsState,...storeElementList};
                // console.log("-------------optionsData",optionsData)
                // debugger;
                // setState('setOptionsData', {...storedOptionsState,...storeElementList});
                 */
              }
            }
            dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[ele_id]}`, value: checkValueSetStatus ? productCategoryData : valueSet }));
          }
          else {
            const productCategoryData = groupProductsIntoCategory(resp.data, "");
            dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[ele_id]}`, value: productCategoryData }));
            const optdata = { "id": ele_id, "data": productCategoryData };
            // setOptionsDataList(() => optdata)
            setState('setOptionsDataList', optdata);
          }
        }
        setPageFeedbacks("inlineMessageAPI", false);
      }
    }
  }

  /** validateRadioExcelJsonData */
  const validateRadioExcelJsonData  = async (jsonData) =>{
    const currency = caseDetailsSelector.data.currency;
    const method = "POST";
    const endpoint = "/api/configurator/boq/item/validate"
    const params = `currency=${currency}`;
    const post_data = {
      data: jsonData
    };
    const resp = await CONFIG_API.configuratorDataCallback(method, endpoint, params, post_data);
    if (resp && resp.status && resp.data) {
      return resp;
    } else {
      // --- Need to check and enable
      // dispatch(
      //   setError({
      //     key: "error",
      //     value: {
      //       errorCode: resp.errorCode,
      //       errorMsg: resp.errorMessage,
      //     },
      //   })
      // );
      return resp;
    }
  }


  return {
    getDataCallback,
    addCustomCallBack,
    addCallBack,
    addAllCallbacks,
    checkDataCallback,
    validateRadioExcelJsonData
  }
}
