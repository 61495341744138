import { Button, ButtonsRow } from '@nokia-csf-uxr/ccfk';
import AddIcon from '@nokia-csf-uxr/ccfk-assets/legacy/AddIcon';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import { RootState } from 'Store/mainStore';
import { setTheme } from 'Store/theme';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface ISkipBtn {
    showSkip: boolean,
    onSkip: Function,
}
interface IAddCatalogBtn {
    onAction: Function,
    isLoading: boolean,
    renderSkip?: ISkipBtn,

}
const AddCatalogBtn = (props: IAddCatalogBtn) => {
    const dispatch = useDispatch();
    const themeSelector = useSelector((state: RootState) => state.theme);
    return (
        <React.Fragment>
            <ButtonsRow style={{padding:0,margin:0}}>
                {(<Button

                    variant='neutral'
                    hidden={!(props.renderSkip && props.renderSkip.showSkip)}
                    onClick={() => {
                        props.renderSkip.onSkip();
                    }} >
                    <ButtonText>Skip</ButtonText>
                </Button>)}

                <div className='mt'><Button

                    variant='neutral'
                    disabled={props.isLoading}
                    onClick={() => {
                        props.onAction();
                        dispatch(setTheme({
                            element: "modal",
                            comp: "connectivity_catalog",
                            prop: "isOpen",
                            value: true,
                        }))
                    }}

                >
                    <ButtonIcon >
                        <AddIcon />
                    </ButtonIcon><ButtonText>Add From Catalog</ButtonText>
                </Button></div>
            </ButtonsRow>

        </React.Fragment>


    )
}
AddCatalogBtn.propType = {
    onAction: PropTypes.func,
    isLoading: PropTypes.bool,
    showSkip: PropTypes.bool
}
export default AddCatalogBtn