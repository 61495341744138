import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { IconButton, Label, TextInput } from "@nokia-csf-uxr/ccfk";
import AddIcon from "@nokia-csf-uxr/ccfk-assets/legacy/AddIcon";
import RemoveIcon from "@nokia-csf-uxr/ccfk-assets/legacy/RemoveIcon";
import Slider, { SliderLabel } from "@nokia-csf-uxr/ccfk/Slider";
import { roundCount } from "Utils";

const SliderComponent = ({
  sliderId = "",
  sliderStateValue = 1,
  minDuration = "1",
  maxDuration = "10",
  stepBy = 1,
  errorValidation = {},
  setProperty,
  disabled = false,
  ref = {},
  handleChange = (e) => {},
}) => {
  // const selectItemRef = useRef(setProperty.sliceProperty);
  const getAriaLabel = () => {
    if (sliderStateValue <= +roundCount(minDuration)) {
      return `Reach minimum value`;
    } else if (sliderStateValue >= +roundCount(maxDuration)) {
      return `Reach maximum value`;
    }
    return sliderStateValue;
  };

  const frontIcon = (
    <>
      <IconButton
        disabled={sliderStateValue >= roundCount(maxDuration)}
        aria-label="Increase value"
        onClick={() =>
          handleLabelClick(roundCount(sliderStateValue) + roundCount(stepBy))
        }
      >
        <AddIcon />
      </IconButton>
      <span style={{ lineHeight: "2.5em" }}>{maxDuration}</span>
    </>
  );

  const rearIcon = (
    <>
      <span style={{ lineHeight: "2.5em" }}>{minDuration}</span>
      <IconButton
        disabled={roundCount(sliderStateValue) <= roundCount(minDuration)}
        aria-label="Decrease value"
        onClick={() => {
          return handleLabelClick(
            roundCount(sliderStateValue) - roundCount(stepBy)
          );
        }}
      >
        <RemoveIcon />
      </IconButton>
    </>
  );

  const renderMinLabel = (labelProps) => (
    <SliderLabel label={rearIcon} {...labelProps} />
  );
  const renderMaxLabel = (labelProps) => (
    <SliderLabel label={frontIcon} {...labelProps} />
  );
  const handleLabelClick = (value) => {
    let normalizedValue = value;
    if (normalizedValue !== "" && normalizedValue < roundCount(minDuration))
      normalizedValue = minDuration;
    if (normalizedValue !== "" && normalizedValue > roundCount(maxDuration))
      normalizedValue = roundCount(maxDuration);

    handleChange({ value: normalizedValue });
  };
  const [qtyInputVal, setQtyInputVal] = useState(sliderStateValue);
  const [txtInputVal, setTxtInputVal] = useState(sliderStateValue);
  // --- Can be removed once use case is tested 
  // const [isEditMode, setIsEditMode] = useState(false);
  useEffect(() => {
    setQtyInputVal(sliderStateValue);
    setTxtInputVal(sliderStateValue);
  }, [sliderStateValue]);

  /*
  // ------------ This code block can be removed once the changes are tested !
  // Using useCallback to memoize the debounced function
  const onSliderTextChange = useCallback(
    _.debounce((event) => {
      if (event.target.value <= maxDuration) {
        if (event.target.value <= minDuration) {
          // Set text value and also the slider values as min
          event.target.value = minDuration;
          let calcVal = roundCount(minDuration);
          if (isEditMode) {
            setQtyInputVal(calcVal);
            handleLabelClick(calcVal);
          }
        } else {
          let calcVal = roundCount(event.target.value);
          if (calcVal % stepBy > 0) {
            // Find the nearest divisible by stepBy value
            calcVal = Math.round(calcVal / stepBy) * stepBy;
            event.target.value = calcVal;
          }
          
          if (isEditMode) {
            setQtyInputVal(calcVal);
            handleLabelClick(calcVal);
          }
        }
      } else {
        event.target.value = maxDuration;
        let calcVal = roundCount(maxDuration);
        if (isEditMode) {
          setQtyInputVal(calcVal);
          handleLabelClick(calcVal);
        }
      }      
    }, 800),
    // [] // Empty array ensures that the debounced function is created only once
    [isEditMode] // Reguster the function when isEditMode is changed
  );
  */

  /** Set textbox entered value */
  const onTextBoxValue = (event) => {
    let calcVal = event.target.value;
    setTxtInputVal(calcVal);
    onSliderTextChange(event)
  }

  /** onSliderTextChange */
  const onSliderTextChange = _.debounce((event) => {
    const __minDuration = parseInt(minDuration);
    const __maxDuration = parseInt(maxDuration);
    const __txtValue = parseInt(event.target.value);
    let calcVal = roundCount(minDuration);
    if ( __txtValue <= __maxDuration) {
      if (__txtValue <= __minDuration) {
        // Set text value and also the slider values as min
        setTxtInputVal(__minDuration);
        calcVal = roundCount(minDuration);
      } else {
        calcVal = roundCount(event.target.value);
        if (calcVal % stepBy > 0) {
          // Find the nearest divisible by stepBy value
          calcVal = Math.round(calcVal / stepBy) * stepBy;
          setTxtInputVal(calcVal);
          // event.target.value = calcVal;
        }
      }
    } 
    else {
      // event.target.value = __maxDuration;
      calcVal = roundCount(__maxDuration);
      setTxtInputVal(calcVal);
    }
    setQtyInputVal(calcVal);
    setTxtInputVal(calcVal);
    handleLabelClick(calcVal);
  }, 1000);



  return (
    <Label
      htmlFor="selectitem-component-input"
      maxWidth
      style={{ alignItems: "baseline" }}
    >
      <Slider
        ref={ref as React.Ref<HTMLElement>}
        stepBy={roundCount(stepBy)}
        isTicker={true}
        value={qtyInputVal}
        // inputMode='decimal'
        inputProps={{ value: sliderStateValue }}
        onChange={(e: { value: number }) => {
          setQtyInputVal(e.value);
          // setIsEditMode(false);
          if (e.value > roundCount(maxDuration)) {
            handleChange({ value: roundCount(maxDuration) });
            return;
          }
          handleChange(e);
        }}
        disabled={disabled}
        min={roundCount(minDuration)}
        max={roundCount(maxDuration)}
        thumbProps={{
          minThumbProps: {
            role: "slider",
            "aria-valuemin": minDuration,
            "aria-valuenow": sliderStateValue,
            "aria-valuemax": parseInt(maxDuration),
            "aria-valuetext": getAriaLabel(),
            "aria-labelledby": "rangeselection-label-id",
            "aria-orientation": "horizontal",
          },
        }}
        style={{
          marginTop: "2%",
        }}
      />

      <TextInput
        // value={isEditMode ? undefined : qtyInputVal.toString()}
        value={txtInputVal.toString()}
        error={sliderStateValue.toString() === ""}
        disabled={disabled}
        id={sliderId}
        inputProps={{
          type:"number",
          min:minDuration,
          step:stepBy,
          max:maxDuration,
        }}
        // -- Revolked the status change
        // inputProps={{
        //   onFocus: (e) => {
        //     setIsEditMode(true);
        //   },
        //   onBlur: (e) => {
        //     if (e.target.value === "") {
        //       setIsEditMode(false);
        //       let calcVal = roundCount(minDuration);
        //       setQtyInputVal(calcVal);
        //       handleLabelClick(calcVal);
        //     }
        //   },
        // }}
        errorMessage={sliderStateValue.toString() === "" && "Invalid Area"}
        style={{
          margin: "1%",
          width: "20%",
          padding: "5px",
        }}
        // onChange={(event) => onSliderTextChange(event)}
        onChange={(event) => onTextBoxValue(event)}
      ></TextInput>
    </Label>
  );
};
export default SliderComponent;
SliderComponent.propTypes = {
  sliderId: PropTypes.string,
  handleChange: PropTypes.func,
  stepBy: PropTypes.number,
  sliderStateValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minDuration: PropTypes.string,
  maxDuration: PropTypes.string,
  errorValidation: PropTypes.object,
  setProperty: PropTypes.object,
  disabled: PropTypes.bool,
  ref: PropTypes.object,
};
