import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import React from "react";
import { HelpText } from "../hooks/useHelpText";
import classes from "./FieldLabel.module.css";

interface IFieldLabel {
  fieldData: Object
}
/*
fieldData[1].label
fieldData[1].required
fieldData[1].description
fieldData[1].help_title
fieldData[1].help_text
*/
const FieldLabel = ({ fieldData }:IFieldLabel) => {
  const labelText =
    fieldData[1] && fieldData[1].label ? fieldData[1].label : "-";
  const isRequired =
    fieldData[1] && fieldData[1].required ? fieldData[1].required : false;

  const { showHelpText } = HelpText();
  return (
    <div style={{ display: "flex" }}>
      {/* <TextInputLabelContent className={`${classes.dotFieldLabel} ${isRequired ? classes.dotLabelRequired:''}`}>{labelText}</TextInputLabelContent> */}
      <TextInputLabelContent required={true}>{labelText}</TextInputLabelContent>
      {showHelpText(fieldData)}
    </div>
  );
};

export default FieldLabel;
