import { useEffect, useState } from "react";

export const useScrollTop = (targetElement=undefined) => {

  const [isScrolled, setIsScrolled] = useState(false);
  const [dependencies,setDependencies] = useState([]);


  useEffect(() => {    
    if(targetElement){
      targetElement.addEventListener('scroll', getScrollTop);
    }
    return () =>{
      if(targetElement){
        targetElement.removeEventListener('scroll', () => {});
      }
    } 
  }, [targetElement]);

  // useEffect(() => {
  //   console.log("dependencies changed",dependencies)
  //   if(isScrolled && targetElement){
  //     targetElement.scrollTo({ top: 0, behavior: 'smooth' })
  //   }
  // }, dependencies);

  /** getScrollTop */
  const getScrollTop = () =>{
    if(targetElement){
      var scrollTop = targetElement.scrollTop;
      setIsScrolled(scrollTop > 100);
    }      
  }

  /** setScrollDependency */
  const setScrollDependency = (dependency = []) => {
    setDependencies(dependency)
  }

  return {
    isScrolled,
    setScrollDependency
  }
};