import CONFIG_API from "Constants/services/configurator";
import { setProp } from "Store/Actions";
import { setConfigSegmentSoln, setError } from "Store/auth";
import { resetDynamicStep } from "Store/contentState/configurationArea/dynamicSlice";
import { clearDataConfig, setDataConfig } from "Store/contentState/configuration_stepperSlice";
import { RootState } from "Store/mainStore";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useState } from "react";
import { useConfigurator } from "./useConfigurator";
import { ndacCompact_commericalModel } from "../../Business/system";

export const useSolutionLoad = () => {
  const configStepperSelector = useSelector((state: RootState) => state.config_stepper);
  const cartBoqSlice = useSelector((state: RootState) => state.cartBoq);
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
  const dispatch = useDispatch();
  const { getPackageFieldList } = useConfigurator();

  /** referenceSolutionButtons */
  const referenceSolutionButtons = [
    { name: "NDAC Reference Solution", value: "NDAC" },
    { name: "MPW Reference Solution", value: "MPW" }
  ];

  /** solutionLoad */
  const solutionLoad = async (cs) => {
    clearStateData();
    // let segmentLoadStatus = true;
    const dataConfig = [
      { key: "solution_selected", value: cs.segment },
      { key: "isStepperLoading", value: true },
      { key: "solutionCategory", value: cs.solutionCategory },
      { key: "solutionType", value: cs.solutionType }
    ];
    // if (cs.segment === 'mpw_manufacturing') {
    //   segmentLoadStatus = false;
    // }
    // set selected solution
    dispatch(setDataConfig(dataConfig));
    // call api to fetch specific solution data
    try {
      // if (segmentLoadStatus) {
        const resp = await CONFIG_API.getSegmentForm(cs.segment);
        if (resp.status) {
          const configSteps = prepSteps(resp.data);
          const dataConfigRes = [
            { key: "solution_form_data", value: resp.data },
            { key: "stepper_data", value: configSteps }
          ];
          dispatch(setDataConfig(dataConfigRes));
          // dispatch(setDataConfig({ key: "solution_form_data", value: resp.data }));
          dispatch(setProp({ slice: "dynamic", key: `elementMMS`, value: addMinMaxStep(resp.data) }));
          // dispatch(setDataConfig({ key: "stepper_data", value: configSteps }));
        } else {
          dispatch(
            setError({
              key: "error",
              value: {
                errorCode: resp.errorCode,
                errorMsg: resp.errorMessage,
              },
            })
          );
          dispatch(setDataConfig({ key: "stepper_data", value: configStepperSelector.data.stepper_data }));
        }
      // }
    } catch (error) {
      dispatch(
        setError({
          key: "error",
          value: {
            errorCode: error,
            errorMsg: "Timeout",
          },
        })
      );
      dispatch(setDataConfig({ key: "stepper_data", value: configStepperSelector.data.stepper_data }));
    }
    finally{
      dispatch(setDataConfig({ key: "isStepperLoading", value: false }));
    }
  }

  /** updateSolutionData */
  const updateSolutionData = async (csId) => {
    if(!authSelector.system.configuratorSolution || authSelector.system.configuratorSolution.length === 0){
      // select the solution category for selected segment id and send to store
      dispatch(setDataConfig({ key: "isSegmentListLoading", value: true }));
      await getSegmentList();
    };

    if(authSelector.system.configuratorSolution && authSelector.system.configuratorSolution.length > 0){
      const __csObj = getSegmentDetails(csId);
      await solutionLoad(__csObj);
    }
  }

  const getSegmentDetails = (csId) => {
    const configListSegment = (authSelector.system.configuratorSolution).filter((solution) => solution.segment.toLowerCase() === csId.toLowerCase());      
    const solutionCategory = configListSegment && configListSegment[0] && configListSegment[0]['solutionCategory'];
    const solutionType = configListSegment && configListSegment[0] && configListSegment[0]['solutionType'];
    const __csObj = {'segment' : csId, 'solutionCategory': solutionCategory, 'solutionType': solutionType};
    return __csObj;
  }

  const clearStateData = () => {
    if (cartBoqSlice.data.cart.items.length !== 0) {
      dispatch(clearDataConfig());
      dispatch(setProp({ slice: 'cartBoq', key: `cart.items`, value: [] }))
      dispatch(resetDynamicStep());
      dispatch(setProp({
        slice: 'theme', key: `button.isToggle`, value: {
          applications_editSelection: true,
          connectivity_editSelection: true,
          business_undo: true,
          connectivity_undo: false,
        }
      }))
    }
  }

  const addMinMaxStep = (solution_form_data) => {
    let elementMMS = {}
    let packagesList = solution_form_data['packages'];
    Object.keys(packagesList).forEach(function (key) {
      let selectElementMMS = {};
      if (packagesList[key].field_details) {
        Object.entries(packagesList[key].field_details).map((data, _index) => {
          if (data[1]['type'] === 'details' && data[1]['field_details']) {
            const subpackageDetails = getPackageFieldList(key,data[1]['id'],packagesList);
            _.map(subpackageDetails,(subData,subIndex) => {
              const subfields = [];
              subfields[0] = subData.id;
              subfields[1] = subData;
              if(subData['type'] ===  "composite") {
                const compositekey = subData.form_callback.form_identifier + "1";
                const compositeFields = subData.field_details[compositekey];
                _.map(compositeFields,(compositeData,i) => {
                  const compfields = [];
                  compfields[0] = compositeData.id;
                  compfields[1] = compositeData;
                  elementMMS_prep(compfields,selectElementMMS)
                });
              } else {
                elementMMS_prep(subfields,selectElementMMS);
              }
            });
          } else if(data[1]['type'] === 'container') {
            const containerFieldData = data[1]['field_details'];
            _.map(containerFieldData,(containerField,i) => {
              const contfields = [];
              contfields[0] = containerField.id;
              contfields[1] = containerField;
              elementMMS_prep(contfields,selectElementMMS)
            });
          } else {
            elementMMS_prep(data,selectElementMMS)
          }
        });
      }
      if (Object.entries(selectElementMMS).length !== 0) {
        elementMMS[key] = selectElementMMS;
      }
    })
    return elementMMS
  }

  const elementMMS_prep = (data,selectElementMMS) => {
      // // if (eachPackage.field_details) {
      //   Object.entries(fieldData).map((data, _index) => {
          if (data[1]['type'] === 'range' || data[1]['type'] === 'number') {
            let dataMinMaxStep = ["min", "max", "steps"]
            let selectElementMMSKV = {}
            dataMinMaxStep.forEach((mms) => {
              if (data[1][mms]['data_callback']) {
                selectElementMMSKV[mms] = ''
              }
              else {
                selectElementMMSKV[mms] = data[1][mms]
              }
            })
            if (Object.entries(selectElementMMSKV).length !== 0) {
              selectElementMMS[data[0]] = selectElementMMSKV
            }
          }
        // })
      // }
  }

  /** Prepare Steps */
  const prepSteps = (responsedata) => {
    // temp remove after api mpw implementation
    const steps = Object.keys(responsedata.packages);
    let dynamicSteps = [{ name: 'Business', stepNumber: 1,isChildActive:false, status: 'current', isComplete: false, selected: true, isValid: true, id: 'business', stepname: 'business' }];
    let stepno = 2;
    
    steps.forEach(eachStep => {
      const sub_steps = getSubSteps(responsedata.packages[eachStep]['field_details']);
      const is_child_active = sub_steps && sub_steps.length > 0;
      let stepobj = { name: responsedata.packages[eachStep].label,isChildActive:is_child_active, stepNumber: stepno, status: 'future', isComplete: false, selected: false, isValid: true, id: 'config_' + eachStep, stepname: eachStep ,subSteps: sub_steps,states:responsedata.packages[eachStep]['states'] ? responsedata.packages[eachStep]['states']:[]};

      stepno++;
      dynamicSteps.push(stepobj);
    });
    const lastStep = { name: "Review", stepNumber: dynamicSteps.length + 1, isChildActive:false ,status: 'future', isComplete: false, selected: false, isValid: true, id: 'config_review', stepname: 'review' }
    dynamicSteps.push(lastStep);
    return dynamicSteps;
  }

  /** getSubSteps */
  const getSubSteps = (stepFieldDetails) => {
    let sub_steps = [];

    if(stepFieldDetails) {
      let childSteps = _.filter(stepFieldDetails,(obj) => obj.type === 'details');;
      let child_stepno = 1;
      _.each(childSteps, (child) => {
        const childStep = { name: child.label, stepNumber: child_stepno, status: 'future', isComplete: false, selected: false, isValid: true, id: 'config_' + child.id, stepname: child.id, isChildActive:false,states:child.states ? child.states : [] };
        sub_steps.push(childStep);
        child_stepno++;
      });
      return sub_steps;
    }
  }

  // get segment data for standalone solutions (cmu)
  const getSegmentList = async () => {
    await CONFIG_API.getSegmentList()
      .then((resp) => {
        if (resp.status) {
          // const segment_solutions = (resp.data).filter((solution) => solution.solutionType === 'Standalone');
          dispatch(setConfigSegmentSoln(resp.data));          
        } else {
          dispatch(
            setError({
              key: "error",
              value: {
                errorCode: resp.errorCode,
                errorMsg: resp.errorMessage,
              },
            })
          );
          dispatch(setConfigSegmentSoln([]));
        }        
      })
      .catch((e) => {
        dispatch(
          setError({
            key: "error",
            value: {
              errorCode: e,
              errorMsg: "Timeout",
            },
          })
        );
        dispatch(setConfigSegmentSoln([]));
      })
      .finally(()=>{
        dispatch(setDataConfig({ key: "isSegmentListLoading", value: false }));
      });
    dispatch(setError("reset"));    
  }

  // referencesolution,NDAC option is selected
  const setNDACCompact = async() => {
    // fetch the countrylist from backend
    const method = "GET";
    const endpoint = "/api/configurator/custom/getCountryList";
    const params = '';
    const resp = await CONFIG_API.configuratorDataCallback(method, endpoint,params);
    console.log("response : ",resp)
    if (resp && resp.status && resp.data) {
      // check if network country is present in the list
      // if present then go ahead and add commericial model
      const countryCodes = _.map(resp.data,'code');
      if(_.includes(countryCodes,caseDetailsSelector.data.country)) {
        return ndacCompact_commericalModel;
      }
    }
  }

  return {
    referenceSolutionButtons,
    solutionLoad,
    updateSolutionData,
    clearStateData,
    getSegmentList,
    getSegmentDetails,
    setNDACCompact
  }
}