import { Checkbox, HorizontalDivider, Label, Typography } from '@nokia-csf-uxr/ccfk';
import { ExpansionPanel, ExpansionPanelBody, ExpansionPanelButton, ExpansionPanelHeader, ExpansionPanelTitle } from '@nokia-csf-uxr/ccfk/ExpansionPanels';
import ExpansionPanelsContainer from '@nokia-csf-uxr/ccfk/ExpansionPanels/ExpansionPanelsContainer';
import { EmptyState } from 'Components/Content/EmptyState';
import { RootState } from 'Store/mainStore';
import { iterate, roundCount, updateExistingProduct } from 'Utils';
import PropTypes from 'prop-types';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
interface IProductKitContents {
    productId: string,
    description: string,
    quantity: string,
}

export interface IProduct {
    product_name: string,
    list_price: string,
    product_id: string,
    extensionData?: Array<IProduct>,
    product_kit_contents: Array<IProductKitContents>,
    extensions: { extensionData: Array<IProduct> }
}
interface IProductList {
    products: Array<IProduct>,
    getCatalog?: Function,
    getSelectedProduct?: Function, // returns the product which is selected to be added to prepare cart
}
const ProductList = (props: IProductList) => {
    const lineRefs = useRef([]);
    lineRefs.current = props.products.length > 0 && props.products.map((_, i) => lineRefs.current[i] ?? createRef());
    const cartBoqSelector = useSelector((state: RootState) => state.cartBoq);
    const [expandedProduct, SetExpandedProduct] = useState(false)
    const dispatch = useDispatch();
    const [catalog, setCatalog] = useState((cartBoqSelector.data.cart.items));
    // const connectivitySelector = useSelector((state: RootState) => state.connectivity);
    // const cartBoqSelector = useSelector((state: RootState) => state.cartBoq);
    useEffect(() => {
        setCatalog([...cartBoqSelector.data.system.prepare.catalog])
    }, [cartBoqSelector.data.system.prepare.catalog])

    const handleRemoveItem = (item) => {
        let temp = [...catalog];
        const itemIndex = temp.findIndex((e) => e.product_id === item.product_id);
        const removingQuantity = temp[itemIndex].quantity;
        if (item.hasOwnProperty('extensionData')) {
            iterate(item, (child) => {
                temp = temp.flatMap((e) => {
                    if ((e.product_id == child.product_id)) {
                        // Calculated quantity and price while remove item from selection
                        // let calculatedQty = e.quantity - child.quantity;
                        let calculatedQty = e.quantity - removingQuantity;
                        let item_cost = child.cost * calculatedQty;
                        if (calculatedQty == 0) {
                            return [];
                        }
                        return new Object(({ ...e, quantity: calculatedQty, cost: item_cost }));
                    }
                    return e;
                });
            })
        }
        let ros = temp.filter((e) => e.product_id != item.product_id)
        //temp.splice(itemIndex, 1);
        setCatalog(() => [...ros]);
        return ros;
    }
    const handleAddItem = (item) => {
        let res = updateExistingProduct(cartBoqSelector.data.system.prepare.catalog, item, "+");
        // Calculate price Foreach extensionData of the selected item
        if (item.hasOwnProperty('extensionData')) {
            item.extensionData.forEach(e => {
                const itemIndex = res.findIndex((i) => i.product_id === e.product_id);
                res[itemIndex].cost = e.cost * res[itemIndex].quantity;
            });
        }
        setCatalog(() => [...res]);
        return res;
    }
    const isExtension = (panel) => catalog.length > 0 && catalog.some((e) => e.product_id === panel.product_id) && panel.hasOwnProperty('extensionData') && Object.keys(panel?.extensionData).length > 0
    return (
        <>
            {props.products.length === 0 ? (<EmptyState msg='No Products found' />) : (<>
                <ExpansionPanelsContainer>

                    {props.products.map((panel, panelIndex) => (
                        <ExpansionPanel
                            ref={lineRefs.current[panelIndex]}
                            expanded={expandedProduct === lineRefs.current[panelIndex]}
                            key={panelIndex}
                            id={`${panelIndex}`}
                        >
                            <ExpansionPanelHeader
                                data-test="header"
                                id='expansionheader'
                                role="button"
                                style={{ justifyContent: "space-between", alignItems: 'center' }}
                            >
                                <div id="list" style={{ display: "flex", justifyContent: "space-between", width: 300 }}>
                                    <ExpansionPanelTitle id='test'>
                                        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", }}>
                                            <div id="list-heading" style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', alignContent: 'center' }}>
                                                <Checkbox

                                                    checked={catalog.length > 0 ? catalog.some((e) => e.product_id === panel.product_id) : false}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            let res = handleAddItem(panel);
                                                            props.getSelectedProduct({ type: "add", item: panel });
                                                            props.getCatalog(res);
                                                            return;
                                                        }

                                                        let res = (handleRemoveItem(panel));
                                                        props.getSelectedProduct({ type: "remove", item: panel });
                                                        props.getCatalog(res);
                                                        return;
                                                    }
                                                    } />
                                                <Typography style={{ alignSelf: 'center' }} typography='BODY' maxLines={1} title={panel.product_name}>{panel.product_name}</Typography>
                                                {/* <Label style={{ alignSelf: 'center' }}>{panel.product_name}</Label> */}
                                            </div>

                                        </div>

                                    </ExpansionPanelTitle>

                                </div>
                                <div style={{ color: "#737373", alignSelf: 'center', flexShrink: 0 }}>
                                    <Typography style={{ alignSelf: 'center' }} typography='BODY' maxLines={1} title={panel.product_id}>{panel.product_id}</Typography>
                                </div>

                                <div style={{ marginLeft: '1rem' }} >
                                    {/*   //TODO :Fix this */}
                                    <Typography style={{ alignSelf: 'center' }} typography='BODY' maxLines={1} >{roundCount((panel.list_price))}</Typography>
                                </div>
                                {
                                    (panel.hasOwnProperty('product_kit_contents') || isExtension(panel)) && (<ExpansionPanelButton onClick={() => { (expandedProduct === lineRefs.current[panelIndex]) ? SetExpandedProduct(false) : SetExpandedProduct(lineRefs.current[panelIndex]) }} style={{ flexGrow: `1` }} />)

                                }
                            </ExpansionPanelHeader>

                            {panel.hasOwnProperty('product_kit_contents') && panel?.product_kit_contents.length > 0 &&
                                (<>
                                    <ExpansionPanelBody className='m p' >
                                        <Label>Kit Contents</Label>
                                        <HorizontalDivider className='mb mt' />
                                        {
                                            panel.product_kit_contents.map((e, i) => {
                                                return (
                                                    <Typography key={i} typography="BODY">{e.quantity} x {e.productId} - {e.description}</Typography>
                                                )
                                            })
                                        }
                                    </ExpansionPanelBody>

                                </>)
                            }

                            {isExtension(panel) &&
                                (<>
                                    <ExpansionPanelBody className='m p'  >
                                        <Label>Optional</Label>
                                        <HorizontalDivider className='mb mt' />
                                        {
                                            panel.extensionData.map((ext, i) => {
                                                return (
                                                    <ExpansionPanelTitle id='test' key={i}>
                                                        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", }}>
                                                            <div id="list-heading" style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                                                                <Checkbox
                                                                    checked={catalog.length > 0 ? catalog.some((e) => e.product_id === ext.product_id) : false}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            let res = handleAddItem(ext);
                                                                            props.getCatalog(res);
                                                                            return;
                                                                        }
                                                                        let res = (handleRemoveItem(ext));
                                                                        props.getCatalog(res);
                                                                        return;
                                                                    }
                                                                    } />
                                                                <Label style={{ alignSelf: 'center' }}>{ext.product_name}</Label>
                                                            </div>
                                                            <div style={{ color: "#737373", alignSelf: 'ycenter' }}>{ext.product_id}</div>
                                                        </div>

                                                    </ExpansionPanelTitle>
                                                )
                                            })
                                        }
                                    </ExpansionPanelBody>

                                </>)
                            }

                        </ExpansionPanel>

                    ))}
                </ExpansionPanelsContainer></>)
            }

        </>
    )
}

export default React.memo(ProductList);
ProductList.propTypes = {
    products: PropTypes.arrayOf(PropTypes.object),
    getCatalog: PropTypes.func,
}