// import { systemStyle } from 'Constants/StyleLayer'
import React from 'react'
// import OptionsFrame from '../OptionsFrame'
// import ViewFrame from '../ViewFrame'
// import Options from './Options'
// import View from './View'

import OptionsFrame from 'Components/Content/configurationArea/OptionsFrame'
import ViewFrame from 'Components/Content/configurationArea/ViewFrame'
import Options from 'Components/Content/configurationArea/Warranty/Options'
import View from 'Components/Content/configurationArea/Warranty/View'
import Frame from 'Components/Content/configurationArea/Frame';

const WarrantyIndex = () => {
    return (
        <>
 
            <Frame>
                <OptionsFrame title='SLA & Warranty'>
                    <Options />
                </OptionsFrame>
                <ViewFrame>
                    <View />
                </ViewFrame>
            </Frame>
        </>
    )
}

export default WarrantyIndex