import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton, Typography } from "@nokia-csf-uxr/ccfk";
import OOT_API from "Constants/services/oot";
import LaunchPad, {
  LaunchPadTile,
  LaunchPadTileIcon,
  LaunchPadTileSubtitle,
} from "@nokia-csf-uxr/ccfk/LaunchPad";
import { systemStyle } from "Constants/StyleLayer";
import { setProp } from "Store/Actions";
import { setConfigSegmentSoln, setError } from "Store/auth";
import { RootState } from "Store/mainStore";
import SolutionsTab from "./configurator_solutions/solutionsTab";
import { useSolutionLoad } from "../Configurator/hooks/useSolutionLoad";
import { setDataConfig } from "Store/contentState/configuration_stepperSlice";
import { fullMiningType } from "./system";
import _ from "lodash";

const Options = () => {
  /** Selectors */
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
  const businessSelector = useSelector((state: RootState) => state.business);
  const configStepperSelector = useSelector((state: RootState) => state.config_stepper);
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const [segementSelect, setSegementSelect] = useState(businessSelector.data.options.PTDAC.caseIndustry)
  const isSegmentListLoading = useSelector((state: RootState) => state.config_stepper.data.isSegmentListLoading);
  const solutionCategory = configStepperSelector.data.solutionCategory;
  /** States */
  const dispatch = useDispatch();
  const { clearStateData, updateSolutionData,solutionLoad,getSegmentList } = useSolutionLoad();

  /** setSolution */
  const setSolution = useCallback((csId) => {
    updateSolutionData(csId);
  }, [authSelector.system.configuratorSolution]);

  useEffect(() => {
    let isApiSub = true;
    // get the products from OOT and store in redux to use in all steps
    if (["mining","reference_solution","manufacturing"].includes(businessSelector.data.options.PTDAC.caseIndustry)) {
      getProductListDetails(isApiSub);
    }
    return () => {
      //cancel the sub
      isApiSub = false;
    }
  }, [(["mining","reference_solution","manufacturing"].includes(businessSelector.data.options.PTDAC.caseIndustry))]);

  // Load the segment list on change of caseIndustry
  useEffect(()=>{
    if(businessSelector.data.options.PTDAC.caseIndustry && 
      businessSelector.data.options.PTDAC.caseIndustry.length !== 0){
      (async ()=>{
        dispatch(setDataConfig({ key: "isSegmentListLoading", value: true }));
        await getSegmentList();        
      })();
    }    
  },[businessSelector.data.options.PTDAC.caseIndustry]);

  
  // On FullSolutions tabSelect change and  tabSelect = "Full Solutions"
  useEffect(()=>{
    if((businessSelector.data.options.PTDAC.caseIndustry === 'reference_solution') &&
      dynamicSelector.data.tabSelect === "Full Solutions") {
        const selectedRefSolution = dynamicSelector.data.reference_solution.solution;
        let csId = `${selectedRefSolution.toLowerCase()}_${businessSelector.data.options.PTDAC.caseIndustry}`;
        // Hardcoded for ndac compact ( reference_solution > full solution > ndac)
        if(dynamicSelector.data.reference_solution.commercial_model === 'operating_lease') {
          csId = "ndac_compact";
        }
        updateSolutionData(csId);
    }
    if(businessSelector.data.options.PTDAC.caseIndustry === 'mining'){ 
      //&& dynamicSelector.data.tabSelect === "Full Solutions"){
        // Exclude mpw solution for mining
        // Temporrary condition
      const __miningTypeName = fullMiningType.byIds[businessSelector.data.options.PTDAC.ndac_ref.fullMiningType]["name"];
      const fullSolType = businessSelector.data.options.PTDAC.ndac_ref.fullSolType;
      const csId = (fullSolType !== 'mpw') ? `cdm_${__miningTypeName}_${fullSolType}` : undefined;
      if(csId){
        updateSolutionData(csId);
      }
    }
    if(_.includes(["manufacturing"],businessSelector.data.options.PTDAC.caseIndustry)) {
      const fullSolType = businessSelector.data.options.PTDAC.ndac_ref.fullSolType;
      const csId = (fullSolType !== 'mpw') ? `pwml` : undefined;
      if(csId){
        updateSolutionData(csId);
      }
    }
    
  },[dynamicSelector.data.tabSelect,authSelector.system.configuratorSolution])

  /** setSegment */
  const setSegment = async (cs) => {
    clearStateData();
    dispatch(
      setProp({
        slice: "business",
        key: "options.PTDAC.caseIndustry",
        value: cs.id,
      })
    );
    dispatch(setConfigSegmentSoln([]));
    // if segment is other than port then api call for solutions
    // if (cs.id !== 'port') {
    //   setSegementSelect(cs.id);
    //   let csId = "";
    //   // Logic for Mining
    //   if(cs.id == 'mining'){
    //     // Preparing segment name
    //     const __miningTypeName = fullMiningType.byIds[businessSelector.data.options.PTDAC.ndac_ref.fullMiningType]["name"];
    //     const fullSolTypeName = businessSelector.data.options.PTDAC.ndac_ref.fullSolType;
    //     csId = `cdm_${__miningTypeName}_${fullSolTypeName}`;
    //     dispatch(
    //       setProp({
    //         slice: "dynamic",
    //         key: "reference_solution.solution",
    //         value: "NDAC"
    //       })
    //     );
    //   }
      // if segment selected is Reference solution / mining
      // if (cs.id === 'reference_solution' || cs.id === "manufacturing") {
      //   csId = cs.id === 'reference_solution' ? "ndac_reference_solution" : "pwml";
      //   // Set reference_solution.solution as NDAC for cs.id === 'reference_solution'
      //   dispatch(
      //     setProp({
      //       slice: "dynamic",
      //       key: "tabSelect",
      //       value: "Full Solutions"
      //     })
      //   );
      //   dispatch(
      //     setProp({
      //       slice: "dynamic",
      //       key: "reference_solution.solution",
      //       value: "NDAC"
      //     })
      //   );
      // }
      // if(['reference_solution','mining','manufacturing'].includes(cs.id)){
      //   setSolution(csId);
      // }
    // }
  }

  /** getProductListDetails */
  const getProductListDetails = async (isApiSub) => {
    try {
      // if (_.includes(['NDAC'],solutionCategory)) {
        const category = "all"
        const account = caseDetailsSelector.data.account_id
        if (isApiSub) {
          const resp = await OOT_API.getProducts(category, account, caseDetailsSelector);
          if (resp.status && resp.data) {
            // setAllProductDetailsList(resp.data)
            dispatch(setProp({ slice: "dynamic", key: 'allProducts', value: resp.data }));
          } else {
            dispatch(
              setError({
                key: "error",
                value: {
                  errorCode: resp.errorCode,
                  errorMsg: resp.errorMessage,
                },
              })
            );
          }
        }
      // }
    } catch (e) {
      dispatch(
        setError({
          key: "error",
          value: {
            errorCode: e,
            errorMsg: "Timeout",
          },
        })
      );
    } finally {
      dispatch(setError("reset"));
    }
  }
  
  return (
    <>
      <LaunchPad
        style={{ backgroundColor: "white", minHeight: "unset", marginBottom: "2rem" }}
        id="_launchPadTile"
      >
        <div style={systemStyle.genrateGridCols(3)}>
          {/* <LaunchPadContent> */}
          {authSelector.system.segmentSolution.map((cs, index) => {
            return (
              <LaunchPadTile
                key={index}
                onClick={() => setSegment(cs)}
                style={{
                  border: `${businessSelector.data.options.PTDAC.caseIndustry === cs.id
                    ? "2px solid #124191"
                    : "none"
                    }`,
                  height: "100%",
                  width: "100%",
                  background: "#EBEFF5"
                }}
              >
                <LaunchPadTileIcon
                  title={cs.description}
                  style={{ transform: "scale(2.0)", display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <img src={cs.svg} alt={cs.id} height="15"></img>
                </LaunchPadTileIcon>
                <LaunchPadTileSubtitle id="csname" title={cs.name}>
                  <Typography maxLines={1} typography="CAPTION">
                    {cs.name}
                  </Typography>
                </LaunchPadTileSubtitle>
              </LaunchPadTile>
            );
          })}
          {/* </LaunchPadContent> */}
        </div>
      </LaunchPad>
      {
        ["mining","reference_solution","manufacturing"].includes(businessSelector.data.options.PTDAC.caseIndustry) && isSegmentListLoading && (
          <div style={{ display: 'flex', alignItems: "center", flexDirection: "column" }}>
            <Skeleton style={{ width: '400px' }} />
          </div>
        )
      }      
      {
        ["mining","reference_solution","manufacturing"].includes(businessSelector.data.options.PTDAC.caseIndustry) && !isSegmentListLoading &&(
          <SolutionsTab setSolution={setSolution} solutionSelected={configStepperSelector.data.solution_selected} />
        )
      }
    </>
  );
};

export default Options;
