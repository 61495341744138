import CartBoq from 'Components/Content/CartBoq'
import React from 'react'

const View = () => {
  return (
      <>
      <CartBoq />
      </>
  )
}

export default View