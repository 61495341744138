import React from "react";
import { HorizontalDivider } from "@nokia-csf-uxr/ccfk";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import { RootState, store } from "Store/mainStore";
import { useSelector, useStore } from "react-redux";
import { useConfigurator } from "./useConfigurator";
import { useSeachBlock } from "./useSearchBlock";
import { convertIdToLabel } from "Utils";
import { useLoadUI } from "./useLoadUI";
import _ from 'lodash';
import MXIEServerConsumptionChart from "../Components/MXIEServerConsumptionChart";
import { useLocalStateManagement } from "./useLocalStateManagement";

export const useRenderDynamicElements = (allRefs) => {
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const { renderSearchBlock } = useSeachBlock(allRefs);
  const {
    getContainerBasedElements
  } = useConfigurator();
  const { LoadElements } = useLoadUI(allRefs);
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const { setState, getState } = useLocalStateManagement();
  const solutionCategory = configstepperSelector.data.solutionCategory;
  
  /** getContentBlock */
  const getContentBlock = (activePackages, deviceBlock) => {
    let contentBlock = undefined;
    if (deviceBlock) {
      contentBlock = {};
      const filteredContent = _.filter(activePackages, (packageEle) => !_.some(deviceBlock, (search) => search.id === packageEle.id));
      filteredContent.forEach(content => {
        contentBlock[content.id] = content;
      });
    }
    return contentBlock;
  }

  const showContainerLabel = (containerData) => {
    let containerFields = {...containerData.field_details};
    // let markupData = _.find(_.values(containerFields),{type:'markup'});
    // check if item is markup and not subtype chart (MXIE chart)
    let markupData = _.find(_.values(containerFields),(obj) => {
      if(obj.type === 'markup' && obj.subtype === undefined) {
        return obj;
      }
    });
    let markupKey = _.keys(containerFields);
    let showcontainerLabel = "true";
    if(markupData) {
      let tempMarkup = {...markupData};
      showcontainerLabel = "false";
      tempMarkup['label'] = convertIdToLabel(containerData.id);
      markupData = {...tempMarkup};
      containerFields = {[markupKey[0]] : markupData};
    }
    return [showcontainerLabel,containerFields];
  }

  /** renderDynamicContent */
  const renderDynamicContent = (activePackages,isDirectFields=false,renderConfig=undefined) => {
    // Prepare dataStore for all the dynamic component
    const dataStore = renderConfig && !_.isEmpty(renderConfig) && renderConfig.dataStore ? renderConfig.dataStore : 
    store.getState().dynamic.data.element[configstepperSelector.data.active_step];
    //dynamicSelector.data.element[configstepperSelector.data.active_step];
    let newRenderConfig = {}
    if(renderConfig){
      newRenderConfig = _.cloneDeep(renderConfig);
    }
    newRenderConfig["dataStore"] = dataStore;    
    
    let containerElememts = getContainerBasedElements(configstepperSelector.data.active_step);
    const deviceBlock = dynamicSelector.data.dataFromPackage['deviceBlock'];
    const contentBlock = !isDirectFields ? getContentBlock(activePackages, deviceBlock) : activePackages;
    let shouldRenderSearchBlock = deviceBlock && Object.entries(JSON.parse(JSON.stringify(deviceBlock))).length !== 0 && (_.includes(["NDAC","MPW"],solutionCategory))
    
    if(solutionCategory === 'MPW' && configstepperSelector.data.active_step === 'connectivity') {
      shouldRenderSearchBlock = false;
    }

    return (
      <>
        {/** Search block */}
        {shouldRenderSearchBlock  && renderSearchBlock(activePackages,newRenderConfig) }
        {/** Search block */}

        {/** Container blocks */}
        {!isDirectFields && containerElememts.length > 0 ? (
          containerElememts.map((e) => {
            const showLabel = showContainerLabel(e);
            const dynamicEle = JSON.parse(JSON.stringify(showLabel[1]));
            return (
              <div key={e.id} style={{ margin: "10px 0" }}>
                {showLabel[0] === 'true' && <TextInputLabelContent style={{ marginBottom: "10px" }}>{convertIdToLabel(e.id)}</TextInputLabelContent> }
                <>{LoadElements(dynamicEle, activePackages,newRenderConfig)}</>
                <HorizontalDivider className="mb mt" />
              </div>
            );
          })
        ) : (contentBlock && LoadElements(contentBlock, activePackages,newRenderConfig))
        }
        {getState("consumptionGraph") && configstepperSelector.data.active_step === 'mxie' && <MXIEServerConsumptionChart />}
      </>
    )
  }

  return {
    renderDynamicContent
  }
}