export const useFormatters = () => {

  const checkValue = (fieldValue) => {
    if (fieldValue !== undefined) {
      return fieldValue;
    }
    return ""
  }

  const getSelectOptions = (options) => {
    let data_list = []
    if (options) {
      Object.keys(options).map(key => {
        data_list.push({
          id: key,
          value: options[key]
        })
      })
    }
    return data_list
  }

  const getKeyvalueObj = (optionList) => {
    const arrayOfObjects = Object.entries(optionList).map(([key, value]) => (
      {
        key: value,
        value,
      }))
    return arrayOfObjects;
  }

  return {
    checkValue,
    getSelectOptions,
    getKeyvalueObj
  }
}
