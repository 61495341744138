import { MultiSelectSmartCheckboxList, MultiSelectSmartListDivider } from '@nokia-csf-uxr/ccfk/MultiSelectSmartList';
import { TextInputLabelContent } from '@nokia-csf-uxr/ccfk/TextInput';
import PropTypes from 'prop-types';
import React from 'react';

interface IMultiCheckBoxListProps {
    title: string,
    children: React.ReactNode
}
const MultiCheckBoxList = (props: IMultiCheckBoxListProps) => {
    return (
        <>
        
            <TextInputLabelContent >{props.title}</TextInputLabelContent>
            <div className=''>
            <MultiSelectSmartCheckboxList >
                <MultiSelectSmartListDivider />
                {
                    props.children
                }
            </MultiSelectSmartCheckboxList>
          </div>

        </>
    )
}

export default React.memo(MultiCheckBoxList);

MultiCheckBoxList.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,

}