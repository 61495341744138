import { useDispatch, useSelector } from "react-redux";
import { useConfigurator } from "./useConfigurator";
import { RootState } from "Store/mainStore";
import { useStatesOfElement } from "./useStatesOfElement";
import React from "react";

export const useActivePackages = () => {
  const {
    getPackageFieldList,
    preparePackageElementContent,
    getListOfFields,
    getAllFieldsInPackage,
    getAllPackageFields
  } = useConfigurator();

  let deviceBlock = {};
  const configstepperSelector = useSelector((state: RootState) => state.config_stepper);
  const allRefs = React.useRef([]);
  const { getElementsStates } = useStatesOfElement(allRefs);
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
  const findCaseSelector = useSelector((state: RootState) => state.findCase);
  const dispatch = useDispatch();
  

  /** getBlockContent */
  const getBlockContentData = (packageDataDetails) => {
    const { dB, jD } = preparePackageElementContent(packageDataDetails);
    deviceBlock = { ...dB };
    return JSON.parse(JSON.stringify(jD));
  };

  const getDeviceBlockData = () => {
    return deviceBlock;
  }

  const getActivePackagesData = () => {
    const packageData = getPackageFieldList(configstepperSelector.data.active_step);
    const activePackages = getBlockContentData(packageData);
    return activePackages;
  }

  const getSelectors = () => {
    const selectors = {
      dynamic: dynamicSelector,
      config_stepper: configstepperSelector,
      caseDetails: caseDetailsSelector,
      findCase: findCaseSelector,
      dispatch: dispatch,
      getElementsStates: getElementsStates,
      getListOfFields: getListOfFields,
      getAllPackageFields: getAllPackageFields(),
      getAllFieldsInPackage : getAllFieldsInPackage
    };
    return selectors;
  }

  return {
    getBlockContentData,
    getActivePackagesData,
    getDeviceBlockData,
    getSelectors
  }
}