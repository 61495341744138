import { setProp } from "Store/Actions";
import { RootState, store } from "Store/mainStore";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useLocalStateManagement = () => {
  /** Component states */
  const dispatch = useDispatch();
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const setState = (statename, value) => {
    if(dynamicSelector.data.localStates[statename] !== value) {
      // const stateCopy = {...dynamicSelector.data.localStates};
      const reduxstore = store.getState();
      const stateCopy = {...reduxstore.dynamic.data.localStates}
      stateCopy[statename] = value;
      dispatch(setProp({ slice: "dynamic", key: `localStates`, value: stateCopy }));
    }
  }

  const getState = (statename) => {
    const cleanName = 'set' + statename.charAt(0).toUpperCase() + statename.slice(1);
    const reduxstore = store.getState();
    return reduxstore.dynamic.data.localStates[cleanName];
  }


  const setRef = (allRefs, item_id, value) => {
    // console.log("set ref" ,item_id, value)
    allRefs.current[item_id] = value;
  }

  const getRef = (allRefs, item_id) => {
    return allRefs.current[item_id];
  }

  return {
    setState,
    getState,
    setRef,
    getRef
  }
}