import React, { useState, useEffect } from "react";
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import TextInput, { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import { HelpText } from "../hooks/useHelpText";

// -------- This should be common interface and re-use
interface DataList {
    data: Array<any>,
    active_step: string,
    updateDispatchStore?: Function
    isDisabled: boolean
    errorMessageText?: Function,
    ref:object,
    dataStore?:Object
}

const TextField = (props: DataList) => {
    // const dynamicSelector = useSelector((state: RootState) => state.dynamic);
    const label = props.data[1].required ? props.data[1].label + ' *' : props.data[1].label
    const [errorDisabled, setErrorDisabled] = useState(props.data[1].required);
    const disableState = props.data[1].readonly ? props.data[1].readonly : props.isDisabled;
    const {
        showHelpText
    } = HelpText();

    useEffect(() => {
        if (props.data[1].required) {
            // if (dynamicSelector.data.element[props.active_step]) {
            if (props.dataStore) {
                if (props.dataStore[props.data[0]] !== "") {
                    setErrorDisabled(false)
                }
                else {
                    setErrorDisabled(true)
                }
            }
        }
    }, [props.dataStore])

    const getKeyvalueData = (dataSet,selectkey) => {
        let selectKeyValue = {}
        selectKeyValue['key'] = selectkey
        selectKeyValue['value'] = selectkey
        props.updateDispatchStore(dataSet,selectKeyValue);
    }

    return (
        <>
            <div style={{ marginTop: '10px' }}>
                <div style={{ display: 'flex'}}>
                    <TextInputLabelContent>
                        {label}
                    </TextInputLabelContent>
                    {showHelpText(props.data)}
                </div>
                <TextInput
                    ref={props.ref as React.Ref<HTMLElement>}
                    id={props.data[1].id}
                    style={{ width: "100%"}}
                    // value={dynamicSelector.data.element[props.active_step] ? dynamicSelector.data.element[props.active_step][props.data[0]].key : ''}
                    value={props.dataStore ? props.dataStore[props.data[0]].key : ''}
                    disabled={disableState}
                    error={errorDisabled}
                    errorMessage={errorDisabled ? props.errorMessageText(props.data[1].label) : ""}
                    onChange={(e) => {
                        getKeyvalueData(props.data[0],e.target.value)
                    }
                    }
                />
            </div>
        </>
    )
}

export default TextField;