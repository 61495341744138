import {
  Button,
  ButtonsRow,
  HorizontalDivider
} from "@nokia-csf-uxr/ccfk";
import ChevronLeftIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ChevronLeftIcon';
import ChevronRightIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ChevronRightIcon';
import { ButtonIcon, ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import StepForm, {
  Step,
  StepConfigurationArea,
  StepFormArea
} from "@nokia-csf-uxr/ccfk/StepForm";
import BusinessIndex from "Components/Content/configurationArea/Business";
import DevicesIndex from "Components/Content/configurationArea/Devices";
import ApplicationsIndex from "Components/Content/configurationArea/PTDAC/Applications";
import ConnectivityIndex from "Components/Content/configurationArea/PTDAC/Connectivity";
import EdgeIndex from "Components/Content/configurationArea/PTDAC/Edge";
import ReviewIndex from "Components/Content/configurationArea/Review";
import ServicesIndex from "Components/Content/configurationArea/Services";
import WarrantyIndex from "Components/Content/configurationArea/Warranty";
import { showModal } from "Store/auth";
import { RootState } from "Store/mainStore";
import { serializeFunction } from "Utils";
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
enum StepStatus { current = 'current', future = 'future', error = 'error', warning = 'warning', completed = 'completed' };
interface IStepperProps {
  onSubmit: Function,
  onNext: Function,
  onBack: Function,
  isDisable: boolean,
  getStep?: Function,
}
const Stepper = (props: IStepperProps) => {
  const dispatch = useDispatch();
  const businessSelector = useSelector((state: RootState) => state.business);
  const stepData = businessSelector.data.options.PTDAC.caseIndustry === "port" ? [
    { name: 'Business', stepNumber: 1, status: 'current', isComplete: false, selected: true, isValid: true, id: 'business' },
    { name: 'Applications', stepNumber: 2, status: 'future', isComplete: false, details: null, isValid: true, id: 'applications' },
    { name: 'Connectivity', stepNumber: 3, status: 'future', isComplete: false, isValid: true, id: 'connectivity' },
    { name: 'Edge (MXIE)', stepNumber: 4, status: 'future', isComplete: false, isValid: true, id: 'edge' },
    { name: 'Devices', stepNumber: 5, status: 'future', isComplete: false, isValid: true, id: 'devices' },
    { name: 'Services', stepNumber: 6, status: 'future', isComplete: false, isValid: true, id: 'services' },
    { name: 'SLA & Warranty', stepNumber: 7, status: 'future', isComplete: false, isValid: true, id: 'warranty' },
    { name: 'Review & Submit', stepNumber: 8, status: 'future', isComplete: false, isValid: true, id: 'review' },
  ]
  :
  [{ name: 'Business', stepNumber: 1, status: 'current', isComplete: false, selected: true, isValid: true, id: 'business' }];
  const [steps, setSteps] = useState(stepData);

  useEffect(()=>{
    const stepData = businessSelector.data.options.PTDAC.caseIndustry === "port" ? [
      { name: 'Business', stepNumber: 1, status: 'current', isComplete: false, selected: true, isValid: true, id: 'business' },
      { name: 'Applications', stepNumber: 2, status: 'future', isComplete: false, details: null, isValid: true, id: 'applications' },
      { name: 'Connectivity', stepNumber: 3, status: 'future', isComplete: false, isValid: true, id: 'connectivity' },
      { name: 'Edge (MXIE)', stepNumber: 4, status: 'future', isComplete: false, isValid: true, id: 'edge' },
      { name: 'Devices', stepNumber: 5, status: 'future', isComplete: false, isValid: true, id: 'devices' },
      { name: 'Services', stepNumber: 6, status: 'future', isComplete: false, isValid: true, id: 'services' },
      { name: 'SLA & Warranty', stepNumber: 7, status: 'future', isComplete: false, isValid: true, id: 'warranty' },
      { name: 'Review & Submit', stepNumber: 8, status: 'future', isComplete: false, isValid: true, id: 'review' },
    ]
    :
    [{ name: 'Business', stepNumber: 1, status: 'current', isComplete: false, selected: true, isValid: true, id: 'business' }];

    setSteps(()=>stepData)
  },[businessSelector.data.options.PTDAC.caseIndustry]);


  const getStepIndex = useCallback((stepName) => steps.findIndex(item => (item.name === stepName)), [steps]);
  const getStep = useCallback((stepName) => steps.find(item => (item.name === stepName)), [steps]);

  const getConfigurationArea = useCallback((step) => {
    const currentStep = getStep(getCurrentStepName())
    props.getStep({ index: getStepIndex(getCurrentStepName()), step: currentStep });
    let configurationArea = null;
    switch (step) {
      case 'Business':
        configurationArea = <BusinessIndex />;
        break;
      case 'Applications':
        configurationArea = <ApplicationsIndex />;
        break;
      case 'Connectivity':
        configurationArea = <ConnectivityIndex />;
        break;
      case 'Edge (MXIE)':
        configurationArea = <EdgeIndex />;
        break;
      case 'Devices':
        configurationArea = <DevicesIndex />;
        break;
      case 'Services':
        configurationArea = <ServicesIndex />;
        break;
      case 'SLA & Warranty':
        configurationArea = <WarrantyIndex />;
        break;
      case 'Review & Submit':
        configurationArea = <ReviewIndex />;
        break;
      default:
        configurationArea = null;
    }

    return configurationArea;
  }, [steps, getStepIndex]);
  const NEXT_STEP = 'next'; const PREVIOUS_STEP = 'previous'; const CURRENT_STEP = 'current';
  // return the current step name
  const getCurrentStepName = useCallback(() => {

    const currentStepIndex = steps.findIndex(item => (item.status === 'current'));
    if (currentStepIndex < 0) return steps[steps.length - 1].name;

    return steps[currentStepIndex].name;
  }, [steps]);
  const getIndicatorVariant = (stepName: string): StepStatus => {
    const step = steps[getStepIndex(stepName)];
    if (!step.isValid) return 'error' as StepStatus.error;
    if (step.status === 'current') return 'current' as StepStatus.current;
    if (step.isComplete) return 'completed' as StepStatus.completed;

    return step.status as StepStatus;
  };
  const currentStepArea = useMemo(() => getConfigurationArea(getCurrentStepName()), [getConfigurationArea, getCurrentStepName]);
  // validate current state and move to next step if validation succeeds
  const handleContinue = () => {
    const currentStepName = getCurrentStepName();
    updateCurrentStep(currentStepName, NEXT_STEP);
    props.onNext();

  };
  // return to previous step
  const handleBack = () => {
    const currentStepName = getCurrentStepName();
    // Validate step data if this step was previously complete
    // const currentStepIndex = steps.findIndex(item => (item.status === 'current'));

    // if (steps[currentStepIndex].isComplete) {
    //   // const isValidated = validateConfigurationStep(currentStepName);
    //   // if (!isValidated) return;
    // }
    // Set current step complete and set previous step to be next step target
    updateCurrentStep(currentStepName, PREVIOUS_STEP, false);
    props.onBack();
  };

 

  const handleActivate = (event) => {
    // validate all steps one last time
    // const isValidated = areaRef && areaRef.current && areaRef.current.validate();
    // if (!isValidated) return;
    // all steps validate successfully, set last step complete
    const currentStepName = getCurrentStepName();
    //updateCurrentStep(currentStepName, CURRENT_STEP);
    // dispatch(setCompleted(true));
    props.onSubmit();
  };
  // move current status to the appropriate step; only 1 step can be current
  const updateCurrentStep = (currentStepName, whichStep, updateCompletedStatus = true) => {
    const currentStepIndex = getStepIndex(currentStepName);
    // remove status = current from all steps
    const newSteps = steps.map((item) => {
      return {
        ...item,
        status: item.name !== currentStepName ? item.status : updateCompletedStatus ? 'completed' : 'future',
        isComplete: item.isComplete || (item.name === currentStepName && whichStep === NEXT_STEP),
        selected: false,
      };
    });
    // make sure current step status is set to valid
    newSteps[currentStepIndex].isValid = true;
    // Set the next current step
    let newSelectedStep = currentStepIndex;
    if (whichStep === NEXT_STEP) {
      newSelectedStep = getNextStepIndex(currentStepIndex);
      newSteps[newSelectedStep].status = 'current';
      newSteps[newSelectedStep].selected = true;
    } else if (whichStep === PREVIOUS_STEP) {
      newSelectedStep = getPreviousStepIndex(currentStepIndex);
      newSteps[newSelectedStep].status = 'current';
      newSteps[newSelectedStep].selected = true;
    }
    localStorage.setItem('currentStepName', newSteps[newSelectedStep].name);


    setSteps(newSteps as any);
    //  dispatch(setSteps(newSteps));
  };
  const getNextStepIndex = (currentStepIndex) => {
    const nextStepIndex = currentStepIndex + 1;
    if (nextStepIndex >= steps.length) {
      return currentStepIndex;
    }
    return nextStepIndex;
  };
  const getPreviousStepIndex = (currentStepIndex) => {
    const previousStepIndex = currentStepIndex - 1;
    if (previousStepIndex < 0) {
      return currentStepIndex;
    }
    return previousStepIndex;
  };


  const isActivate = businessSelector.data.options.PTDAC.caseIndustry !== "port" || businessSelector.data.view.PTDAC.additionalFeature.length>0 || getCurrentStepName() === 'Review & Submit' ? true : false;

  return (
    <>
      <div className="stepFormSegment">
        <StepFormArea >
          <StepForm style={{ width: '20%' }}>
            {steps.map((item, x) => {
              return (
                <Step
                  key={`${item.name}-${x}`}
                  variant={getIndicatorVariant(item.name)}
                  selected={item.selected}
                  stepNumber={item.stepNumber}
                  title={item.name}
                  details={item.details}
                  lastStep={x === steps.length - 1}
                />
              );
            })}
          </StepForm>
          <div style={{ width: '100%' }} >
            <StepConfigurationArea style={{ padding: '0 0 0 1rem' }}>
              {currentStepArea}
            </StepConfigurationArea>
          </div>
        </StepFormArea>
        {/* <HorizontalDivider /> */}
        <ButtonsRow>
          <Button onClick={() => dispatch(showModal({
            msg: "Are you sure you want to cancel the Case? All the changes done will be lost.",
            title: "Cancel Case",
            variant: "WARNING",
            show: true,
            action: [
              {
                label: "No",
                variant: "neutral",
                onAction: serializeFunction(() => {
                  window['store'].dispatch(window['authSlice'].showModal({ msg: "", panel: { title: '', msg: '' }, show: false, }))
                })
              },
              {
                label: "Yes",
                variant: "call-to-action",
                onAction: serializeFunction(() => {
                  window.location.href = "/";
                }),
                fullWidth: true,
              }
            ]
            //

          }))}>Cancel</Button>
          <Button onClick={() => {
            handleBack();


          }}>
            <ButtonIcon>
              <ChevronLeftIcon />
            </ButtonIcon>
            <ButtonText>
              Previous</ButtonText></Button>
          {
            isActivate ? (<Button disabled={props.isDisable} variant='call-to-action'
              onClick={handleActivate}>
              <ButtonText>
                Submit
              </ButtonText>

            </Button>) : (<Button disabled={props.isDisable} variant='call-to-action'
              onClick={() => {
                handleContinue();

              }}

            >

              <ButtonText>
                Next
              </ButtonText>
              <ButtonIcon>
                <ChevronRightIcon />
              </ButtonIcon>

            </Button>)
          }

        </ButtonsRow>
      </div >

    </>
  )
}

export default Stepper

Stepper.propType = {
  onSubmit: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  isDisable: PropTypes.bool
}