import _ from 'lodash'
import { store } from 'Store/mainStore';
const operator = {
  'equals': '===',
  'greater': '>',
  'not equals': '!=='
}

const conditions = {
  'AND': '&&',
  'OR': '||'
}

const stateMappings = {
  'visible': 'block',
  'invisible': 'none',
  'required': true,
  'optional': false,
  'enabled': false,
  'disabled': true,
  'readonly': true
}

let defaultFlag = false;
let storedFieldValue = null;

export const getStateOfElement = (fieldDetails, allfieldsList,dynamicElementFieldData?) => {
  let allStates = {
    visibility: 'block',
    required: fieldDetails.required,
    readonly: fieldDetails.readonly,
    defaultValue: "",
    disabled: false
  };

  storedFieldValue = dynamicElementFieldData;

  if (allfieldsList && !_.isEmpty(allfieldsList)) {
    // call only if there is value ( default ) conditions set for the field state
    if (fieldDetails.states.value && _.isArray(fieldDetails.states.value)) {
      // this is for value state details only where its array and not json
      for (let i = 0; i < fieldDetails.states.value.length; i++) {
        const eachState = fieldDetails.states.value[i];
        evalValueState(allStates, eachState, allfieldsList);
        if (defaultFlag) {
          break;
        }
      }
    }
    // if the state has value or doesnt have still we want this to call because we have visibility etc to check
    evalEachStateDetails(allStates, fieldDetails, allfieldsList);
  }
  return allStates;
}

const evalValueState = (allStates, states, allfieldsList) => {
  // for (let i=0;i<states.length;i++) {
  // const innerstate = states[i];
  const state_condition = states.state_condition;
  const stateDetails = states.state_details;
  let checkField = '';
  let stateDetailsLen = 0;
  if (stateDetails) {
    stateDetailsLen = stateDetails.length;
  }
  let index = 0;
  _.map(stateDetails, (eachstate) => {
    let conditional_field = allfieldsList[eachstate.field_name]?.value;
    if (conditional_field && typeof allfieldsList[eachstate.field_name]?.value === 'string') {
      conditional_field = allfieldsList[eachstate.field_name]?.value;
    }
    let eachStateValue = eachstate.value;

    if (eachStateValue && typeof eachStateValue === 'string') {
      eachStateValue = eachStateValue;
    }


    let operatorSign = operator[eachstate.operator];
    // 3. readonly if value exists
    if (eachstate.condition === 'filled') {
      let operatorvalue = "equals";
      if (eachstate.operator === "equals") {
        operatorvalue = "not equals";
      }
      operatorSign = operator[operatorvalue];
      // const checkFieldValue = eval('("' + conditional_field + '" ' + operator[operatorvalue] + '"")');
      // allStates['readonly'] = checkFieldValue ? stateMappings[eachstate.state_name] : allStates['readonly'];
    }
    if (index !== 0 && index < stateDetailsLen) {
      checkField = checkField + conditions[state_condition];
    }
    
    checkField = checkField + ' ("' + conditional_field + '" ' + operatorSign + ' "' + eachStateValue + '")';
    index = index + 1;
  });
  if (stateDetailsLen > 0) {
    checkField = checkField.length > 0 ? eval(checkField) : undefined;
    allStates = setStates(allStates, checkField, states);
  }
  // }
}

const evalEachStateDetails = (allStates, fieldDetails, allfieldsList) => {
  for (const stateData in fieldDetails.states) {
    const state_condition = fieldDetails.states[stateData].state_condition;
    const stateDetails = fieldDetails.states[stateData].state_details;
    let checkField = '';
    let stateDetailsLen = 0;
    if (stateDetails) {
      stateDetailsLen = stateDetails.length;
    }
    let index = 0;
    _.map(stateDetails, (eachstate) => {
      let conditional_field = allfieldsList[eachstate.field_name]?.key !== undefined ? allfieldsList[eachstate.field_name]?.key : allfieldsList[eachstate.field_name];
      if(_.isArray(allfieldsList[eachstate.field_name])) {
        // if multiselect is there in conditional field then we have to use this logic
        conditional_field = _.map(allfieldsList[eachstate.field_name],(eachval) => (eachval.key))
      }
      if (conditional_field && typeof conditional_field === 'string') {
        conditional_field = (conditional_field)
      }
      let eachStateValue = eachstate.value;

      if (eachStateValue && typeof eachStateValue === 'string') {
        eachStateValue = eachStateValue;
      }


      let operatorSign = operator[eachstate.operator];
      // 3. readonly if value exists
      if (eachstate.condition === 'filled') {
        let operatorvalue = "equals";
        if (eachstate.operator === "equals") {
          operatorvalue = "not equals";
        }
        operatorSign = operator[operatorvalue];
        // const checkFieldValue = eval('("' + conditional_field + '" ' + operator[operatorvalue] + '"")');
        // allStates['readonly'] = checkFieldValue ? stateMappings[eachstate.state_name] : allStates['readonly'];
      }
      if (index !== 0 && index < stateDetailsLen) {
        checkField = checkField + " " + conditions[state_condition];
      }
      if(_.isArray(allfieldsList[eachstate.field_name])) {
        // if multiselect is there in conditional field then we have to use this logic
        // need to make this operator logic more proper
        operatorSign  = eachstate.operator === 'equals' ? '_.includes' : '!_.includes';
        let conditionalFieldString = JSON.stringify(conditional_field);
        checkField = checkField + ' (' + operatorSign + '(' + conditionalFieldString + ', "' + eachStateValue + '"))';
      } else {
        checkField = checkField + ' ("' + conditional_field + '" ' + operatorSign + ' "' + eachStateValue + '")';
      }
      index = index + 1;
    });
    if (stateDetailsLen > 0) {
      checkField = checkField.length > 0 ? eval(checkField) : undefined;
      allStates = setStates(allStates, checkField, fieldDetails.states[stateData], fieldDetails.required);
    }
  }
}

export const getCheckField = (fieldDetails, stateData,updatedData?,allFieldData?,item?) => {
  const state_condition = fieldDetails.states[stateData].state_condition;
  const stateDetails = fieldDetails.states[stateData].state_details;
  let checkField = '';
  let stateDetailsLen = 0;
  if (stateDetails) {
    stateDetailsLen = stateDetails.length;
  }
  let index = 0;
  _.map(stateDetails, (eachstate) => {
    //Imp Note : if the field we are checking in all fields is same as what has been sent, then dont pick from dynamic data but pick from the latest value sent in arguments, as still dynamic store wont be updated with selected value else if its not same then pick from the dynamic store
    if(eachstate.field_name !== item) {
      const dynamicFieldData = allFieldData && allFieldData[eachstate.field_name];
      if(dynamicFieldData) {
        updatedData = dynamicFieldData;
      }
    }
    let conditional_field = updatedData && updatedData.value && typeof(updatedData.value) === 'string' && (updatedData.value);
    if(_.isArray(updatedData)) {
      // if multiselect is there in conditional field then we have to use this logic
      conditional_field = _.map(updatedData,(eachval) => (eachval.value))
    }
    let operatorSign = operator[eachstate.operator];
    // 3. readonly if value exists
    if (eachstate.condition === 'filled') {
      let operatorvalue = "equals";
      if (eachstate.operator === "equals") {
        operatorvalue = "not equals";
      }
      operatorSign = operator[operatorvalue];
    }
    if (index !== 0 && index < stateDetailsLen) {
      checkField = checkField + " " + conditions[state_condition];
    }
    const matchingData = (eachstate.value);
    if(_.isArray(updatedData)) {
      // if multiselect is there in conditional field then we have to use this logic
      // need to make this operator logic more proper
      operatorSign  = eachstate.operator === 'equals' ? '_.includes' : '!_.includes';
      let conditionalFieldString = JSON.stringify(conditional_field);
      checkField = checkField + ' (' + operatorSign + '(' + conditionalFieldString + ', "' + matchingData + '"))';
    } else {
      checkField = checkField + ' ("' + conditional_field + '" ' + operatorSign + ' "' + matchingData + '")';
    }
    index = index + 1;
  });
  return checkField;
}

export const setStates = (allStates, checkField, state_data, required?) => {
  const state_name = state_data.state_name;
  // 1. visibile / invisible
  if (state_name === 'visible') {
    allStates['visibility'] = stateMappings[state_name];
    if (!checkField) {
      allStates['visibility'] = 'none';
      allStates['required'] = false;
    }
  } else if(state_name === 'invisible') {
    allStates['visibility'] = stateMappings[state_name];
    if (!checkField) {
      allStates['visibility'] = 'block';
      allStates['required'] = false;
    }
  }

  // 2. required
  // if (eachstate.condition === 'value' && (eachstate.state_name === 'required' || eachstate.state_name === 'optional')) {
  if ((state_name === 'required' || state_name === 'optional')) {
    allStates['required'] = checkField ? stateMappings[state_name] : required;
  }

  //3. readOnly
  if (state_name === 'readonly') {
    allStates['readonly'] = checkField ? stateMappings[state_name] : allStates['readonly'];
  }

  // 4. enable disable
  if (state_name === 'enabled' || state_name === 'disabled') {
    allStates['disabled'] = checkField ? stateMappings[state_name] : allStates['disabled'];
  }
  //4. default value
  if (state_name === 'value') {
    // if the states value matches the value of selected item
    allStates['defaultValue'] = "";
    if(checkField) {
      if(state_data && state_data.state_value) {
        allStates['defaultValue'] = state_data.state_value;
      } else {
        // default value is not static and it has a datacallback
        allStates['defaultValue'] = {'state_callback' : state_data.state_callback};
      }
    } 
    // else if(state_data && state_data.state_value &&  storedFieldValue && storedFieldValue.key) {
    //   console.log(" field toggle value : ",storedFieldValue,storedFieldValue.key);
    //   // reset to null for toggle when there is no match, when parent toggle is off
    //   allStates['defaultValue'] = 'null';
    // }
    defaultFlag = checkField ? true : false;
  }

  return allStates;
}