// import { systemStyle } from 'Constants/StyleLayer'
import React from 'react'
import OptionsFrame from 'Components/Content/configurationArea/OptionsFrame'
import ViewFrame from 'Components/Content/configurationArea/ViewFrame'
import Options from 'Components/Content/configurationArea/Devices/Options'
import View from 'Components/Content/configurationArea/Devices/View'
import Frame from 'Components/Content/configurationArea/Frame';

const DevicesIndex = () => {
    return (
        <>
     
            <Frame>
                <OptionsFrame title='Devices'>
                    <Options />
                </OptionsFrame>
                <ViewFrame>
                    <View />
                </ViewFrame>
            </Frame>
        </>
    )
}

export default DevicesIndex