//@ts-nocheck
import { PTDAC } from ".";

/*
What is this ?
Logics to run  After Recommendations are modified.


--Interceptors---
--------------------
These are those functions which run after the recommendations are done and dusted,runs as an affter effects | Middle Panel Implementation.
*/
export class Interceptors extends PTDAC {
    constructor(store) {
        super(store);
    }
    interceptApplication() {


        const applicationLogic = this.response.applications.hasOwnProperty('getrecommendedApplicationLogic');
        const devicesLogic = this.response.applications.hasOwnProperty('getrecommendedDevicesLogic');
        if ((!devicesLogic) || !applicationLogic) {
            this.response.applications['interceptor'] = { response_code: 0, response_msg: "Setting up Applications tab ", error: {} };
            return this;
        }

        if (!(this.response.applications.hasOwnProperty('getrecommendedDevicesLogic') && this.response.applications.getrecommendedDevicesLogic?.response_code)) {
            this.response.applications['interceptor'] = { response_code: 0, response_msg: "Interceptor: Application ready but not App's devices.", error: {} };
            return this;
        }
        this.response.applications.getrecommendedApplicationLogic.data.set['products'] = [];
        // STORE
        const recommendedApplicationTabResponse = this.response.applications;
        //Check For Recommendation Mutations:  weather suggest.devices are Mutated.
        if (this.store.cartBoq.data.system.prepare.suggest.devices.length == 0 && !this.store.theme.data.button.isToggle.applications_undo) {
            this.response.applications['interceptor'] = { response_code: 0, response_msg: "Interceptor: Nothing change detected", error: {} };
            return this;
        }

        //MUTATE
        let selectedDevicesApplications = [];
        //INTERCEPT-1 Update applications with same qty as devices
        this.response.applications.getrecommendedDevicesLogic.data.products = this.response.applications.getrecommendedDevicesLogic.data.products.flatMap(product => {
            // Middle is Active
            let findSuggestDevice = this.store.cartBoq.data.system.prepare.suggest.devices.filter((e) => e.product_id == product.product_id);
            if (findSuggestDevice.length == 0) {
                //Undo is Active |- Since Middle gets reseted on ATC
                findSuggestDevice = this.store.cartBoq.data.cart.items.filter((e) => e.product_id == product.product_id);
            }
            if (findSuggestDevice.length > 0) {
                return findSuggestDevice.flatMap((suggestedDevice) => {
                    const metaData = Object.fromEntries(recommendedApplicationTabResponse.getrecommendedApplicationLogic.data.set.selectedMandatoryAppMap) // devices
                    const deviceLinkedApplications = Object.values(metaData).filter((e) => e.devices.includes(suggestedDevice.product_id));
                    deviceLinkedApplications.forEach((deviceLinkedApplication) => {
                        const totalSumQty = this.store.cartBoq.data.system.prepare.suggest.devices.reduce((a, c) => deviceLinkedApplication.devices.includes(c.product_id) ? a + c.quantity : a, 0)
                        this.response.applications.getrecommendedApplicationLogic.data.products = this.response.applications.getrecommendedApplicationLogic.data.products.flatMap((p) => {
                            if (deviceLinkedApplication && deviceLinkedApplication.applications.perdevice.includes(p.product_id)) {
                                return new Object({ ...p, quantity: totalSumQty })
                            }
                            return p;
                        })
                        selectedDevicesApplications = [...selectedDevicesApplications, ...(deviceLinkedApplication.applications.perdevice.concat(deviceLinkedApplication.applications.extra))]
                    })

                    return new Object({ ...product, quantity: suggestedDevice.quantity })
                })
            }
            return product;
        });
        //INTERCEPT-2 Update applications with same qty as devices
        this.response.applications.getrecommendedApplicationLogic.data.products.filter((e) => selectedDevicesApplications.includes(e.product_id)).forEach(p => {
            this.response.applications.getrecommendedApplicationLogic.data.set['products'].push(p)

        });
        return this;
    }
    interceptConnectivity() {
        // STORE
        const recommendedConnectivityTabResponse = this.response.connectivity;

        //Check For Recommendation Mutations:  weather suggest.devices are Mutated.
        if (!this.store.theme.data.button.isToggle.applications_undo) {
            this.response.connectivity['interceptor'] = { response_code: 0, response_msg: "Interceptor: Nothing change detected", error: {} };
            return this;
        }
        if (!(this.response.connectivity['getrAPConnectivityTabLogic'].response_code)) {
            this.response.connectivity['interceptor'] = { response_code: 0, response_msg: "Interceptor: Connectivity ready but not Aps.", error: {} };
            return this;
        }
        //MUTATE
        this.response.connectivity.getrAPConnectivityTabLogic.data.products = this.response.connectivity.getrAPConnectivityTabLogic.data.products.flatMap(product => {
            // Middle is Active
            let findSuggestAps = this.store.cartBoq.data.system.prepare.suggest.aps.find((e) => e.product_id == product.product_id);
            if (!findSuggestAps) {
                //Undo is Active |- Since Middle gets reseted on ATC
                findSuggestAps = this.store.cartBoq.data.cart.items.find((e) => e.product_id == product.product_id);
            }
            if (findSuggestAps) {
                return new Object({ ...product, quantity: findSuggestAps.quantity })
            }
            return product;
        });
        return this;

    }

}