import { SimpleCard } from '@nokia-csf-uxr/ccfk/Card'
import React, { ReactNode } from 'react'
interface MyProps {
    children: ReactNode,

}
const ViewFrame: React.FunctionComponent<MyProps> = (props) => {
    return (
        <>
            <div style={{ flexGrow: 1, height: '100%' }}>
                <SimpleCard style={{ height: "100%",  padding: '0.5rem 1rem 0rem', minHeight: '40rem' }} hoverEffect={false} focusEffect={false}>
                    {props.children}
                </SimpleCard>
            </div>

        </>
    )
}

export default ViewFrame