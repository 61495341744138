import React , { useCallback } from "react";
import _ from 'lodash';
import { Tooltip } from "@nokia-csf-uxr/ccfk";
import { LabelHelpIcon } from "@nokia-csf-uxr/ccfk/Label";
import DOMPurify from 'dompurify';

export const HelpText = () => {
  
  const displayHelpText = useCallback((data) => {
    const helpData = getSanitizedHtml(data[1].description);
    const helpTitle = getSanitizedHtml(data[1].help_title);
    const helpText = getSanitizedHtml(data[1].help_text);
    const allText = _.compact([helpTitle,helpText,helpData]);
    const helptext = _.join(allText," , ");
    const textdata = helptext ? helptext : '';
    return textdata;
  },[]);

  const getSanitizedHtml = (html) => {
    //sanitize the html not to allow dangerous elements
    return DOMPurify.sanitize(html,{ALLOWED_TAGS: []});
  };

  const showHelpText = (data) => {
    return (
        <Tooltip 
        placement="top"
        trigger="hover"
        tooltip={displayHelpText(data)}
        >
          <LabelHelpIcon/>
        </Tooltip>
    )
  }

  return {
    showHelpText
  }
}