
//import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { DataGrid, Label, Skeleton, Tabs } from '@nokia-csf-uxr/ccfk';
// import { DataGrid, Label, Tabs, Tooltip } from '@nokia-csf-uxr/ccfk';
// import DeleteIcon from '@nokia-csf-uxr/ccfk-assets/legacy/DeleteIcon';
import { ChipLabel, InfoChip } from '@nokia-csf-uxr/ccfk/Chip';
// import { HorizontalMoreButtonItem, HorizontalMoreButtonItemIcon } from '@nokia-csf-uxr/ccfk/HorizontalMoreButton';
import { Tab } from '@nokia-csf-uxr/ccfk/Tabs';
import { EmptyState } from 'Components/Content/EmptyState';
import ActionBtns from 'Components/Elements/ActionBtns';
import QuantityModifier from 'Components/Elements/QuantityModifier';
import { addCartItem, removeCartItem, setCart, totalCost } from 'Store/contentState/cartBoqSlice';
import { RootState } from 'Store/mainStore';
import { patchDurationItems, roundCount } from 'Utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'Components/Elements/Loader';
import _ from "lodash";
import BoqDataGrid from './BoqDataGrid';
import { BoqDetails } from './configurationArea/Configurator/hooks/useCartDetails';

const CartBoq = () => {
  const cartBoqSelector = useSelector((state: RootState) => state.undoCartBoq.present);
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
  const businessSelector = useSelector((state: RootState) => state.business);
  const warrantySelector = useSelector((state: RootState) => state.warranty);
  const themeSelector = useSelector((state: RootState) => state.theme);
  const stateMachineSelector = useSelector((state: RootState) => state.stateMachine);
  let cartTotal = useSelector((state: RootState) => totalCost(state.cartBoq));
  const configstepperSelector = useSelector((state: RootState) => state.config_stepper);
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const dispatch = useDispatch();
  const autoSizeFitAll = (gridOptions) => {
    window['gridOptions'] = gridOptions;
    const allColumnIds = [];
    gridOptions.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridOptions.columnApi.autoSizeAllColumns(false);
  };
  const [finalTotal, setfinalTotal] = useState(roundCount(cartTotal));
  const {
    portBoqGrid,
    referenceBoqGrid,
    cmuBoqGrid,
    cdmBoqGrid,
    MPWGrid
  } = BoqDetails();

  const undoBtnStatus = themeSelector.data.button.isToggle[`${stateMachineSelector.PTDAC.activeTab}_undo`];
  const [modifiedcartItems, setmodifiedcartItems] = useState([]);
  
  const solutionCategory = configstepperSelector.data.solutionCategory;
  const solutionType = configstepperSelector.data.solutionType;

  useEffect(() => {
    let durations = {
      contract_duration: businessSelector.data.view.PTDAC.contract_duration,
      sla_duration: warrantySelector.data.options.PTDAC.subscription_duration,
      warranty_duration: warrantySelector.data.options.PTDAC.warranty[0]
    };
    //  for non ptdac and non cmu
    if (solutionType !== 'Standalone' && businessSelector.data.options.PTDAC.caseIndustry !== 'port') {
      const slaNDACDur = dynamicSelector.data.dataSet['subscription_duration_months_opex'] ? dynamicSelector.data.dataSet['subscription_duration_months_opex'] : dynamicSelector.data.dataSet['subscription_duration_years_capex'];
      const warrantyNDACDuration = dynamicSelector.data.dataSet['extend_warranty_by'];
      durations = {
        contract_duration: businessSelector.data.view.PTDAC.contract_duration,
        sla_duration: slaNDACDur,
        warranty_duration: warrantyNDACDuration
      }
    }
    const cartItems = patchDurationItems(cartBoqSelector.data.cart.items, durations)
    setmodifiedcartItems(cartItems);
    // re calculate the total after modifying the cart items
    let cartTotal = 0;
    cartItems.forEach(obj => {
      // Convert cost to number
      const cost = parseFloat(obj.cost);
      
      // Check if cost is a valid number (not NaN)
      if (!isNaN(cost)) {
        // Add to sum
        cartTotal += cost;
      }
    });
    cartTotal = roundCount(cartTotal);
    
    setfinalTotal(cartTotal);
  }, [undoBtnStatus, cartBoqSelector.data.cart.items]);

  const loadCMU = () => {
    let cmu = cmuBoqGrid(modifiedcartItems);
    return cmu && <BoqDataGrid boqGridList={cmu} />
  }

  const loadOther = () => {
    let boqGriddata = [];
    if(authSelector.system.isBoqLoading) {
      return <div className='boqloader'><Loader /></div>
    } else {
      switch(businessSelector.data.options.PTDAC.caseIndustry) {
        case 'port' : boqGriddata = portBoqGrid(modifiedcartItems);
                      break;

        case 'mining' : boqGriddata =  cdmBoqGrid(modifiedcartItems);
                        break;

        default : boqGriddata = referenceBoqGrid(modifiedcartItems);
      }
      return <BoqDataGrid boqGridList={boqGriddata}></BoqDataGrid>
    }
  }

  const loadMPW = () => {
    // const cmu_boq = loadCMU();
    // const reference_boq = loadOther();
    // return <>{cmu_boq}{reference_boq}</>
    let mpw = MPWGrid(modifiedcartItems);
    return mpw && <BoqDataGrid boqGridList={mpw} />
  }

  return (
    <>
      <div style={{ display: 'flex', padding: '0.2rem', justifyContent: 'space-between' }}>
        <Tabs style={{ justifyContent: "start" }} >
          <Tab onSelect={() => { }} selected>Bill Of Quantities</Tab>
        </Tabs>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
          <div><Label>Total Price: </Label></div>
          <div> <InfoChip size='small' style={{ border: '2px solid var(--g-color-app-banner-background)' }}><ChipLabel labelProps={{ style: { fontWeight: 'bolder' } }} label={`${roundCount(finalTotal)} ${caseDetailsSelector.data.currency}`} /></InfoChip> </div>
        </div>
      </div>
      {
        (!modifiedcartItems  || modifiedcartItems.length === 0) ? (
          authSelector.system.isBoqLoading ?
            (
              <div className='boqloader'><Loader /></div>
            )
            :
            (<EmptyState msg='Offer contents will appear here after making configuration choices.' />)
        ) : (
          <>
            {
              (solutionType === "Standalone" && dynamicSelector.data.boq.length > 0 ) ? (
                loadCMU()
              ) :
                (
                  (solutionCategory === "MPW" ) ? 
                    (loadMPW())
                  :
                    loadOther()
                )
            }
          </>
        )
      }
    </>
  )
}

export default CartBoq
