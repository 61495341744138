
//import { AllCommunityModules } from '@ag-grid-community/all-modules';
// import { DataGrid, Label } from '@nokia-csf-uxr/ccfk';

import { Checkbox, TextInput, Typography } from '@nokia-csf-uxr/ccfk';
import { ExpansionPanel, ExpansionPanelBody, ExpansionPanelButton, ExpansionPanelHeader } from '@nokia-csf-uxr/ccfk/ExpansionPanels';
import { TextInputLabelContent } from '@nokia-csf-uxr/ccfk/TextInput';
import { useQuery } from '@tanstack/react-query';
import AddCartBtn from 'Components/Elements/AddCartBtn';
import Loader from 'Components/Elements/Loader';
import SelectBar from 'Components/Elements/SelectBar';
import { isValidPositiveNumber } from 'Components/Logics/ensoCommonFunctions';
import { systemStyle } from 'Constants/StyleLayer';
import OOT from 'Constants/services/oot';
import { setProp } from 'Store/Actions';
import { addCartItem } from 'Store/contentState/cartBoqSlice';
import { setServicesTabRules } from 'Store/contentState/stateMachine';
import { RootState } from 'Store/mainStore';
import { setTheme } from 'Store/theme';
import { roundCount } from 'Utils';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectionsFields } from './system';

const Options = () => {

  const dispatch = useDispatch();
  const servicesSelector = useSelector((state: RootState) => state.services);
  const cartBoqSelector = useSelector((state: RootState) => state.cartBoq);
  const findCaseSelector = useSelector((state: RootState) => state.findCase);
  const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
  const themeSelector = useSelector((state: RootState) => state.theme);
  const stateMachineSelector = useSelector((state: RootState) => state.stateMachine);
  const slice = "services";
  const islistServicesState = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.listServicesTabLogic;
  let currentSelection = [];

  const servicesTab = useQuery({
    queryKey: ['servicesTab', caseDetailsSelector.data.country, {
      account: caseDetailsSelector.data.account_id,
      shipping_country_code: caseDetailsSelector.data.shipping_country,
      currency_code: caseDetailsSelector.data.currency,
    }],
    queryFn: async ({ queryKey }) => {
      //@ts-ignore
      const caseData = {
        boq: cartBoqSelector.data.cart.items,
        case_type: findCaseSelector.data.caseType,
        case_sub_type: findCaseSelector.data.subType,
      }
      //@ts-ignore
      const [_key, country, { account, shipping_country_code, currency_code }] = queryKey;
      const response = await OOT.getServicesTab({
        account: account,
        caseData: caseData,
        currency_code: currency_code,
        shipping_country_code: shipping_country_code,
        selector: caseDetailsSelector
      });
      return response;
    },
    refetchOnMount: false,
    onSuccess(res) {
      dispatch(setServicesTabRules(res));
      dispatch(setTheme({ element: "button", comp: "addToCart", prop: "isDisable", value: true }))

    },
  })
  if (servicesTab.isLoading || !(islistServicesState && islistServicesState.response_code)) {
    return (<Loader />)
  }



  return (

    <>
      <div id="segment-specific-services">
        <div style={{ display: 'block' }} className='mb'><Typography typography="BODY">Segment specific services</Typography></div>
        <div className='mb' style={systemStyle.flex({ justify: "space-between" })}>
          <div><Typography typography="LABEL">Services</Typography></div>
          <div><Typography typography="LABEL">{`Price (${caseDetailsSelector.data.currency})`}</Typography></div>
        </div>

        {

          islistServicesState.done?.services.PTDAC_servicesToAdd.allIds.map((service, sI) => {
            return (
              <>
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <div id="checkbox">
                    <Checkbox
                      disabled={true}
                      checked={servicesSelector.data.options.PTDAC.category.segment_oriented.includes(service)}
                      onChange={(e) => {
                        let currentSelection = servicesSelector.data.options.PTDAC.category.segment_oriented

                        if ((e.target.checked)) {
                          // let res = currentSelection.push(service);
                          dispatch(setProp({ slice: slice, key: "options.PTDAC.category.segment_oriented", value: [...currentSelection, new Object({ ...service, cost: service.quantity * roundCount(service.list_price) })] }))
                          return;
                        }
                        // let res = currentSelection.filter((e) => e != service)
                        dispatch(setProp({ slice: slice, key: "options.PTDAC.category.segment_oriented", value: currentSelection.filter((e) => e != service) }))
                        return;

                        //remove

                      }
                      } />
                  </div>
                  <div id="expansion-panel" style={{ flexGrow: 1 }}>
                    <ExpansionPanel
                      disabled={false}
                      selected={false}
                      //expanded={themeSelector.data.panel.isOpen[service.cui_userSelection]}
                      expanded={themeSelector.data.panel.isOpen[service]}
                    >
                      <ExpansionPanelHeader key={sI} >

                        <div style={systemStyle.flex({ alignItems: "center", justify: "space-between", flex: "1" })}>
                          <div key={service.id} style={systemStyle.flex({ alignItems: "center", justify: "space-between", flex: "1" })} >
                            <div>
                              <TextInputLabelContent>{islistServicesState.done?.services.PTDAC_servicesToAdd.byIds[service].category}</TextInputLabelContent>
                            </div>
                            <div>
                              <Typography variant="BODY">{roundCount(islistServicesState.done?.services.PTDAC_servicesToAdd.byIds[service].serviceDetails.reduce((a, c) => a + roundCount(c.cost), 0))}</Typography>
                            </div>
                          </div>
                        </div>

                      </ExpansionPanelHeader>
                      <ExpansionPanelBody>
                        <div className='m' style={systemStyle.flex({ justify: "space-between", alignItems: "center", flexDirection: "row", flexWrap: "wrap" })}>
                          {
                            _.uniq(islistServicesState.done?.services.PTDAC_servicesToAdd.byIds[service].serviceDetails.map((e) => e.cui_serviceId_assoc_fields).flat()).map((field: any) => {
                              return (
                                <>
                                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <div>
                                      <Typography typography="LABEL">{userSelectionsFields.byIds[field].label}</Typography>
                                    </div>
                                    <div>
                                      {
                                        userSelectionsFields.byIds[field].type == "input" ?
                                          (<div>
                                            <TextInput
                                              variant={"outlined"}
                                              inputMode='numeric'
                                              inputProps={{ type: "number" }}
                                              onChange={(e) => {
                                                if (isValidPositiveNumber(e.target.value)) {
                                                  dispatch(setProp({ slice: slice, key: `options.PTDAC.userSelections.${[field]}`, value: e.target.value }))
                                                }
                                              }}
                                              value={isValidPositiveNumber(islistServicesState.done?.services.PTDAC_servicesToAdd.allIds[service].fillers[field]) ? islistServicesState.done?.services.PTDAC_servicesToAdd.allIds[service].fillers[field] : undefined} />
                                          </div>) :

                                          (<div><SelectBar
                                            handOnSelect={(e) => {
                                              dispatch(setProp({ slice: slice, key: `options.PTDAC.userSelections.${[field]}`, value: e }))
                                            }}
                                            isOpen={themeSelector.data.select.isOpen[`services_${field}`]}
                                            slice='services'
                                            variant='outlined'
                                            onlyHandOnSelect={true}
                                            optionPath='id'
                                            displayKey='value'
                                            setProperty={{
                                              sliceProperty: `${field}`,
                                              themeProperty: `services_${field}`,
                                            }}
                                            options={userSelectionsFields.byIds[field].options}
                                            renderValue={servicesSelector.data.options.PTDAC.userSelections[field]}

                                          /></div>)
                                      }
                                    </div>
                                  </div>
                                </>
                              )
                            })


                          }
                        </div>
                      </ExpansionPanelBody>
                    </ExpansionPanel>
                  </div>
                </div>


              </>
            )
          })
        }
      </div>

      <div id="all-services">
        <div style={{ display: 'block' }} className='mb'><Typography typography="BODY">All Services</Typography></div>
        <div className='mb' style={systemStyle.flex({ justify: "space-between" })}>
          <div><Typography typography="LABEL">Services</Typography></div>
          <div><Typography typography="LABEL">{`Price (${caseDetailsSelector.data.currency})`}</Typography></div>
        </div>
        {
          islistServicesState.done?.services.NDAC_servicesToAdd.allIds.map((service, sI) => {
            let mandatoryServ = false;
            const serviceType = islistServicesState.done.services.byIds[service].type;
            // for professional service make checked true and disabled
            if (serviceType === 'Professional'
              && islistServicesState.done.services.byIds[service]['serviceDetails'][0].required) {
              mandatoryServ = true;
              if (!currentSelection.includes(service)) {
                currentSelection.push(service);
                // dispatch(setProp({ slice: slice, key: "options.PTDAC.category.all", value: [...currentSelection] }));
              }
            }
            return (
              <>
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <div id="checkbox" style={{ display: serviceType === 'Training' ? 'none' : 'block' }}>
                    <Checkbox
                      disabled={mandatoryServ}
                      checked={servicesSelector.data.options.PTDAC.category.all.includes(service) || mandatoryServ}
                      onChange={(e) => {
                        //  add the previous state data and current selected data
                        const defaultSelections = servicesSelector.data.options.PTDAC.category.all;
                        currentSelection = _.uniq([...currentSelection, ...defaultSelections]);

                        if (e.target.checked) {
                          dispatch(setProp({ slice: slice, key: "options.PTDAC.category.all", value: [...currentSelection, service] }));

                          return;
                        }
                        dispatch(setProp({ slice: slice, key: "options.PTDAC.category.all", value: currentSelection.filter((e) => e != service) }))

                        return;
                      }
                      } />
                  </div>
                  <div id="expansion-panel" style={{ flexGrow: 1 }}>
                    <ExpansionPanel

                      disabled={false}
                      selected={false}
                      expanded={themeSelector.data.panel.isOpen[service]}
                    >
                      <ExpansionPanelHeader key={sI} >
                        {
                          (islistServicesState.done.services.byIds[service].serviceDetails.map((e) => e.cui_serviceId_assoc_fields).flat().length) ? (<ExpansionPanelButton
                            onClick={() => {
                              dispatch(setTheme({
                                element: "panel",
                                comp: service,
                                prop: "isOpen",

                                value: !themeSelector.data.panel.isOpen[service]
                              }))
                            }}
                            iconButtonProps={{
                              // disabled: !service.cui_userSelection,
                              'aria-hidden': true,
                            }}
                            style={{ display: mandatoryServ ? 'none' : 'block' }}
                          />) : (<></>)
                        }

                        <div style={systemStyle.flex({ alignItems: "center", justify: "space-between", flex: "1" })}>
                          <div key={service.id} style={systemStyle.flex({ alignItems: "center", justify: "space-between", flex: "1" })} >
                            <div>
                              <TextInputLabelContent>{islistServicesState.done?.services.NDAC_servicesToAdd.byIds[service].category}</TextInputLabelContent>
                            </div>
                            <div>
                              <Typography variant="BODY">{roundCount(islistServicesState.done?.services.NDAC_servicesToAdd.byIds[service].serviceDetails.reduce((a, c) => a + roundCount(c.cost), 0))}</Typography>
                            </div>
                          </div>
                        </div>
                      </ExpansionPanelHeader>
                      <ExpansionPanelBody>
                        <div className='m' style={systemStyle.flex({ justify: "space-between", alignItems: "center", flexDirection: "row", flexWrap: "wrap" })}>
                          {
                            _.uniq(islistServicesState.done.services.byIds[service].serviceDetails.map((e) => e.cui_serviceId_assoc_fields).flat()).map((field: any, index: number) => {
                              const serviceData = islistServicesState.done.services.byIds[service].serviceDetails[index];
                              const selectedServ = serviceData.id;
                              const defaultValue = serviceData[field];
                              return (
                                <>
                                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: serviceType === 'Training' ? "100%" : "unset" }}>
                                    <div style={{ display: serviceType === 'Training' ? 'none' : 'block' }}>
                                      <Typography typography="LABEL">{userSelectionsFields.byIds[field].label}</Typography>
                                    </div>
                                    <div>
                                      {
                                        serviceType === 'Training' ?
                                          (
                                            <div id={field} style={{ display: "flex", justifyContent: "space-between", marginBottom: '2%' }}>
                                              <Checkbox
                                                disabled={false}
                                                checked={servicesSelector.data.options.PTDAC.category.all.includes(selectedServ)}
                                                onChange={(e) => {
                                                  const defaultSelections = servicesSelector.data.options.PTDAC.category.all;
                                                  currentSelection = _.uniq([...currentSelection, ...defaultSelections]);
                                                  if (e.target.checked) {
                                                    dispatch(setProp({ slice: slice, key: "options.PTDAC.category.all", value: [...currentSelection, selectedServ] }));

                                                    return;
                                                  }

                                                  dispatch(setProp({ slice: slice, key: "options.PTDAC.category.all", value: currentSelection.filter((e) => e != selectedServ) }))

                                                  return;
                                                }} />
                                              <Typography typography="LABEL">{userSelectionsFields.byIds[field].label}</Typography>
                                              <div>
                                                <TextInput
                                                  variant={"outlined"}
                                                  inputMode='numeric'
                                                  inputProps={{ type: "number" }}
                                                  onChange={(e) => {
                                                    if (isValidPositiveNumber(e.target.value)) {
                                                      dispatch(setProp({ slice: slice, key: `options.PTDAC.userSelections.${[field]}`, value: e.target.value }))
                                                    }
                                                  }}
                                                  value={isValidPositiveNumber(servicesSelector.data.options.PTDAC.userSelections[field]) ? servicesSelector.data.options.PTDAC.userSelections[field] : undefined} />

                                              </div>
                                            </div>
                                          ) :
                                          (
                                            userSelectionsFields.byIds[field].type == "input" ?
                                              (
                                                <div>
                                                  <TextInput
                                                    variant={"outlined"}
                                                    inputMode='numeric'
                                                    inputProps={{ type: "number" }}
                                                    onChange={(e) => {
                                                      if (isValidPositiveNumber(e.target.value)) {
                                                        dispatch(setProp({ slice: slice, key: `options.PTDAC.userSelections.${[field]}`, value: e.target.value }))
                                                      }

                                                    }}
                                                    value={isValidPositiveNumber(defaultValue) ? defaultValue : undefined} />
                                                </div>
                                              ) :
                                              (<div><SelectBar
                                                handOnSelect={(e) => {
                                                  dispatch(setProp({ slice: slice, key: `options.PTDAC.userSelections.${[field]}`, value: e }))
                                                }}
                                                isOpen={themeSelector.data.select.isOpen[`services_${field}`]}
                                                slice='services'
                                                variant='outlined'
                                                onlyHandOnSelect={true}
                                                optionPath='id'
                                                displayKey='value'
                                                setProperty={{
                                                  sliceProperty: `${field}`,
                                                  themeProperty: `services_${field}`,
                                                }}
                                                options={userSelectionsFields.byIds[field].options}
                                                renderValue={servicesSelector.data.options.PTDAC.userSelections[field]}

                                              /></div>)
                                          )
                                      }
                                    </div>
                                  </div>
                                </>
                              )
                            })
                          }
                        </div>
                      </ExpansionPanelBody>
                    </ExpansionPanel>
                  </div>
                </div>
              </>
            )
          })
        }
      </div>

      <AddCartBtn
        onAction={() => {
          //category_code
          let totalSelectedCategory = servicesSelector.data.options.PTDAC.category.all.concat(servicesSelector.data.options.PTDAC.category.segment_oriented);
          if (servicesSelector.data.options.PTDAC.category.all.length === 0 && currentSelection.length > 0) {
            totalSelectedCategory = totalSelectedCategory.concat(currentSelection);
          }

          // check if either category_code or id any is present then add to boq
          let filterServices = islistServicesState.done?.services.normalized.filter((e) => totalSelectedCategory.includes(e.category_code) || totalSelectedCategory.includes(e.id));
          dispatch(addCartItem({
            products: filterServices,
            history: true,
          }));
        }}
      />
    </>
  )
}

export default React.memo(Options)