//@ts-nocheck
import { ButtonsRow, Checkbox, Label, List, Skeleton, ToggleSwitch } from '@nokia-csf-uxr/ccfk'
import { CheckboxLabelContent } from '@nokia-csf-uxr/ccfk/Checkbox'
import { ListItemBasic, ListItemTextContainer } from '@nokia-csf-uxr/ccfk/List'
import { MultiSelectSmartListCheckbox } from '@nokia-csf-uxr/ccfk/MultiSelectSmartList'
import { TextInputLabelContent } from '@nokia-csf-uxr/ccfk/TextInput'
import { ToggleSwitchLabelContent } from '@nokia-csf-uxr/ccfk/ToggleSwitch'
import { useQuery } from '@tanstack/react-query'
import ProductCatalog from 'Components/Content/modelArea/ProductCatalog'
import AddCartBtn from 'Components/Elements/AddCartBtn'
import DialogModal from 'Components/Elements/DialogModal'
import Loader from 'Components/Elements/Loader'
import MultiCheckBoxList from 'Components/Elements/MultiCheckBoxList'
import QuantityModifier from 'Components/Elements/QuantityModifier'
import { mandatoryApp } from 'Components/Logics/stateMachine/modules/PTDAC/system'
import OOT from 'Constants/services/oot'
import { setProp } from 'Store/Actions'
import { addCartItem, prepareCart, removeCartItem } from 'Store/contentState/cartBoqSlice'
import { Case, applicationsDevices } from 'Store/contentState/configurationArea/PTDAC/applicationsSlice'
import { setApplicationsTabRules } from 'Store/contentState/stateMachine'
import { RootState } from 'Store/mainStore'
import { setTheme } from 'Store/theme'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { segment_solution } from './system'
import ToggleDisabledIcon from '@nokia-csf-uxr/ccfk-assets/latest/ToggleDisabledIcon'
import ToggleIcon from '@nokia-csf-uxr/ccfk-assets/latest/ToggleIcon'

const Options = () => {
    const dispatch = useDispatch();
    const applicationsSelector = useSelector((state: RootState) => state.applications);
    const cartBoqSelector = useSelector((state: RootState) => state.cartBoq);
    // const authSelector = useSelector((state: RootState) => state.authSlice);
    const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
    const stateMachineSelector = useSelector((state: RootState) => state.stateMachine);
    const themeSelector = useSelector((state: RootState) => state.theme);
    const slice = "applications";
    //Checkers //
    const isrAState = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.getrecommendedApplicationLogic
    const isrDstate = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.getrecommendedDevicesLogic
    const iscatalogstate = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.getcatalogLogic
    const applicationTab = useQuery({
        queryKey: ['connectivityTab', { account: caseDetailsSelector.data.account_id }],
        queryFn: async ({ queryKey }) => {
            //@ts-ignore
            const [_key, { account }] = queryKey;
            const response = await OOT.getApplicationTab({ account: account, selector: caseDetailsSelector });
            return response;
        },
        onSuccess(res) {
            if (res.status) {
                dispatch(setApplicationsTabRules({ key: "setRecommendedApplication", value: res.data.recommendedApplication }));
                dispatch(setApplicationsTabRules({ key: "setRecommendedDevices", value: res.data.recommendedDevices }));
                dispatch(setApplicationsTabRules({ key: "catalog", value: res.data.catalog }));
                dispatch(setTheme({ element: "button", comp: "addToCart", prop: "isDisable", value: true }))
            }
        },
    })
    if (applicationTab.isLoading) {
        return (<Loader />)
    }

    return (
        <>
            
            <DialogModal isOpen={themeSelector.data.modal.isOpen.connectivity_catalog}
                type='modal'
                dialogInfo={{ title: 'Product Catalog' }}
                actionBtns={[
                    {
                        label: "Cancel", onAction: () => {
                            dispatch(setTheme({
                                element: "modal",
                                comp: "connectivity_catalog",
                                prop: "isOpen",
                                value: false,
                            }));
                            dispatch(prepareCart([]))
                        }, variant: "neutral"
                    },
                    {
                        label: "Add", onAction: () => {
                            dispatch(addCartItem(cartBoqSelector.data.system.prepare.catalog));
                            dispatch(setTheme({
                                element: "modal",
                                comp: "connectivity_catalog",
                                prop: "isOpen",
                                value: false,
                            }))
                            // dispatch(showPopup({ msg: "Item added to cart", show: true }))
                            dispatch(prepareCart([]))
                        }, variant: "call-to-action"
                    }]} >
                {
                    (iscatalogstate?.data?.menus.length > 0 && iscatalogstate?.data?.catalog) && (<ProductCatalog
                        catalog={iscatalogstate.data.catalog} menus={iscatalogstate.data.menus}
                        onTabSelect={(e) => {
                        }}
                        getCatalog={(e) => {
                            dispatch(prepareCart(e))
                        }}
                        getSelectedProduct={(selectedProduct) => { }}
                    />)
                }
            </DialogModal>

            <TextInputLabelContent >Segment Solution Use Cases *</TextInputLabelContent>
            <div className='' id="use-cases">
                {
                    segment_solution.allIds.map((useCase, index) => {
                        return (

                            <div id={useCase} key={index}>
                                <div style={{ display: 'flex' }}>

                                    <ToggleSwitch
                                        toggleIcon={applicationsSelector.data.options.PTDAC.segment_solution.use_case.includes(segment_solution.byIds[useCase].id) ? <ToggleIcon /> : <ToggleDisabledIcon />}
                                        onChange={(e) => {
                                            if (e.value) {
                                                dispatch(Case({ method: "add", case: segment_solution.byIds[useCase].id }));
                                                return;
                                            }
                                            dispatch(Case({ method: "remove", case: segment_solution.byIds[useCase].id }));
                                            dispatch(setProp({ slice: "cartBoq", key: `system.prepare.suggest.devices`, value: [] }))
                                        }
                                        }
                                        checked={applicationsSelector.data.options.PTDAC.segment_solution.use_case.includes(segment_solution.byIds[useCase].id)}
                                    />
                                    <ToggleSwitchLabelContent>{segment_solution.byIds[useCase].value}</ToggleSwitchLabelContent>
                                </div>
                                <div id={`${segment_solution.byIds[useCase].id}-devices`}>
                                    {<div className='ml'>{
                                        segment_solution.byIds[useCase].options.allIds.map((devices, indexD) => {
                                            return (
                                                <Label key={`${devices}-${indexD}`}>
                                                    <Checkbox
                                                        autoFocus={false}
                                                        disabled={!applicationsSelector.data.options.PTDAC.segment_solution.use_case.includes(useCase)}
                                                        checked={applicationsSelector.data.options.PTDAC.segment_solution.applications_devices[useCase].includes(devices)}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                // dispatch(setProp({ slice: 'applications', key: `view.PTDAC.segment_solution.applications_devices.${useCase.id}`, value: [...applicationsSelector.data.view.PTDAC.segment_solution.applications_devices[useCase.id], devices.id] }));
                                                                dispatch(applicationsDevices({ method: "add", useCase: segment_solution.byIds[useCase].id, device: devices }));
                                                                return;
                                                            }
                                                            dispatch(applicationsDevices({ method: "remove", useCase: segment_solution.byIds[useCase].id, device: devices }));

                                                        }} />
                                                    <CheckboxLabelContent maxLines={3}>{segment_solution.byIds[useCase].options.byIds[devices].value}</CheckboxLabelContent>
                                                </Label>

                                            )
                                        })
                                    }</div>}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div id="recomm-applications" hidden={!Boolean(isrAState?.response_code)}>
                <TextInputLabelContent >Included Applications</TextInputLabelContent>

                {
                    /* @ts-ignore */
                    (isrAState?.response_code) ?
                        (<List hoverEffect={false} elevationProps={{ elevationIndex: 0 }}>
                            {/* @ts-ignore */}
                            {isrAState.data.set.included_applications.map((mp, i) => {
                                return (
                                    <>
                                        <ListItemBasic key={i} style={{ justifyContent: 'space-between' }}>
                                            {/* @ts-ignore */}
                                            <ListItemTextContainer className='mb' title={mandatoryApp.byIds[mp].value} style={{ 'textWrap': 'balance' }}>
                                                {
                                                    //@ts-ignore 
                                                    mandatoryApp.byIds[mp].value
                                                }

                                            </ListItemTextContainer>


                                        </ListItemBasic>
                                        {/* {
                                            (i != isrAState.data.set.included_applications.length - 1) && (<HorizontalDivider />)
                                        } */}
                                    </>


                                );
                            })}

                        </List>) : (<Skeleton style={{ width: '250px' }} />)
                }

            </div >
            <div id="recomm-devices" hidden={!Boolean(isrDstate?.response_code)}>
                {
                    (isrDstate?.response_code) ? (<MultiCheckBoxList title='Recommended Devices'>
                        {
                            isrDstate.data.products.map((rD, i) => {
                                return (
                                    <MultiSelectSmartListCheckbox key={i}
                                        disabled={themeSelector.data.button.isToggle.applications_undo}
                                        selected={cartBoqSelector.data.system.prepare.suggest.devices.find((product) => product.product_id === rD.product_id)}
                                        label={`${rD.product_name}`} subLabel={rD.product_id}
                                        icon={<div className='mr'>
                                            {/* <Typography typography="BODY">{`#${rD.quantity}`}</Typography> */}
                                            <ListItemTextContainer key={i}>
                                                <QuantityModifier
                                                    key={i}
                                                    isDisabled={!(cartBoqSelector.data.system.prepare.suggest.devices.find((product) => product.product_id === rD.product_id))}
                                                    product={rD}
                                                    isEdit={(e) => {
                                                        setTimeout(() => dispatch(setTheme({ element: "button", comp: `${slice}_editSelection`, prop: "isToggle", value: !e, })), 1000)
                                                    }}
                                                    onUpdateQty={
                                                        (val) => {
                                                            Math.sign((rD.quantity - val)) == - 1 ?
                                                                dispatch(addCartItem({ products: [new Object({ ...rD, quantity: Math.abs((rD.quantity - val)) })], targetItems: "suggest.devices", parentSync: false, unWrap: false }))
                                                                : dispatch(removeCartItem({ products: [new Object({ ...rD, quantity: Math.abs((rD.quantity - val)) })], targetItems: "suggest.devices", unWrap: false }))
                                                        }
                                                    }
                                                    onDecQty={() => dispatch(removeCartItem({ products: [new Object({ ...rD, quantity: 1 })], targetItems: "suggest.devices", parentSync: false, unWrap: false }))}
                                                    onIncQty={() => {
                                                        dispatch(addCartItem({ products: [new Object({ ...rD, quantity: 1 })], targetItems: "suggest.devices", parentSync: false, unWrap: false }))

                                                    }}
                                                    value={Math.round(rD.quantity)} />
                                            </ListItemTextContainer>
                                        </div>}
                                        onChange={(e) => {
                                             if (e.target.checked && e.type === "change") {
                                                dispatch(prepareCart({ method: "add", product: rD, type: "suggest.devices" }));

                                                return;
                                            } else {
                                                if (e.type === "change") {
                                                    dispatch(prepareCart({ method: "remove", product: rD, type: "suggest.devices" }));
                                                }
                                            }
                                        }} />
                                )
                            })
                        }

                    </MultiCheckBoxList >) : (<><MultiCheckBoxList title='Recommended Devices'><Skeleton style={{ width: '250px' }} /></MultiCheckBoxList></>)
                }
            </div >

            <ButtonsRow>
                <AddCartBtn

                    isLoading={!(isrAState && isrAState.response_code && (themeSelector.data.button.isToggle.applications_undo || cartBoqSelector.data.system.prepare.suggest.devices.length > 0) && themeSelector.data.button.isToggle[`${slice}_editSelection`])}
                    onAction={() => {
                        dispatch(addCartItem({ products: isrAState.data?.set?.products.concat(cartBoqSelector.data.system.prepare.suggest.devices), history: true }))
                    }} />
            </ButtonsRow>




        </>
    )
}

export default Options