import { SelectItem, Skeleton,Typography } from "@nokia-csf-uxr/ccfk";
import {
  SelectItemClearButton,
  SelectItemInput,
  SelectItemText,
  SelectListItem,
  SelectItemErrorMessage
} from "@nokia-csf-uxr/ccfk/SelectItem";
import { useValidate } from "Components/Logics/ensoCommonFunctions";
import { handleSalesChannelLogic } from "Components/Logics/systemLogics";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProp } from "Store/Actions";
import { RootState, store } from "Store/mainStore";
import { resetTheme, setTheme } from "Store/theme";
interface selectBarDefaults {
  options: any,
  optionPath?: string, //i.e -> value when select bar is selected
  isOpen?: boolean, // first level of render if its not a key value pair , here it will be id
  renderValue?: string,
  displayKey?: string | null, //1.1 [{id:12332,value:"some text"}] -> to show value add 'value' as display key
  setProperty?: { sliceProperty: string, themeProperty: string },
  isDisabled?: boolean,
  slice?: string,
  variant?: "outlined" | "underlined",
  style?: {},
  onChange?: any,
  handOnSelect?: any,
  onlyHandOnSelect?: boolean,
  error?: boolean
  eleref: object,
  getReturnValue?: Function
  placeholder?:string
}
const ConfiguratorSelectBar = ({
  options,
  optionPath, //i.e -> value when select bar is selected
  isOpen, // first level of render if its not a key value pair , here it will be id
  renderValue,
  displayKey = undefined, //1.1 [{id:12332,value:"some text"}] -> to show value add 'value' as display key
  setProperty,
  isDisabled = false,
  slice = "caseDetails",
  variant = "outlined",
  style = {},
  onChange = () => { },
  handOnSelect = () => { },
  onlyHandOnSelect = false,
  error,
  eleref,
  getReturnValue = () => { },
  placeholder
}: selectBarDefaults) => {
  const [newSearch, setNewSearch] = useState(options);
  const [inputText, setInputText] = useState("");
  const [renderText, setRenderText] = useState(renderValue);

  const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
  const selectItemRef: any = useRef(setProperty.sliceProperty);
  const dispatch = useDispatch();
  const isValidated = useValidate();
  // return any items containing `filterValue`.
  const themeSelector = useSelector((state: RootState) => state.theme);
  //const findCaseSelector = useSelector((state: RootState) => state.findCase);
  //const authSelector = useSelector((state:RootState) => state.authSlice);
  const configstepperSelector = useSelector((state: RootState) => state.config_stepper)
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);

  useEffect(()=>{
    if(!renderValue || (renderValue && renderValue.length === 0)){
      clearSelectItem()
    }    
  },[renderValue])

  const clearSelectItem = () => {
    //setting the state in slice
    dispatch(setProp({ key: setProperty.sliceProperty, value: "", slice: slice }));
    getReturnValue(setProperty.sliceProperty,'')
    setRenderText("");
    setNewSearch(options);
  }

  const shouldUpdateSelection = (event) => {
    const { key, type } = event;
    // if ESCAPE or TAB or click (outside) event closed the menu clear current input text
    if ((key === 'Escape') || (type === 'mousedown')) {
      setRenderText(null);
      dispatch(
        setTheme({
          element: "select",
          prop: "isOpen",
          comp: setProperty.themeProperty,
          value:
            !themeSelector.data.select.isOpen[setProperty.themeProperty],
        })
      );
    }
  }

  const getFilteredValues = (filterValue) => {
    if (filterValue.length === 0) {
      return options;
    }
    return options.filter((item) => {
      if (item[displayKey]) {
        return item[displayKey].toLowerCase().includes(filterValue.toLowerCase());
      }

    });
  };
  const handleInputChange = (event) => {
    let newText = event.target.value;
    return setNewSearch(getFilteredValues(newText));
  };
  const handleInputSubmit = (event) => {
    if (inputText === "") {
      setInputText("");
    }
  };
  const handleSelect = ({ key, payload, property }) => {
    if (onlyHandOnSelect) {
      handOnSelect(payload);
    } else {
      dispatch(setProp({ key: key, value: payload, slice: slice }));
      handOnSelect(payload);
    }
    dispatch(resetTheme());
  };
  const renderClearButton = (props) => {
    if (renderText === "" || renderText == null) {
      return undefined;
    }

    return (
      <SelectItemClearButton
        id={"ClearButton_" + setProperty.sliceProperty}
        aria-label="clear input"
        onClick={clearSelectItem}
        // onClick={() => {
        //   //setting the state in slice
        //   dispatch(setProp({ key: setProperty.sliceProperty, value: "", slice: slice }));
        //   getReturnValue(setProperty.sliceProperty,'')
        //   setRenderText("");
        //   setNewSearch(options);
        // }}
      />
    );
  };
  const renderSelectItemBase = () => {
    if (renderValue && renderValue !== "None") {
      setRenderText(renderValue);
    }
    else if(renderValue === "" && renderText != null && (dynamicSelector.data.element[configstepperSelector.data.active_step][setProperty.themeProperty] == "")){
      setRenderText(renderValue);
    }

    return (
      <SelectItemInput
        crossOrigin="anonymous"
        onChange={handleInputChange}
        onSubmit={handleInputSubmit}
        renderClearButton={renderClearButton}
        value={renderText} //renderValue
        ref={eleref as React.Ref<HTMLElement>}
        placeholder={placeholder}
      />
    );
  };

  return (
    <>
      {(Array.isArray(options) && options.length === 0) ? (<Skeleton style={{ width: '250px' }} />) : (
        (
          <>
            {/* <Typography typography="LABEL">CCFK</Typography> */}
            <SelectItem
              onClose={(event)=>shouldUpdateSelection(event)}
              maxWidth={true}
              className="SelectBar"
              key={setProperty.sliceProperty}
              onChange={onChange()}
              searchable={true}
              disabled={isDisabled}
              style={style}
              error={error}
              errorMessage={setProperty.themeProperty.replace(/_/g, " ").toLowerCase() + " cannot be empty"}
              id={setProperty.sliceProperty}
              aria-labelledby="selectitem-component-label"
              variant={variant}
              ref={selectItemRef}
              isOpen={isOpen}
              renderSelectItemBase={renderSelectItemBase}
              onClick={() => {
                setRenderText(null);
                dispatch(
                  setTheme({
                    element: "select",
                    prop: "isOpen",
                    comp: setProperty.themeProperty,
                    value:
                      !themeSelector.data.select.isOpen[setProperty.themeProperty],
                  })
                );
                setNewSearch(options);
              }}
            >
              {newSearch.length === 0 ? <div>No Results Found</div> : newSearch.map((country, index, arr) => {
              return (
                  <SelectListItem
                    key={index}
                    selected={renderValue === _.get(country, optionPath)}
                    role="option"
                    onClick={() => {
                      setRenderText(_.get(country, displayKey));
                      handleSelect({
                        key: setProperty.sliceProperty, // setting the state in slice
                        payload: _.get(country, optionPath), //setting the state in slice
                        property: setProperty.themeProperty, //setting the state in theme
                      }); //closes that selectbar only

                      handleSalesChannelLogic(store.getState());
                    }}
                  >
                    <SelectItemText key={index}>
                      {displayKey
                        ? _.get(country, displayKey)
                        : _.get(country, optionPath)}
                    </SelectItemText>
                  </SelectListItem>
              );
              })}
            </SelectItem>
          </>
        )
      )}
    </>
  );
};
export default memo(ConfiguratorSelectBar);
ConfiguratorSelectBar.propTypes = {
  options: PropTypes.array,
  optionPath: PropTypes.string,
  displayKey: PropTypes.string,
  renderValue: PropTypes.string,
  isOpen: PropTypes.bool,
  onOpenSelect: PropTypes.any,
  setProperty: PropTypes.object,
  isDisabled: PropTypes.bool,
  slice: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  handOnSelect: PropTypes.func,
  onlyHandOnSelect: PropTypes.bool,
  error: PropTypes.bool
};
