import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";

import DATAMART_API from "Constants/services/datamart";
import { setError } from "Store/auth";

const useDatamart = () => {
  // Selector
  const cartBoqSelector = useSelector((state: RootState) => state.cartBoq);
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const caseDetailsSelector = useSelector(
    (state: RootState) => state.caseDetails
  );
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);

  const dispatch = useDispatch();

  /** getCartSummary */
  const getCartSummary = () => {    
    const cartSummary = {
      ...authSelector?.system,
      ...caseDetailsSelector?.data,
    }

    return cartSummary;
  };

  /** Function to handle save BoQ in datamart */
  const saveBoQInDatamart = async () => {
    // const boqData = dynamicSelector.data.boq;
    const boqData = cartBoqSelector?.data?.cart?.items;
    // Call API to save BoQ in datamart incase BoQ data is available
    if (boqData.length > 0) {
      try {
        // Call API to save BoQ in datamart
        const temp_offer_id = dynamicSelector?.data?.sid;
        const cartDataList = {
          cart: {
            cart_summary: getCartSummary(),
            cart_items: boqData,
          },
          // bookmark_items: [],
          temp_offer_id: temp_offer_id,
          source: "cui", // yet to add this column in table
          status: "draft", //draft or submitted
          //"ui_json": {"lable1":"value1","lable2": "value2","lable3":"value3"} - ???
        };
        const datamartResp = await DATAMART_API.saveCartDetails(cartDataList);
        if (!datamartResp.status) {
          dispatch(
            setError({
              key: "warn",
              value: {
                errorCode: null,
                errorMsg: datamartResp?.errorMessage
                  ? `The BoQ has not been saved in the datamart: ${datamartResp?.errorMessage}`
                  : "Something went wrong while saving BoQ in Datamart",
              },
            })
          );
        }
      } catch (e) {
        dispatch(
          setError({
            key: "warn",
            value: {
              errorCode: null,
              errorMsg: "Something went wrong while saving BoQ in Datamart",
            },
          })
        );
      }
    }
  };

  return {
    saveBoQInDatamart,
  };
};

export default useDatamart;
