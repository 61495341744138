import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication
} from "@azure/msal-react";
import "App.css";
import Home from "Pages/Home";
import { Login } from "Pages/Login";
import { Segment } from "Pages/Segment";

import { AdvancedTheme, App } from "@nokia-csf-uxr/ccfk";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from "react";
// import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { InteractionType } from "@azure/msal-browser";
// import GlobalCCFKStyles from '@nokia-csf-uxr/ccfk/styles/GlobalCCFKStyles';
import '@nokia-csf-uxr/nokia-design-system-tokens/css/_variables.css';
// import '@nokia-csf-uxr/nokia-design-system-tokens/global-style.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";

const EnsoBp = () => {

  useMsalAuthentication(InteractionType.Redirect); //to auto redired login
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { refetchOnWindowFocus: false }
    }
  });
  return (
    <>

      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AdvancedTheme advancedTheme={"CCFK FreeForm - Light"} addGlobalStyle={true} addGlobalVariables={true}  >
          {/* <ComponentsContextProvider direction={{ RTL: false }} disableAnimation={false} > */}
          {/* <GlobalCCFKStyles /> */}
          <App disableAnimation={false} >
            <AuthenticatedTemplate>
              <BrowserRouter>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/" element={<Home />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/segment" element={<Segment />} />
                </Routes>
              </BrowserRouter>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Login />
            </UnauthenticatedTemplate>
          </App>

          {/* </ComponentsContextProvider> */}
        </AdvancedTheme>
      </QueryClientProvider>

    </>
  );
};

export default EnsoBp;
