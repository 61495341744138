import React, { useState } from "react";
import * as XLSX from "xlsx";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@nokia-csf-uxr/ccfk";
import { ButtonIcon, ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import DownloadIcon from '@nokia-csf-uxr/ccfk-assets/legacy/DownloadIcon';

import { RootState } from "Store/mainStore";
import { setProp } from "Store/Actions";
import FileUploader from "../DynamicField/FileUpload/FileUploader";
import FieldLabel from "../DynamicField/FieldLabel";
import { useDataCallBack } from "../hooks/useDataCallback";


interface IRadioExcelUpload {
  allRefs: any;
}

const RadioExcelUpload = ({allRefs}:IRadioExcelUpload) => {
  /** Selectors */
  const stateMachineSelector = useSelector(
    (state: RootState) => state.stateMachine
  );
  const themeSelector = useSelector((state: RootState) => state.theme);
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  
  // State
  const [isUploaderLoading,setIsUploaderLoading] = useState(false);
  const [isFileUploaderReset,setIsFileUploaderReset] = useState(false);
  const [customMessage,setCustomMessage] = useState("");
  
  // hooks
  const dispatch = useDispatch();
  const { validateRadioExcelJsonData } = useDataCallBack(allRefs);
  
  // Local variable
  const activeStep = configstepperSelector.data.active_step;
  const activeSubStep = configstepperSelector.data.active_substep;

  /** setRadioExcelFileUpload */
  const setRadioExcelFileUpload = async (fileList) => {
    if (
      fileList.length > 0 &&
      fileList[0].file &&
      fileList[0].file.status === "complete"
    ) {
      setIsFileUploaderReset(false);
      try {
        const jsonData = await parseUploadedExcelContent(fileList[0].file);
        if(jsonData && !_.isEmpty(jsonData)){
          // Call validation api only if json data is not empty
          setIsUploaderLoading(true);
          const resp = await validateRadioExcelJsonData(jsonData);
          if (resp && resp.status && resp.data) {
            // Set the radio excel data
            // - from configurator response
            const __radioExcelJson = resp.data 
            // Update the radioExcelJson in data store :- dynamicSelector.data.modal[activeStep][activeSubStep].radioExcelJson
            dispatch(
              setProp({
                slice: "dynamic",
                key: `modal.${activeStep}.${[activeSubStep]}.radioExcelJson`,
                value: __radioExcelJson,
              })
            );
          } else {
            // Something went wrong ! Please try again
            // Reset the store          
            dispatch(
              setProp({
                slice: "dynamic",
                key: `modal.${activeStep}.${activeSubStep}.radioExcelJson`,
                value: [],
              })
            );
            // Reset file uploader with error message
            setCustomMessage((resp && resp.stacktrace) ? resp.stacktrace : "Something went wrong ! Please try again.");
            setIsFileUploaderReset(true);
          }
          setIsUploaderLoading(false);
        }
        else{
          setIsFileUploaderReset(true);
          setCustomMessage("The uploaded file contains no data. Please check and upload again.");
        }
      } catch (error) {
        setIsUploaderLoading(false);
        // Error processing file
        // Reset the store          
        dispatch(
          setProp({
            slice: "dynamic",
            key: `modal.${activeStep}.${activeSubStep}.radioExcelJson`,
            value: [],
          })
        );
        // Reset file uploader with error message
        setCustomMessage("Something went wrong ! Please try again.");
      }
    } else {
      // Reset the store          
      dispatch(
        setProp({
          slice: "dynamic",
          key: `modal.${activeStep}.${activeSubStep}.radioExcelJson`,
          value: [],
        })
      );
    }
  };

  /** parseUploadedExcelContent */
  const parseUploadedExcelContent = async (file) => {
    const readFile = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const binaryStr = event.target.result;
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let jsonData = [];
          const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          if (sheetData.length > 0) {
            // Get the headers from the worksheet
            // @ts-ignore
            const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0].map((header)=>header.toLowerCase().replace(/ /g, '_'));

            // Convert the worksheet to JSON using the transformed headers
            jsonData = XLSX.utils.sheet_to_json(worksheet, { header: headers, range: 1 });
          }
          
          resolve(jsonData);
        };
  
        reader.onerror = (error) => {
          reject(error);
        };
  
        reader.readAsBinaryString(file);
      });
    }

    try {
      const jsonData = await readFile(file);
      return jsonData;
    } catch (error) {
      console.error('Error reading file:', error);
    }
  };

  

  /** downloadTemplateFile */
  const downloadTemplateFile = () => {
    //@ts-ignore
    if(configstepperSelector.data?.solution_form_data?.packages?.connectivity?.field_details?.radio_solution_connectivity?.field_details?.radio_solutions?.field_details?.download_template?.data_callback?.endpoint){
      //@ts-ignore
      let downloadUrl = configstepperSelector.data?.solution_form_data?.packages?.connectivity?.field_details?.radio_solution_connectivity?.field_details?.radio_solutions?.field_details?.download_template?.data_callback?.endpoint;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.target = "_blank"; // -- Should be removed once download link started working
      link.download = getFileNameFromUrl(downloadUrl);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // const fileName = getFileNameFromUrl(downloadUrl);
      // downloadFileFromSharePoint(downloadUrl,fileName)
    }    
  }

  /** getFileNameFromUrl */
  const getFileNameFromUrl = (url) => {
      const urlObj = new URL(url);
      const fileName = urlObj.searchParams.get('file');
      return fileName;
  };

  const getRadioExcelPageStatus = () => {
    const isUndoButtonEnabled = themeSelector.data.button.isToggle[`${stateMachineSelector.PTDAC.activeTab}_undo`];
    return isUploaderLoading || isUndoButtonEnabled;
  }
  
  return (
    <>
      <div style={{display:'flex',justifyContent:'space-between'}}>
        <FieldLabel
          fieldData={['upload_radio_excel',{
            label:"Upload Radio Excel",
            required:true,
            description:"",
            help_title:"Upload Radio Excel file",
            help_text:""
          }]}
        />
        <Button
          onClick={downloadTemplateFile}
          variant={"secondary"}
          disabled={getRadioExcelPageStatus()}
        >
          <ButtonText>Download Template</ButtonText>
          <ButtonIcon><DownloadIcon /></ButtonIcon>
        </Button>
      </div>
      <div style={{ padding: "10px 0", marginBottom: "10px" }}>
        <FileUploader
          accept={[".xlsx"]}
          minSize={0}
          // Maximum file size (in bytes) => 209715200 = 200 MB
          maxSize={209715200}
          // maxSize={100}
          maxFiles={1}
          multiple={false}
          setUploadedFiles={setRadioExcelFileUpload}
          isReset={isFileUploaderReset}
          customMessage={customMessage}
          isLoading={isUploaderLoading}
          loadingMessage = {isUploaderLoading? 'Validating Radio Excel... Please wait':''}
          isFileListDisabled={getRadioExcelPageStatus()}
        />
      </div>
    </>
  );
}
export default RadioExcelUpload;
