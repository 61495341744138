// import { Button, HorizontalDivider, Label, Skeleton } from '@nokia-csf-uxr/ccfk'
// import { ButtonText } from '@nokia-csf-uxr/ccfk/Button'
import { HorizontalDivider } from '@nokia-csf-uxr/ccfk'
// import { CoachmarkFooter } from '@nokia-csf-uxr/ccfk/Coachmarks'
import { TextInputLabelContent } from '@nokia-csf-uxr/ccfk/TextInput'
import { useQuery } from '@tanstack/react-query'
import AddCartBtn from 'Components/Elements/AddCartBtn'
import Loader from 'Components/Elements/Loader'
import SliderComponent from 'Components/Elements/Slider'
import OOT from 'Constants/services/oot'
import { setProp } from 'Store/Actions'
import { addCartItem } from 'Store/contentState/cartBoqSlice'
import { setDevicesTabRules } from 'Store/contentState/stateMachine'
import { RootState } from 'Store/mainStore'
import { setTheme } from 'Store/theme'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
const Options = () => {
    const devicesSelector = useSelector((state: RootState) => state.devices);
    const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
    const stateMachineSelector = useSelector((state: RootState) => state.stateMachine);
    const dispatch = useDispatch();
    const slice = "devices";
    const issimCardDevicesState = stateMachineSelector.PTDAC.selected?.hasOwnProperty(slice) && stateMachineSelector.PTDAC.selected?.[slice]?.simCardDevicesTabLogic;
    const devicesTabQuery = useQuery({

        queryKey: ['devices', { account: caseDetailsSelector.data.account_id }],
        queryFn: async ({ queryKey }) => {
            //@ts-ignore
            const [_key, { account }] = queryKey;
            const response = await OOT.getDevicesTab({ account: account ,selector : caseDetailsSelector});
            return response;

        },
        onSuccess(res) {
            dispatch(setDevicesTabRules(res.data));
            dispatch(setTheme({ element: "button", comp: "addToCart", prop: "isDisable", value: true }))
        }
    })
    useEffect(() => {
        /*
        Rule: Change the value of simCard  When Devices tab is loaded
        Depends upon : devicesTabQuery Api Call is set then only issimCardDevicesState gets sim_card value
        */
        if (issimCardDevicesState && issimCardDevicesState.response_code) {
            dispatch(setProp({ slice: slice, key: "options.PTDAC.sim_card", value: issimCardDevicesState.data.set.sim_card }))
        }

    }, [devicesTabQuery.data && issimCardDevicesState && issimCardDevicesState.response_code])

    if (devicesTabQuery.isLoading) {
        return (<Loader />)
    }
    return (
        <>
            <TextInputLabelContent>SIM Card *</TextInputLabelContent>
            <SliderComponent
                sliderId='options.PTDAC.sim_card'
                minDuration='100'
                maxDuration='10000'
                stepBy={100}
                sliderStateValue={devicesSelector.data.options.PTDAC.sim_card}
                handleChange={(e) => { dispatch(setProp({ slice: slice, key: "options.PTDAC.sim_card", value: e.value })) }}
            />
            <HorizontalDivider />
            <AddCartBtn isLoading={!(issimCardDevicesState && issimCardDevicesState.response_code)}
                onAction={() => {
                    /*simCardDevicesRule: Add the products + set the simcard */
                    dispatch(addCartItem({products:[...issimCardDevicesState.data.set.products],history:true}));
                   

                }} />
        </>
    )
}

export default Options